import {
  MIGRATE_REGION,
  MIGRATE_MERCHANDISER,
  PREVIEW_MIGRATE_MERCHANDISER,
  MIGRATE_HOLIDAYS
} from "../constants/action-types";

import Axios from "axios";

import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";

const API_CONFIG = getApiConfig();

export const PreviewMerchandiserMigration = (metadata, callback) => {
  return async (dispatch) => {
    try {
      const response = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9001/api/PreviewMerchandiserMigration`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/PreviewMerchandiserMigration`,
        {
          method: "post",
          data: metadata
        }
      );

      dispatch({
        type: PREVIEW_MIGRATE_MERCHANDISER,
        payload: {
          data: response.data
        }
      });

      callback(response);
    } catch (error) {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    }
  };
};

export const RegionMassMigration = (metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9004/api/RegionMigration`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/RegionMigration`,
        {
          method: "post",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_REGION,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_REGION,
        failures: metadata,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  };
};

export const MerchandiserMassUpload = (metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9001/api/MerchandiserMigration`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/MerchandiserMigration`,
        {
          method: "post",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: metadata,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  };
};

export const MerchandiserContractMassUpload = (metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9001/api/MerchandiserContractMigration`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/MerchandiserContractMigration`,
        {
          method: "post",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: metadata,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  };
};

export const MerchandiserLeaveMassUpload = (metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9001/api/MerchandiserLeaveMigration`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/MerchandiserLeaveMigration`,
        {
          method: "post",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: metadata,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  };
};

export const HolidayCalendarMassUpload = (data, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9004/api/UpdateHolidayCalendar`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/UpdateHolidayCalendar`,
        {
          method: "post",
          data: data,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_HOLIDAYS,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_HOLIDAYS,
        failures: data,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  }
}

export const MerchandiserCardMassUpload = (metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9001/api/MerchandiserCardMigration`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/MerchandiserCardMigration`,
        {
          method: "post",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: metadata,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  };
};

export const CustomerMerchWindowMassMigration = (metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9002/api/MassUploadCustomerMerchWindow`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/MassUploadCustomerMerchWindow`,
        {
          method: "post",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: metadata,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  };
};

export const CustomerServicePolicyMassUpload = (metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9002/api/CustomerServicePolicyMigration`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/CustomerServicePolicyMigration`,
        {
          method: "post",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: metadata,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  };
};

export const CustomerMassMigration = (metadata, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };
    try {
      const migrationResponse = await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
          ? `http://localhost:9002/api/CustomerMassMigration`
          : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/CustomerMassMigration`,
        {
          method: "post",
          data: metadata,
          headers: headers
        }
      );

      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: [],
        successes: migrationResponse.data,
        message: ""
      });
      callback(true);

    } catch (error) {
      dispatch({
        type: MIGRATE_MERCHANDISER,
        failures: metadata,
        successes: [],
        message: error.message
      });
      console.log(`Exception occured: ${error.message}`);
      callback(true);
    }
  };
};
