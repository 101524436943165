// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../components/dialog/Modal";

// UI and Styling
import { Box, Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import styles from "./MigrationPage.module.scss";
import { withStyles } from "@material-ui/core/styles";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WarningRounded from '@material-ui/icons/WarningRounded';
import Information from '@material-ui/icons/InfoRounded';

// Actions
import {
    MerchandiserMassUpload,
    PreviewMerchandiserMigration
} from "../../state/actions/MigrationActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";


// Components
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import {PageContainer} from "../../components/page-container/PageContainer";
import { LoadingComponent } from "../../components/loading2/CircleLoader";

// Segments
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import { UUID } from "../../helpers/uuid";

// API
import { getApiConfig } from "../../config/apiConfig";
const API_CONFIG = getApiConfig();
const templateUrl = process.env.NODE_ENV !== "production"
    ? `http://localhost:9001/api/GetAllMerchandisersAsCSV`
    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/GetAllMerchandisersAsCSV`

// Moment
var moment = require('moment');
moment().format();

const RedTextTypography = withStyles({
    root: {
        color: "#f53100"
    }
})(Typography);


const MerchMigrationPage = (props) => {
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [csvData, setCsvData] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [targetsEdited, setTargetsEdited] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [previewMigrationCalled, setPreviewMigrationCalled] = useState(false);
    const [previewData, setPreviewData] = useState({});

    const dispatch = useDispatch();

    //Use Effects
    useEffect(() => {
        setLoading(true);
        if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
            dispatch(
                getLoggedInUserByEmail(() => {
                    setLoggedInUserCallMade(true);
                })
            );
        } else {
            setLoggedInUserCallMade(true);
        }
    }, []);

    useEffect(() => {
        if (targetsEdited) {
            setSubmitEnabled(true);
        }
    }, [targetsEdited]);

    useEffect(() => {
        if (dataChanged) {
            setDataChanged(false);
        }
    }, [dataChanged]);

    const handleSubmit = async () => {
        setIsUploading(true);
        if (!submitEnabled) {
            return;
        }

        setSubmitEnabled(false);

        const data = createRequestBody();

        dispatch(
            MerchandiserMassUpload(
                data,
                (res) => {
                    if (res) {
                        props.history.push("/post-migration");
                    }
                }
            )
        );
    };

    const createRequestBody = () => {
        let toReturn = [];
        csvData.forEach(row => {
            const requestBody = {
                ...row,
                CreatedDate: moment().toISOString(),
                CreatedBy: loggedInUser.userName,
                ModifiedBy: loggedInUser.userName,
                ModifiedDate: moment().toISOString()
            };
            toReturn.push(requestBody);
        });
        return toReturn;
    };

    const renderSubmitAndCancelButtons = () => {
        return [
            <Button
                key={UUID()}
                variant="outlined"
                onClick={() => {
                    const data = createRequestBody();
                    dispatch(
                        PreviewMerchandiserMigration(
                            data,
                            (res) => {
                                setPreviewMigrationCalled(true);
                                setPreviewData({
                                    updates: res.data.Item1,
                                    creates: res.data.Item2 - res.data.Item1
                                });
                                setModalShow(true);
                            })
                    );
                }}
                disabled={!submitEnabled}
            >
                Submit
          </Button>
        ];
    };

    function MyVerticallyCenteredModal() {
        return (
            <Modal
                title={

                    previewData.updates != 0 ?
                        <div>
                            <Typography variant='h4' align='center'><WarningRounded /></Typography>
                            <Typography align='center' variant='h5'>Warning!</Typography>
                        </div> :
                        <div>
                            <Typography variant='h4' align='center'><Information /></Typography>
                            <Typography align='center' variant='h5'>Confirm Migration</Typography>
                        </div>
                }
                open={modalShow}
                fullWidth={true}
                fixedHeight={false}
                actions={
                    <div>
                        <br />
                        <Button color="secondary" onClick={() => {
                            setModalShow(false);
                        }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<CloudUploadIcon />}
                            color="primary"
                            onClick={() => {
                                handleSubmit();
                            }}
                        >
                            Confirm Upload
                        </Button>
                    </div>
                }
            >
                {previewData.updates != 0 &&
                    <RedTextTypography align='center'>
                        <b>This dataset contains updates!</b> Any Merchandiser who is updated due to matching an existing employee number will have their data overwritten irrevocably.
                </RedTextTypography>}

                {previewData.updates == 0 &&
                    <Typography align='center'>
                        Please confirm the contents of the CSV file before uploading, as there are no data-recovery workflows to resolve issues that arise in this process.
                </Typography>}
                <br />
                <Typography align='center' variant='subtitle1'><b>Updated Merchandisers: </b>{previewData.updates}</Typography>
                <Typography align='center' variant='subtitle1'><b>Created Merchandisers: </b>{previewData.creates}</Typography>
            </Modal >
        );
    }

    return (
        <Box>
            <Fragment>

                {isUploading ? (
                    <>
                        <HeaderBlock
                            title={"Please Wait: Merchandiser Data is being uploaded"}
                        />
                        <PageContainer>
                            <LoadingComponent></LoadingComponent>
                        </PageContainer>
                    </>
                ) : (
                        <>
                            <HeaderBlock
                                title={"Merchandiser Data Mass Upload"}
                                right={
                                    renderSubmitAndCancelButtons() ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                            justify="flex-end"
                                        >
                                            <Grid item>
                                                <ButtonGroup>{renderSubmitAndCancelButtons()}</ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    ) : null
                                }
                            />

                            <PageContainer>
                                <div className={styles.contentGrid}>
                                    <Grid container spacing={3}>
                                        <Grid key={"migrationUploadSegment"} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MigrationUploadSegment
                                                csvData={csvData}
                                                setCsvData={setCsvData}
                                                setDataChanged={setDataChanged}
                                                title="Upload Merchandiser Data"
                                                targetsEdited={targetsEdited}
                                                setTargetsEdited={setTargetsEdited}
                                                isEditing={isEditing}
                                                setFormEdited={setFormEdited}
                                                description="Please upload a CSV containing the Merchandiser data that you would like uploaded to SaM-M"
                                                columns={["Id", "ManagerEmployeeNumber", "Site", "EmployeeNumber", "FirstName", "LastName", "PreferredName",
                                                    "PreferredSubRegionId", "SalesOffice", "Email", "Phone", "PreferredBanner", "TradeChannelCompliance",
                                                    "IsFullTime", "IsStudent", "IsWorkingForCompetition", "IsActive"]}
                                                templateFileName="Merchandiser_Migration.csv"
                                                allDataUrl={templateUrl}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <MyVerticallyCenteredModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                        />
                                    </Grid>
                                </div>
                            </PageContainer>
                        </>)}
            </Fragment>
        </Box>
    );
};

const hoc = withRouter(MerchMigrationPage);

// EXPORT COMPONENT
export { hoc as MerchMigrationPage };