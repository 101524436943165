// Config Imports
import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import { format } from "date-fns";
// Misc
const API_CONFIG = getApiConfig();

/*
    Get the logged in user details by logged in users upn/email.
*/
export const generateCalls = (startTime, endTime, numCalls, email, callback) => {
    startTime = format(startTime, "yyyy/MM/dd HH:mm:ss");
    endTime = format(endTime, "yyyy/MM/dd HH:mm:ss");
    adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
            ? `http://localhost:1234/api/AddMerchCalls?Email=${email}&NumCalls=${numCalls}&StartTime=${startTime}&EndTime=${endTime}`
            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/AddMerchCalls?Email=${email}&NumCalls=${numCalls}&StartTime=${startTime}&EndTime=${endTime}`,
        {
            method: "post"
        }
    )
        .then((res) => {
            callback(true);
        })
        .catch((error) => {
            console.log(`Exception Occurred: ${error}`);
            callback(false, error);
        });
};