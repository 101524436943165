// IMPORT PACKAGE REFERENCES
import { createStore, applyMiddleware, compose } from "redux";

// IMPORT MIDDLEWARE
import thunk from "redux-thunk";
import promiseMiddleware from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

// IMPORT REDUCERS
import { RootReducer } from "../reducers/AppReducer";

const persistConfig = {
  key: "root",
  storage: storageSession
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// CONFIGURE STORE
export const createAppStore = () => {
  let store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(thunk, promiseMiddleware))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
