export const MerchandiserModel = {
    Site: {
        type: "int",
        required: true,
        errorMessage: "",
        value: "Site",
        label: "Territory"
    },
    EmployeeNumber: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "EmployeeNumber",
        label: "Employee Number"
    },
    FirstName: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "FirstName",
        label: "First Name"
    },
    LastName: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "LastName",
        label: "Last Name"
    },
    PreferredName: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "PreferredName",
        label: "Preferred Name"
    },
    PreferredSubRegionId: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "PreferredSubRegionId",
        label: "Preferred Sub-Region Id"
    },
    SalesOffice: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "SalesOffice",
        label: "Sales Office"
    },
    Email: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Email",
        label: "Email"
    },
    Phone: {
        type: "int",
        required: false,
        errorMessage: "",
        value: "Phone",
        label: "Phone"
    },
    PreferredBanner: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "PreferredBanner",
        label: "Preferred Banner"
    },
    TradeChannelCompliance: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "TradeChannelCompliance",
        label: "Trade Channel Compliance"
    },
    IsFullTime: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "IsFullTime",
        label: "Full Time?"
    },
    IsStudent: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "IsStudent",
        label: "Student?"
    },
    IsWorkingForCompetition: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "IsWorkingForCompetition",
        label: "Working For Competition?"
    },
    ManagerId: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "ManagerId",
        label: "Manager Id"
    },
    IsActive: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "IsActive",
        label: "Active?"
    },
    SubRegions: {
        type: "string",
        required: true,
        errorMessage: "",
        value: "SubRegions",
        label: "Sub Regions"
    },
}