export const GlobalsModel = {
  Site: {
    type: "string",
    required: false,
    errorMessage: "",
    value: "Site",
    label: "Site"
  },
  STEM: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "STEM",
    label: "STEM"
  },
  ToleranceOutOfStoreKM: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "ToleranceOutOfStoreKM",
    label: "Tolerance Out Of Store KM"
  },
  ToleranceSTEMKMs: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "ToleranceSTEMKMs",
    label: "Tolerance STEM KM (Travel Distance)"
  },
  ToleranceSTEMHours: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "ToleranceSTEMHours",
    label: "Tolerance STEM Hours (Travel Time)"
  },
  ToleranceStoreHours: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "ToleranceStoreHours",
    label: "Tolerance for instore time"
  },
  MaxShiftHours: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "MaxShiftHours",
    label: "Max Shift Hours"
  },
  MaxDaysConsec: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "MaxDaysConsec",
    label: "Max Consecutive Days"
  },
  MaxDaysConsecHours: {
    type: "select",
    required: true,
    errorMessage: "",
    value: "MaxDaysConsecHours",
    label: "Max Days Consecutive Hours"
  },
  MinHoursBetweenShifts: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "MinHoursBetweenShifts",
    label: "Min Hours Between Shifts"
  },
  IsActive: {
    type: "checkbox",
    required: true,
    errorMessage: "",
    value: "IsActive",
    label: "Active"
  }
};
