export const CallTypeModel = {
    Id: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Id",
        label: "Id"
    },
    CallTypeName: {
        type: "string",
        required: true,
        errorMessage: "",
        value: "CallTypeName",
        label: "Name"
    },
    IsActive: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "IsActive",
        label: "Is Active?"
    }
}