import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
    GET_ROSTERS_BY_MERCHANDISER
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

export const getMerchandiserRosters = (employeeNumber, email, callback) => {
    return async (dispatch) => {
        try {
            const response =
                employeeNumber !== undefined || email !== undefined
                    ? await adalApiFetch(
                        Axios,
                        process.env.NODE_ENV !== "production"
                            ? `http://localhost:9005/api/GetRostersForMerchandiser?EmployeeNumber=${employeeNumber}&EmailAddress=${email}&LatestOnly=true`
                            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Roster/GetRostersForMerchandiser?EmployeeNumber=${employeeNumber}&EmailAddress=${email}&LatestOnly=true`,
                        {
                            method: "get"
                        }
                    )
                    : {};

            dispatch({
                type: GET_ROSTERS_BY_MERCHANDISER,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};