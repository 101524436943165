import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import { v4 as uuidv4 } from 'uuid';
import {
    GET_TIMESHEETS,
    GET_TIMESHEET_LIST,
    GET_TIMESHEET_LIST_PENDING,
    GET_CURRENT_TIMESHEET,
    UPSERT_TIMESHEET,
    GET_TIMESHEET_REASONS
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

export const getTimesheetList = (email, callback) => {
    return async (dispatch) => {
        dispatch({
            type: GET_TIMESHEET_LIST_PENDING
        });
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9011/api/GetTimesheetsForUser?Email=${email}`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Timesheets/GetTimesheetsForUser?Email=${email}`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_TIMESHEET_LIST,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getTimesheetReasons = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9004/api/GetTimesheetReasonList`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/GetTimesheetReasonList`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_TIMESHEET_REASONS,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getTimesheetCalls = (email, startdate, enddate, callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9011/api/GetTimesheetDataForUser?Email=${email}&Start=${startdate}&End=${enddate}`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Timesheets/GetTimesheetDataForUser?Email=${email}&Start=${startdate}&End=${enddate}`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_TIMESHEETS,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getTimesheet = (id, callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9011/api/GetTimesheetById?Id=${id}`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Timesheets/GetTimesheetById?Id=${id}`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_TIMESHEETS,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};


export const updateTimesheet = (data, callback) => {
    return async (_) => {
        try {
            const _ = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9011/api/PostTimesheet`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Timesheets/PostTimesheet`,
                {
                    data: data,
                    method: "post"
                }
            );
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};