// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./ViewSubRegionPage.module.scss";

// Actions
import {
    createSubRegion,
    getSubRegionById,
    updateSubRegion,
    getLookups
} from "../../state/actions/RegionActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import {PageContainer} from "../../components/page-container/PageContainer";

// Models
import { SubRegionModel } from "../../models/SubRegionModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";

const ViewSubRegionPage = (props) => {
    const currentSubRegion = useSelector(
        (state) => state.RegionReducer.Current
    );
    const lookupData = useSelector((state) => state.RegionReducer.lookupData);

    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [contentValues, setContentValues] = useState([]);
    const [formProperties, setFormProperties] = useState(SubRegionModel);
    const [SubRegion, setSubRegion] = useState({});
    const [uneditedSubRegion, setUneditedSubRegion] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingSubRegion, setIsExistingSubRegion] = useState(true);
    const [SubRegionId, setSubRegionId] = useState("");
    const [dataChanged, setDataChanged] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const [getSubRegionCallMade, setGetSubRegionCallMade] = useState(false);
    const [getLookupsCallMade, setGetLookupsCallMade] = useState(false);
    const [regionLookupData, setRegionLookupData] = useState(
        []
    );

    //Use Effects
    useEffect(() => {
        setLoading(true);

        setIsExistingSubRegion(
            props.type != null && props.type === FormType.VIEW
        );
    }, []);

    useEffect(() => {
        if (props.type != null && props.type === FormType.VIEW) {
            dispatch(
                getSubRegionById(props.match.params.SubRegionId, () => {
                    setGetSubRegionCallMade(true);
                    setSubRegionId(props.match.params.SubRegionId);
                })
            );
        } else {
            dispatch(
                getSubRegionById(undefined, () => {
                    setGetSubRegionCallMade(true);
                    setIsEditing(true);
                })
            );
        }

        dispatch(
            getLookups(() => {
                setGetLookupsCallMade(true);
            })
        );

    }, []);

    useEffect(() => {
        if (getLookupsCallMade) {
            setLoading(false);
        }
    }, [getLookupsCallMade]);

    useEffect(() => {
        setRegionLookupData(lookupData);
        updateRegionLookupData();
    }, [lookupData]);

    const updateRegionLookupData = () => {
        setContentValues(
            regionLookupData !== undefined &&
                regionLookupData.length > 0
                ? regionLookupData.sort(function (a, b) {
                    return a.DisplayName.localeCompare(b.DisplayName);
                }).map((t) => {
                    return t.DisplayName;
                }).filter((t) => {
                    if (process.env.NODE_ENV !== "production") {
                        return t !== "DO NOT USE" && t !== "Route setup for load test";
                    } else {
                        return t;
                    }
                })
                : []
        );
    }

    useEffect(() => {
        if (getSubRegionCallMade) {
            setLoading(false);
            setDataChanged(!dataChanged);
        }
    }, [getSubRegionCallMade]);


    useEffect(() => {
        // Update the DisplayName of the call schedule as we only get the code down from the backend
        let localSubRegion = Object.assign({}, currentSubRegion);
        setSubRegion(localSubRegion);
    }, [currentSubRegion]);

    // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
    // This is so the values in the form fields updates correctly on change.
    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, SubRegion);

        localData[name] = value;

        setSubRegion(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };

    /*
       Generic validation to perform on all field types
    */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            SubRegion[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    /*
        Validate the form before it is submitted.
        Dates not only need to be checked for validity they also need to be compared with each other.
     */
    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }

        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";
            errorMessage = checkGenericField(key, formProperties[key].required);
            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            setSubmitEnabled(true);
            return true;
        }
    };

    /* Transform the activity group data into the form that is required by the backend */
    const createRequestBody = (isCreate) => {
        let requestBody = {
            ...SubRegion
        };

        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            dispatch(
                createSubRegion(createRequestBody(true), (success, id) => {
                    if (success) {
                        setIsEditing(false);
                        props.history.push(`/sub-region/${id}`);
                    } else {
                        setIsEditing(true);
                        setSubmitEnabled(true);
                        setFormEdited(true);
                    }
                })
            );
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            dispatch(
                updateSubRegion(SubRegion, () => {
                    setIsEditing(false);
                })
            );
        }
    };

    /*
      Functionality for when the user clicks the cancel button on the edit or create form
    */
    const handleCancelEditButtonClicked = () => {
        if (formEdited) {
            setCancelEditingAlertOpen(true);
        } else {
            setIsEditing(false);

            if (!isExistingSubRegion) {
                props.history.push(`/sub-region`);
            }
        }
    };

    //Display Activity group and associated list of activities
    const renderData = () => {
        return (
            <Fragment>
                <div className={styles.contentGrid}>
                    <CustomCard title="Main Details" actionButton={[]}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.SubRegionName.value}
                                    name={formProperties.SubRegionName.value}
                                    label={formProperties.SubRegionName.label}
                                    placeholder="Sub Region Name"
                                    value={
                                        Object.keys(SubRegion).length > 0 &&
                                            SubRegion.SubRegionName !== undefined
                                            ? SubRegion.SubRegionName
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.SubRegionName.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.SubRegionName.errorMessage !== ""}
                                    helperText={formProperties.SubRegionName.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.SubRegionId.value}
                                    name={formProperties.SubRegionId.value}
                                    label={formProperties.SubRegionId.label}
                                    placeholder="Enter Sub Region Id"
                                    value={
                                        Object.keys(SubRegion).length > 0 &&
                                            SubRegion.SubRegionId !== undefined
                                            ? SubRegion.SubRegionId
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.SubRegionId.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.SubRegionId.errorMessage !== ""}
                                    helperText={formProperties.SubRegionId.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <DropdownSelect
                                    fullWidth={true}
                                    label="Region"
                                    disabled={!isEditing}
                                    handleChange={(value) => {
                                        var localSubRegion = Object.assign({}, SubRegion);
                                        localSubRegion.RegionId = value;
                                        setSubRegion(localSubRegion);
                                        setFormEdited(true);
                                    }}
                                    data={
                                        Object.values(regionLookupData).map(
                                            (modelType) => modelType.Id
                                        )
                                    }
                                    value={
                                        SubRegion.RegionId
                                    }
                                    valueName={"RegionId"}
                                ></DropdownSelect>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.AnniversaryName.value}
                                    name={formProperties.AnniversaryName.value}
                                    label={formProperties.AnniversaryName.label}
                                    placeholder="Enter Holiday Name"
                                    value={
                                        Object.keys(SubRegion).length > 0 &&
                                            SubRegion.AnniversaryName !== undefined
                                            ? SubRegion.AnniversaryName
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.AnniversaryName.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.AnniversaryName.errorMessage !== ""}
                                    helperText={formProperties.AnniversaryName.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="dense"
                                        disabled={!isEditing}
                                        disableToolbar
                                        fullWidth
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        label={formProperties.LocalHolidayDate.label}
                                        value={
                                            Object.keys(SubRegion).length > 0 &&
                                                SubRegion.LocalHolidayDate !== undefined
                                                ? SubRegion.LocalHolidayDate
                                                : new Date()
                                        }
                                        onChange={(date) => {
                                            handleInputChange(formProperties.LocalHolidayDate.value, date);
                                        }}
                                        error={formProperties.LocalHolidayDate.errorMessage !== ""}
                                        helperText={formProperties.LocalHolidayDate.errorMessage}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsActive.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                SubRegion !== undefined &&
                                                    SubRegion.IsActive !== undefined
                                                    ? SubRegion.IsActive
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsActive.value,
                                                    !SubRegion.IsActive
                                                )
                                            }
                                            name={formProperties.IsActive.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </div>
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <HeaderBlock
                title={SubRegion.SubRegionName != null ? SubRegion.SubRegionName + " ("+SubRegion.SubRegionId+")" : "New SubRegion"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setUneditedSubRegion(SubRegion);
                                        } else if (isEditing && isExistingSubRegion) {
                                            handleUpdateClicked();
                                        } else {
                                            handleSubmitClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingSubRegion
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />


            <PageContainer>{renderData()}</PageContainer>
        </div>
    );
};

const hoc = withRouter(ViewSubRegionPage);

// EXPORT COMPONENT
export { hoc as ViewSubRegionPage };
