import React from "react";

class CustomToolbarSelect extends React.Component {
    render() {
        return (
            <div className={"custom-toolbar-select"}>
                {this.props.toolbarOptions}
                {
                    // toolbarOptions structure should be a list of these:
                    /* <Tooltip title={"Submit Selected Item(s)"}>
                        <IconButton  onClick={this.handleClick}>
                            <ForwardIcon  />
                        </IconButton>
                    </Tooltip> */
                }
            </div>
        );
    }
}

export default (CustomToolbarSelect);
