export const CustomerServicePolicyModel = {
    CustomerNumber: {
        type: "string",
        required: true,
        errorMessage: "",
        value: "CustomerNumber",
        label: "Customer Number"
    },
    Day1Hours: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Day1Hours",
        label: "Monday Hours"
    },
    Day2Hours: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Day2Hours",
        label: "Tuesday Hours"
    },
    Day3Hours: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Day3Hours",
        label: "Wednesday Hours"
    },
    Day4Hours: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Day4Hours",
        label: "Thursday Hours"
    },
    Day5Hours: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Day5Hours",
        label: "Friday Hours"
    },
    Day6Hours: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Day6Hours",
        label: "Saturday Hours"
    },
    Day7Hours: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Day7Hours",
        label: "Sunday Hours"
    },
    EffectiveDate: {
        type: "date",
        required: true,
        errorMessage: "",
        value: "EffectiveDate",
        label: "Effective Date"
    },
    IsActive: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "IsActive",
        label: "Is Active?"
    }
}