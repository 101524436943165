import {
    GET_ALL_REGIONS,
    GET_SUBREGION,
    GET_LOOKUPS
} from "../constants/action-types";


// INITIALIZE STATE
const initialState = {
    Regions: [],
    SubRegions: [],
    Current: {},
    lookupData: {}
};

// REDUCER
export const RegionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_REGIONS: {
            return {
                ...state,
                Regions: action.payload.data.Regions,
                SubRegions: action.payload.data.SubRegions
            }
        }

        case GET_SUBREGION: {
            return {
                ...state,
                Current: action.payload.data
            }
        }

        case GET_LOOKUPS: {
            return {
                ...state,
                lookupData: action.payload.data
            }
        }

        default:
            return state;
    }
};
