// React
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import {
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    FormControlLabel,
    IconButton,
    TableRow,
    TextField,
    Tooltip,
    Paper
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import ForwardIcon from "@material-ui/icons/Forward";
import {
    DateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CustomToolbarSelect from "../../components/MUI-Datatable-CustomSelectActions/CustomToolbarSelect";
import TimesheetReasonModal from "./TimesheetReasonModal";

const TimesheetTable = (props) => {
    const [data, setData] = useState(props.data);
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    const [title, setTitle] = useState(props.title);
    const [reasonNeeded, setReasonNeeded] = useState(false);
    const [reasonRowIndex, setReasonRowIndex] = useState(0);
    const [reasonIndexSuccesses, setReasonIndexSuccesses] = useState([]);

    useEffect(() => {
        let reasonIndexes = props.data.map(s => false);
        setReasonIndexSuccesses(reasonIndexes);
    }, []);

    const userTableOptions = {
        filter: true,
        responsive: "stacked",
        rowsPerPage: 50,
        rowsPerPageOptions: [5, 10, 50, 100, 200],

        viewColumns: false,
        filterType: "checkbox",
        elevation: 0,
        selectableRows: "multiple",
        print: false,
        download: false,
        customToolbarSelect: selectedRows => (<CustomToolbarSelect selectedRows={selectedRows} toolbarOptions={toolbarOptions(selectedRows)} />),
        isRowSelectable: (dataIndex) => {
            return data[dataIndex].LatestState === "Pending" || data[dataIndex].LatestState == 0;
        },
        expandableRows: true,
        renderExpandableRow: (_, rowMeta) => {
            var subrowData = data[rowMeta.rowIndex];
            return (
                <React.Fragment >
                    <tr>
                        <td colSpan={12} >
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell align="right">Time Taken</TableCell>
                                            <TableCell align="right">Start Time</TableCell>
                                            <TableCell align="right">End Time</TableCell>
                                            <TableCell align="right">KM Travelled</TableCell>
                                            <TableCell align="right">Edit Reason</TableCell>
                                            <TableCell align="right">Reason Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={subrowData.name}>
                                            <TableCell component="th" scope="row">Estimated Values</TableCell>
                                            <TableCell align="right">{subrowData.EstimatedDurationMinutes}</TableCell>
                                            <TableCell align="right">{moment(subrowData.DeviceStartTime).format('LLLL')}</TableCell>
                                            <TableCell align="right">{moment(subrowData.DeviceEndTime).format('LLLL')}</TableCell>
                                            <TableCell align="right">{subrowData.EstimatedDistanceMeters / 1000}</TableCell>
                                            <TableCell align="right">N/A</TableCell>
                                            <TableCell align="right">N/A</TableCell>
                                        </TableRow>

                                        {subrowData.SubmitterAmendment != null &&
                                            <TableRow key={subrowData.SubmitterAmendment.UserDisplayName}>
                                                <TableCell component="th" scope="row">Amended By: {subrowData.SubmitterAmendment.UserDisplayName}</TableCell>
                                                <TableCell align="right">{moment.duration(moment(subrowData.SubmitterAmendment.AmendedEndTime).diff(moment(subrowData.SubmitterAmendment.AmendedStartTime))).asMinutes()}</TableCell>
                                                <TableCell align="right">{moment(subrowData.SubmitterAmendment.AmendedStartTime).format('LLLL')}</TableCell>
                                                <TableCell align="right">{moment(subrowData.SubmitterAmendment.AmendedEndTime).format('LLLL')}</TableCell>
                                                <TableCell align="right">{subrowData.SubmitterAmendment.AmendedDistanceMeters / 1000}</TableCell>
                                                <TableCell align="right">{subrowData.SubmitterAmendment.ReasonId}</TableCell>
                                                <TableCell align="right">{subrowData.SubmitterAmendment.ReasonMessage}</TableCell>
                                            </TableRow>
                                        }
                                        {subrowData.ReviewerAmendment != null &&
                                            <TableRow key={subrowData.ReviewerAmendment.UserDisplayName}>
                                                <TableCell component="th" scope="row">Amended By: {subrowData.ReviewerAmendment.UserDisplayName}</TableCell>
                                                <TableCell align="right">{moment.duration(moment(subrowData.ReviewerAmendment.AmendedEndTime).diff(moment(subrowData.ReviewerAmendment.AmendedStartTime))).asMinutes()}</TableCell>
                                                <TableCell align="right">{moment(subrowData.ReviewerAmendment.AmendedStartTime).format('LLLL')}</TableCell>
                                                <TableCell align="right">{moment(subrowData.ReviewerAmendment.AmendedEndTime).format('LLLL')}</TableCell>
                                                <TableCell align="right">{subrowData.ReviewerAmendment.AmendedDistanceMeters / 1000}</TableCell>
                                                <TableCell align="right">{subrowData.ReviewerAmendment.ReasonId}</TableCell>
                                                <TableCell align="right">{subrowData.ReviewerAmendment.ReasonMessage}</TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                </React.Fragment>
            )
        }
    };

    const updateData = (newvalue, meta) => {
        var localData = [...data];
        var colName = meta.columnData.name;
        if (localData[meta.rowIndex].SubmitterAmendment == null) {
            localData[meta.rowIndex].SubmitterAmendment = {
                UserId: "",
                UserDisplayName: loggedInUser.userName.split('\\')[1],
                ReasonId: "",
                ReasonMessage: "",
                UpdateDate: new Date(),
                AmendedStartTime: localData[meta.rowIndex].DeviceStartTime,
                AmendedEndTime: localData[meta.rowIndex].DeviceEndTime,
                AmendedDistanceMeters: localData[meta.rowIndex].EstimatedDistanceMeters,
                Active: true
            }
        }

        if (colName === "DeviceStartTime") {
            localData[meta.rowIndex].SubmitterAmendment.AmendedStartTime = newvalue;
            localData[meta.rowIndex].SubmitterAmendment.AmendedEndTime = moment(newvalue).add((localData[meta.rowIndex].EstimatedDurationMinutes), 'minutes').format();
        } else if (colName === "DeviceEndTime") {
            localData[meta.rowIndex].SubmitterAmendment.AmendedEndTime = newvalue;
        } else if (colName === "EstimatedDurationMinutes") {
            localData[meta.rowIndex].EstimatedDurationMinutes = moment.duration(moment(localData[meta.rowIndex].SubmitterAmendment.AmendedStartTime).diff(moment(localData[meta.rowIndex].SubmitterAmendment.AmendedEndTime))).asMinutes();
        } else if (colName === "EstimatedDistanceMeters") {
            localData[meta.rowIndex].SubmitterAmendment.AmendedDistanceMeters = newvalue * 1000;
        } else {
            // This shouldn't trigger at all.
            localData[meta.rowIndex][colName] = newvalue;
        }
        let SubmitterAmendmentData = localData[meta.rowIndex].SubmitterAmendment;
        let newDuration = moment.duration(moment(SubmitterAmendmentData.AmendedEndTime).diff(moment(SubmitterAmendmentData.AmendedStartTime))).asMinutes();
        if (newDuration >= localData[meta.rowIndex].EstimatedDurationMinutes) {
            if (!reasonIndexSuccesses[meta.rowIndex]) {
                let local = { ...reasonIndexSuccesses }
                local[meta.rowIndex] = true;
                setReasonIndexSuccesses(local)
                setReasonRowIndex(meta.rowIndex);
                setReasonNeeded(true);
            }

        }

        setData(localData);
    }

    const submitTimesheetReason = (reasonId, reasonMessage) => {
        var localData = [...data];
        localData[reasonRowIndex].SubmitterAmendment.ReasonId = reasonId;
        localData[reasonRowIndex].SubmitterAmendment.ReasonMessage = reasonMessage;
        setReasonNeeded(false);
        setReasonRowIndex(0);
        setData(localData);
    }

    const columns = [
        { name: 'CustomerName', label: 'Customer' },
        { name: 'EntryType', label: 'Type' },
        {
            name: 'DeviceStartTime', label: 'Action Start Time',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            value={getValueForRow(tableMeta, value)}
                            disabled={isRowUneditable(tableMeta)}
                            onChange={(newData) => { updateData(newData, tableMeta); updateValue(newData) }}
                            format="MMM d HH:mm"
                        />
                    </MuiPickersUtilsProvider>
                )
            }
        },
        {
            name: 'DeviceEndTime', label: 'Action End Time',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                            value={getValueForRow(tableMeta, value)}
                            disabled={isRowUneditable(tableMeta)}
                            onChange={(newData) => { updateData(newData, tableMeta); updateValue(newData) }}
                            format="MMM d HH:mm"
                        />
                    </MuiPickersUtilsProvider>
                )
            }
        },
        {
            name: 'EstimatedDistanceMeters', label: 'Distance Travelled (KM)',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={
                            <TextField
                                value={getValueForRow(tableMeta, value) || ''}
                                type='number' disabled={isRowUneditable(tableMeta)}
                            />
                        }
                        // onBlur={(event) => { updateData(event.target.value, tableMeta); updateValue(event.target.value) }}
                        onChange={(event) => { updateData(event.target.value, tableMeta); updateValue(event.target.value) }}
                    />
                )
            }
        },
        {
            name: 'EstimatedDurationMinutes', label: 'Estimated Time Taken',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        control={<TextField value={getValueForRow(tableMeta, value) || ''} type='number' disabled={true} />}
                        onChange={(event) => updateValue(event.target.value)}
                        onBlur={(event) => { updateData(event.target.value, tableMeta); updateValue(event.target.value) }}
                    />
                )
            }
        },
        {
            name: 'LatestState', label: 'Approval Status',
            options: {
                filter: false,
                customBodyRender: (value) => (
                    <Chip
                        label={value}
                        color={getColorByApprovalValue(value)}
                    />
                )
            },
        }
    ];

    const isRowUneditable = (tableMeta) => data[tableMeta.rowIndex].LatestState !== "Pending" && data[tableMeta.rowIndex].LatestState != 0;

    const getValueForRow = (tableMeta, defaultValue) => {
        var name = tableMeta.columnData.name;
        if (name === "DeviceEndTime") {
            return data[tableMeta.rowIndex].ReviewerAmendment?.AmendedEndTime ?? data[tableMeta.rowIndex].SubmitterAmendment?.AmendedEndTime ?? defaultValue;
        } else if (name === "DeviceStartTime") {
            return data[tableMeta.rowIndex].ReviewerAmendment?.AmendedStartTime ??
                data[tableMeta.rowIndex].SubmitterAmendment?.AmendedStartTime ??
                defaultValue;
        } else if (name === "EstimatedDistanceMeters") {
            if (defaultValue == data[tableMeta.rowIndex].EstimatedDistanceMeters) defaultValue = defaultValue / 1000;
            let val = data[tableMeta.rowIndex].ReviewerAmendment?.AmendedDistanceMeters ?? data[tableMeta.rowIndex].SubmitterAmendment?.AmendedDistanceMeters ?? defaultValue;
            if (val == defaultValue) {
                if (defaultValue == data[tableMeta.rowIndex].EstimatedDistanceMeters) val = defaultValue / 1000;
            }
            else {
                val = val / 1000;
            }
            return val;
        } else if (name === "EstimatedDurationMinutes") {
            return defaultValue;
        }
        return defaultValue;
    }

    const handleSubmitRows = (rows) => {
        var selected = rows.lookup;
        var localData = [...data];
        localData.forEach((item, index) => {
            if (selected[index]) {
                item.LatestState = 'Submitted';
            };
        })
        setData(localData);
        props.updateData(localData, props.index);
    }

    const toolbarOptions = (selectedRows) => {
        return (
            <Tooltip title={"Submit Selected Item(s)"}>
                <IconButton onClick={(_) => { handleSubmitRows(selectedRows) }}>
                    <ForwardIcon />
                </IconButton>
            </Tooltip>
        )
    }

    const getColorByApprovalValue = (value) => {
        switch (value) {
            case 'Submitted':
                return 'primary'
            case 'Approved':
                return 'secondary'
            case 'Rejected':
            case 'Pending':
            default:
                return 'default'
        }
    }
    return (
        <div>
            <TimesheetReasonModal
                open={reasonNeeded}
                submitTimesheetReason={submitTimesheetReason}>
            </TimesheetReasonModal>
            <div style={{ marginTop: '12px', marginBottom: '12px' }} >
                <MUIDataTable
                    title={title}
                    data={data}
                    options={userTableOptions}
                    columns={columns}
                />
            </div >
        </div>
    )
};

export default TimesheetTable;
