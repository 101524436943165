import {
    GET_ROSTERS_BY_MERCHANDISER
} from "../constants/action-types";

// INITIALIZE STATE
const initialState = {
    Rosters: []
};

// REDUCER
export const RosterReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROSTERS_BY_MERCHANDISER: {
            return {
                ...state,
                Rosters: action.payload.data
            }
        }
        default: {
            return state;
        }
    }
}