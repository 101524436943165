// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Divider
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./GlobalItemPage.module.scss";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// Actions
import {
    getGlobals,
    updateGlobals
} from "../../state/actions/GlobalsActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import { PageContainer } from "../../components/page-container/PageContainer";

// Models
import { GlobalsModel } from "../../models/GlobalsModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";

const GlobalItemPage = (props) => {
    const currentGlobals = useSelector(
        (state) => state.GlobalsReducer.Current
    );

    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [formProperties, setFormProperties] = useState(GlobalsModel);
    const [Globals, setGlobals] = useState({});
    const [uneditedGlobals, setUneditedGlobals] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingGlobals, setIsExistingGlobals] = useState(true);
    const [GlobalsSiteId, setGlobalsSiteId] = useState("");
    const [dataChanged, setDataChanged] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const [getGlobalsCallMade, setGetGlobalsCallMade] = useState(false);

    // init load effect
    useEffect(() => {
        setLoading(true);

        if (props.type != null && props.type === FormType.VIEW) {
            dispatch(
                getGlobals(props.match.params.Site, () => {
                    setGetGlobalsCallMade(true);
                    setGlobalsSiteId(props.match.params.Site);
                })
            );
        } else {
            dispatch(
                getGlobals(undefined, () => {
                    setGetGlobalsCallMade(true);
                    setIsEditing(true);
                })
            );
        }
    }, [])

    // on globals req made effect, hide loading, invert datachanged.
    useEffect(() => {
        if (getGlobalsCallMade) {
            setLoading(false);
            setDataChanged(!dataChanged);
        }
    }, [getGlobalsCallMade]);

    // on current global update, reset the one stored in this comp state/reducer.
    useEffect(() => {
        let localGlobals = currentGlobals;
        setGlobals(localGlobals);
    }, [currentGlobals]);

    // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
    // This is so the values in the form fields updates correctly on change.
    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, Globals);

        localData[name] = value;

        setGlobals(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };


    // Generic validation to perform on all field types
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            Globals[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    /*
        Validate the form before it is submitted.
        Dates not only need to be checked for validity they also need to be compared with each other.
    */
    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }


        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";
            errorMessage = checkGenericField(key, formProperties[key].required);
            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            return true;
        }
    };

    /* Transform the current Globals data into the shape that is digestible by the backend.*/
    const createRequestBody = () => {
        let requestBody = {
            ...Globals
        };

        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the update action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            dispatch(
                updateGlobals(Globals, () => {
                    setIsEditing(false);
                })
            );
            props.history.push(`/globals/${currentGlobals.Site}`);
        }
    };

    /*
        Functionality for when the user clicks the
        cancel button on the edit form
    */
    const handleCancelEditButtonClicked = () => {
        if (formEdited) {
            setCancelEditingAlertOpen(true);
        } else {
            setIsEditing(false);

            if (!isExistingGlobals) {
                props.history.push(`/globals/${currentGlobals.Site}`);

            }
        }
    };

    // Displays the current active globals object. And the other formy stuff.
    const renderData = () => {
        return (
            <Fragment>
                <div className={styles.contentGrid}>
                    <CustomCard title="Globals Data" actionButton={[]}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={true} //Always disable the site field. It's not editable.
                                    id={formProperties.Site.value}
                                    name={formProperties.Site.value}
                                    label={formProperties.Site.label}
                                    placeholder="Site"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Site.errorMessage !== ""}
                                    helperText={formProperties.Site.errorMessage}
                                    value={
                                        Object.keys(Globals).length > 0 &&
                                            Globals.Site !== undefined
                                            ? Globals.Site
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Site.value,
                                            event.target.value
                                        )
                                    }
                                />
                                <Grid item xs={6} md={12}>
                                    <TextField
                                        disabled={!isEditing}
                                        id={formProperties.STEM.value}
                                        name={formProperties.STEM.value}
                                        label={formProperties.STEM.label}
                                        placeholder="STEM"
                                        value={
                                            Globals !== undefined &&
                                                Object.keys(Globals).length > 0 &&
                                                Globals.STEM !== undefined
                                                ? Globals.STEM
                                                : ""
                                        }
                                        onChange={(event) =>
                                            handleInputChange(
                                                formProperties.STEM.value,
                                                event.target.value
                                            )
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={formProperties.STEM.errorMessage !== ""}
                                        helperText={formProperties.STEM.errorMessage}
                                    />
                                </Grid>

                                <Grid item xs={6} md={12}>
                                    <TextField
                                        disabled={!isEditing}
                                        id={formProperties.ToleranceOutOfStoreKM.value}
                                        name={formProperties.ToleranceOutOfStoreKM.value}
                                        label={formProperties.ToleranceOutOfStoreKM.label}
                                        placeholder="Tolerance"
                                        value={
                                            Globals !== undefined &&
                                                Object.keys(Globals).length > 0 &&
                                                Globals.ToleranceOutOfStoreKM !== undefined
                                                ? Globals.ToleranceOutOfStoreKM
                                                : ""
                                        }
                                        onChange={(event) =>
                                            handleInputChange(
                                                formProperties.ToleranceOutOfStoreKM.value,
                                                event.target.value
                                            )
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={formProperties.ToleranceOutOfStoreKM.errorMessage !== ""}
                                        helperText={formProperties.ToleranceOutOfStoreKM.errorMessage}
                                    />
                                </Grid>
                                <Grid item xs={6} md={12}>
                                    <TextField
                                        disabled={!isEditing}
                                        id={formProperties.ToleranceSTEMKMs.value}
                                        name={formProperties.ToleranceSTEMKMs.value}
                                        label={formProperties.ToleranceSTEMKMs.label}
                                        placeholder="Tolerance"
                                        value={
                                            Globals !== undefined &&
                                                Object.keys(Globals).length > 0 &&
                                                Globals.ToleranceSTEMKMs !== undefined
                                                ? Globals.ToleranceSTEMKMs
                                                : ""
                                        }
                                        onChange={(event) =>
                                            handleInputChange(
                                                formProperties.ToleranceSTEMKMs.value,
                                                event.target.value
                                            )
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={formProperties.ToleranceSTEMKMs.errorMessage !== ""}
                                        helperText={formProperties.ToleranceSTEMKMs.errorMessage}
                                    />
                                </Grid>
                                <Grid item xs={6} md={12}>
                                    <TextField
                                        disabled={!isEditing}
                                        id={formProperties.ToleranceSTEMHours.value}
                                        name={formProperties.ToleranceSTEMHours.value}
                                        label={formProperties.ToleranceSTEMHours.label}
                                        placeholder="Tolerance"
                                        value={
                                            Globals !== undefined &&
                                                Object.keys(Globals).length > 0 &&
                                                Globals.ToleranceSTEMHours !== undefined
                                                ? Globals.ToleranceSTEMHours
                                                : ""
                                        }
                                        onChange={(event) =>
                                            handleInputChange(
                                                formProperties.ToleranceSTEMHours.value,
                                                event.target.value
                                            )
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        error={formProperties.ToleranceSTEMHours.errorMessage !== ""}
                                        helperText={formProperties.ToleranceSTEMHours.errorMessage}
                                    />
                                </Grid>
                                <Grid item xs={6} md={12}></Grid>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.ToleranceStoreHours.value}
                                    name={formProperties.ToleranceStoreHours.value}
                                    label={formProperties.ToleranceStoreHours.label}
                                    placeholder="Tolerance"
                                    value={
                                        Globals !== undefined &&
                                            Object.keys(Globals).length > 0 &&
                                            Globals.ToleranceStoreHours !== undefined
                                            ? Globals.ToleranceStoreHours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.ToleranceStoreHours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.ToleranceStoreHours.errorMessage !== ""}
                                    helperText={formProperties.ToleranceStoreHours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={12}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.MaxShiftHours.value}
                                    name={formProperties.MaxShiftHours.value}
                                    label={formProperties.MaxShiftHours.label}
                                    placeholder="maxShiftHours"
                                    value={
                                        Globals !== undefined &&
                                            Object.keys(Globals).length > 0 &&
                                            Globals.MaxShiftHours !== undefined
                                            ? Globals.MaxShiftHours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.MaxShiftHours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.MaxShiftHours.errorMessage !== ""}
                                    helperText={formProperties.MaxShiftHours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={12}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.MaxDaysConsec.value}
                                    name={formProperties.MaxDaysConsec.value}
                                    label={formProperties.MaxDaysConsec.label}
                                    placeholder="maxDaysConsec"
                                    value={
                                        Globals !== undefined &&
                                            Object.keys(Globals).length > 0 &&
                                            Globals.MaxDaysConsec !== undefined
                                            ? Globals.MaxDaysConsec
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.MaxDaysConsec.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.MaxDaysConsec.errorMessage !== ""}
                                    helperText={formProperties.MaxDaysConsec.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={12}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.MaxDaysConsecHours.value}
                                    name={formProperties.MaxDaysConsecHours.value}
                                    label={formProperties.MaxDaysConsecHours.label}
                                    placeholder="maxDaysConsecHours"
                                    value={
                                        Globals !== undefined &&
                                            Object.keys(Globals).length > 0 &&
                                            Globals.MaxDaysConsecHours !== undefined
                                            ? Globals.MaxDaysConsecHours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.MaxDaysConsecHours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.MaxDaysConsecHours.errorMessage !== ""}
                                    helperText={formProperties.MaxDaysConsecHours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={12}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.MinHoursBetweenShifts.value}
                                    name={formProperties.MinHoursBetweenShifts.value}
                                    label={formProperties.MinHoursBetweenShifts.label}
                                    placeholder="minHoursBetweenShifts"
                                    value={
                                        Globals !== undefined &&
                                            Object.keys(Globals).length > 0 &&
                                            Globals.MinHoursBetweenShifts !== undefined
                                            ? Globals.MinHoursBetweenShifts
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.MinHoursBetweenShifts.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.MinHoursBetweenShifts.errorMessage !== ""}
                                    helperText={formProperties.MinHoursBetweenShifts.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsActive.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                Globals !== undefined &&
                                                    Globals.IsActive !== undefined
                                                    ? Globals.IsActive
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsActive.value,
                                                    !Globals.IsActive
                                                )
                                            }
                                            name={formProperties.IsActive.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </div >
            </Fragment >
        );
    };

    // If loading only show the loading dialog.
    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <HeaderBlock
                title={"Global Management"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setUneditedGlobals(Globals);
                                        } else if (isEditing && isExistingGlobals) {
                                            handleUpdateClicked();
                                        } else {
                                            // wont hithandleSubmitClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingGlobals
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />
            <PageContainer hasHelp={props.type !== FormType.VIEW}>
                {renderData()}
            </PageContainer>
        </div>
    );
}

const hoc = withRouter(GlobalItemPage);

//export
export { hoc as GlobalItemPage };

