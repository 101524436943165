import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
    GET_ALL_MERCHANDISERS,
    GET_MERCHANDISER_BY_ID,
    UPDATE_MERCHANDISER,
    GET_ALL_SUBREGIONS,
    GET_PREFERRED_DAYS,
    SET_PREFERRED_DAYS

} from "../constants/action-types";
import { PreferredDaysModel } from "../../models/PreferredDaysModel";

const API_CONFIG = getApiConfig();

export const getAllMerchandisers = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9001/api/GetAllMerchandisers`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/GetAllMerchandisers`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_ALL_MERCHANDISERS,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getSubRegions = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9001/api/GetSubRegionLookups`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/GetSubRegionLookups`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_ALL_SUBREGIONS,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getMerchandiserById = (merchandiserId, callback) => {
    return async (dispatch) => {
        try {
            const response =
                merchandiserId !== undefined
                    ? await adalApiFetch(
                        Axios,
                        process.env.NODE_ENV !== "production"
                            ? `http://localhost:9001/api/GetMerchandiserById?Id=${merchandiserId}`
                            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/GetMerchandiserById?Id=${merchandiserId}`,
                        {
                            method: "get"
                        }
                    )
                    : {};

            dispatch({
                type: GET_MERCHANDISER_BY_ID,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};



export const updateMerchandiser = (merch, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };
        
        // Cheeky little extra step to flatten the outgoing subregion data. Now in two different flavours.
        let localMerch = Object.assign({}, merch);
        localMerch.SubRegions = merch.SubRegions.map(e => {
            if (e.Id != null) return e.Id
            if (e.SubRegionId != null) return e.SubRegionId
        });

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9001/api/UpdateMerchandiser` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/UpdateMerchandiser`,
                {
                    method: "post",
                    data: localMerch,
                    headers: headers
                });

            dispatch({
                type: UPDATE_MERCHANDISER,
                payload: merch
            });

            callback(true);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};

export const getPreferredDays = (PreferredDaysId, callback) => {
    return async (dispatch) => {
        try {
            const response =
                PreferredDaysId !== undefined
                    ? await adalApiFetch(
                        Axios,
                        process.env.NODE_ENV !== "production"
                            ? `http://localhost:9001/api/GetPreferredDays?Id=${PreferredDaysId}`
                            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/GetPreferredDays?Id=${PreferredDaysId}`,
                        {
                            method: "get"
                        }
                    )
                    : new PreferredDaysModel;

            dispatch({
                type: GET_PREFERRED_DAYS,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};

export const createPreferredDays = (PreferredDaysId, callback) => {
    return async (dispatch) => {
        try {
            const response =
                PreferredDaysId !== undefined
                    ? await adalApiFetch(
                        Axios,
                        process.env.NODE_ENV !== "production"
                            ? `http://localhost:9001/api/GetPreferredDays?Id=${PreferredDaysId}`
                            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/GetPreferredDays?Id=${PreferredDaysId}`,
                        {
                            method: "get"
                        }
                    )
                    : {};
            dispatch({
                type: GET_PREFERRED_DAYS,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};

export const updatePreferredDays = (Model, MerchandiserEmpNumber, callback) => {
    const headers = { "Content-Type": "application/json" };
    return async (dispatch) => {
        try {
            const response =
                await adalApiFetch(
                    Axios,
                    process.env.NODE_ENV !== "production"
                        ? `http://localhost:9001/api/UpdatePreferredDays?MerchandiserEmployeeNumber=${MerchandiserEmpNumber}`
                        : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/UpdatePreferredDays?MerchandiserEmployeeNumber=${MerchandiserEmpNumber}`,
                    {
                        method: "post",
                        data: Model,
                        headers: headers
                    }
                );
            dispatch({
                type: SET_PREFERRED_DAYS,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};
// export const getCustomer = (callback) => { };
// export const getAllCustomers = (callback) => { };