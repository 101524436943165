// React
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Grid,
} from "@material-ui/core";
import styles from "./CustomerListPage.module.scss";

// Actions
import { getAllCustomers } from "../../state/actions/CustomerActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import {PageContainer} from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";


// Other
import { getIcon } from "../../icon/icon";

// Start of main component body
const CustomerListPage = (props) => {
    const allCustomers = useSelector((state) => state.CustomerReducer.Customers);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [currentCustomer, setCurrentCustomer] = useState("");

    useEffect(() => {
        setLoading(true);
        dispatch(getAllCustomers(() => { }));
    }, []);

    useEffect(() => {
        setupData();
    }, [currentCustomer]);

    useEffect(() => {
        if (allCustomers !== undefined) {
            const data = createData(allCustomers);
            setOriginalData(data);
            setSearchedData(data);
            generateCurrentCustomer(allCustomers);
            setupData();
            if (loading) {
                setLoading(false);
            }
        }
    }, [allCustomers]);

    const setupData = () => {
        let filteredCustomers = allCustomers;
        const data = createData(filteredCustomers);

        setOriginalData(data);
        setSearchedData(data);
    };

    const generateCurrentCustomer = (allCustomers) => {
        var c = 0;
        var data = [];
        allCustomers.forEach(customer => {
            data.push({ index: c, id: customer.RegionId });
            c += 1;
        });

        // This is because generateThingy is called in the wrong useEffect,
        // But I have no idea how to fix it. So let's just be very strict.
        if (data != null && data.length > 0) { setCurrentCustomer(data[0].id) };
    }

    const createData = (allCustomers) => {
        const customerKeys = Object.keys(allCustomers);

        return customerKeys.map((customerKey) => {
            const customer = allCustomers[customerKey];
            return {
                number: customer.CustomerNumber,
                name: customer.Name,
                site: customer.Site == "1" ? "Australia" : "New Zealand",
                active: customer.IsActive === true ? "True" : "False"
            };
        });
    };

    // The headers of the table
    const createHeaders = () => {
        return [
            {
                id: "number",
                clickable: true,
                action: { path: "/customer/{id}", identifier: "number" },
                align: "left",
                label: "Customer Number"
            },
            {
                id: "name",
                clickable: true,
                action: { path: "/customer/{id}", identifier: "number" },
                align: "left",
                label: "Customer Name"
            },
            {
                id: "site",
                align: "left",
                label: "Territory"
            },
            {
                id: "active",
                align: "left",
                label: "Is Active"
            }
        ];
    };

    // Create the cells for the table and configure them.
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};

            cell.id = header.id;

            if (header.hasOwnProperty("action")) {
                cell.handleClick = (event, row) => {
                    let path = header.action.path.replace(
                        "{id}",
                        row[header.action.identifier]
                    );
                    props.history.push(path);
                };
            }

            cell.align = header.align;
            cell.clickable = header.clickable;

            cells.push(cell);
        });

        return cells;
    };

    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("licencse.svg")}
                    text="No Customers found."
                />
            ) : (
                    <SimpleTable
                        handleSelection={null}
                        isSelectable={false}
                        onClick={() => { }}
                        dataId={"activityGroupId"}
                        cells={cells}
                        headers={headers}
                        rows={searchedData}
                    />
                );

        return data;
    };

    return (
        <div>
            <HeaderBlock
                title={"Customer List"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <Search
                                searchField={["name", "number"]}
                                returnSearchMatches={(data) => {
                                    setSearchedData(data);
                                }}
                                data={originalData}
                                searchTitle="Search Customers"
                            />
                        </Grid>
                        <ExportExcel csvData={searchedData} fileName="customers" />
                        <Grid item>
                            <ButtonGroup classes={{ root: styles.buttonGroup }}>
                                <Button
                                    onClick={() => {
                                        props.history.push("/customer/new");
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                >
                                    New Customer
                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />
            <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
        </div>
    );
};

const hoc = withRouter(CustomerListPage);

// EXPORT COMPONENT
export { hoc as CustomerListPage };