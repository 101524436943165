const environment = process.env.REACT_APP_ENVIRONMENT;
const flavour = process.env.REACT_APP_FLAVOUR;
const url = process.env.REACT_APP_API_URL;
const coreApim = process.env.REACT_APP_CORE_APIM;
const sammApim = process.env.REACT_APP_SAMM_APIM;

export function getApiConfig() {
  if (environment !== undefined && flavour !== undefined && coreApim !== undefined && sammApim !== undefined) {
    return {
      URL: url,
      CORE: coreApim,
      SAMM: sammApim
    };
  }

  return {};
}
