import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
    GET_GLOBALS,
    UPDATE_GLOBALS
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

export const getGlobals = (site, callback) => {
    return async (dispatch) => {
        try {
            const response =
                site !== undefined
                    ? await adalApiFetch(
                        Axios,
                        process.env.NODE_ENV !== "production"
                            ? `http://localhost:9001/api/GetGlobals?Site=${site}`
                            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/GetGlobals?Site=${site}`,
                        {
                            method: "get"
                        }
                    )
                    : {};

            dispatch({
                type: GET_GLOBALS,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};



export const updateGlobals = (g, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9001/api/UpdateGlobals?Site=${g.Site}` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/UpdateGlobals?Site=${g.Site}`,
                {
                    method: "post",
                    data: g,
                    headers: headers
                });

            dispatch({
                type: UPDATE_GLOBALS,
                payload: g
            });

            callback(true);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};
