import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { TextField, Typography } from '@material-ui/core';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    errorMessage: {
        color: "#FF0000",
    }
}));

export default function TimesheetReasonModal(props) {
    const classes = useStyles();
    const [selected, setSelected] = useState(null);
    const [reasonMessage, setReasonMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const TimesheetReasons = useSelector((state) => state.TimesheetReducer.TimesheetReasons);

    const submitForm = () => {
        if (selected == null || reasonMessage == null) {
            setErrorMessage("Error: All fields need to be set");
        } else {
            props.submitTimesheetReason(selected, reasonMessage);
            setSelected(null);
            setReasonMessage(null);
            setErrorMessage(null);
        }
    }

    return (
        <Dialog
            open={props.open}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Overtime Reason Dialog</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please select a reason for {props.CallType} to go over allocated time.
                </DialogContentText>
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Select Reason</InputLabel>
                        <Select
                            value={selected}
                            onChange={(event) => { setSelected(event.target.value) }}
                        >
                            {TimesheetReasons && TimesheetReasons.map(item => (<MenuItem key={item.Key} value={item.Key}>{item.Value}</MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            value={reasonMessage}
                            placeholder="Reason Message"
                            onChange={(event) => { setReasonMessage(event.target.value) }}
                        />
                    </FormControl>
                </form>
                <br></br>
                <Typography className={classes.errorMessage}>{errorMessage}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => submitForm()} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}