// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import { Box, Button, ButtonGroup, Grid } from "@material-ui/core";
import styles from "./MigrationPage.module.scss";

// Actions
import {
    HolidayCalendarMassUpload,
    MerchandiserCardMassUpload
} from "../../state/actions/MigrationActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";


// Components
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { PageContainer } from "../../components/page-container/PageContainer";
import { LoadingComponent } from "../../components/loading2/CircleLoader";

// Segments
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import { UUID } from "../../helpers/uuid";

// API
import { getApiConfig } from "../../config/apiConfig";
const API_CONFIG = getApiConfig();
const templateUrl = process.env.NODE_ENV !== "production"
    ? `http://localhost:9004/api/GetHolidayCalendar`
    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/GetHolidayCalendar`

// Moment
var moment = require('moment');
moment().format();

const HolidayCalendarMigrationPage = (props) => {
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [csvData, setCsvData] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [targetsEdited, setTargetsEdited] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);


    const dispatch = useDispatch();

    //Use Effects
    useEffect(() => {
        setLoading(true);
        if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
            dispatch(
                getLoggedInUserByEmail(() => {
                    setLoggedInUserCallMade(true);
                })
            );
        } else {
            setLoggedInUserCallMade(true);
        }
    }, []);

    useEffect(() => {
        if (targetsEdited) {
            setSubmitEnabled(true);
        }
    }, [targetsEdited]);

    useEffect(() => {
        if (dataChanged) {
            setDataChanged(false);
        }
    }, [dataChanged]);

    const handleSubmit = async () => {
        setIsUploading(true);
        if (!submitEnabled) {
            return;
        }

        setSubmitEnabled(false);

        const data = createRequestBody();

        dispatch(
            HolidayCalendarMassUpload(
                data,
                (res) => {
                    if (res) {
                        props.history.push("/post-migration");
                    }
                }
            )
        );
    };

    const createRequestBody = () => {
        let toReturn = [];
        csvData.forEach(row => {
            var dateSplit = row.Date.split(" ");
            const date = moment(dateSplit[0], 'MM/DD/YYYY')
            
            const requestBody = {
                ...row,
                Date: moment(date).format(),
                CreatedDate: moment().toISOString(),
                CreatedBy: loggedInUser.userName,
                ModifiedBy: loggedInUser.userName,
                ModifiedDate: moment().toISOString()
            };
            toReturn.push(requestBody);
        });
        return toReturn;
    };

    const renderSubmitAndCancelButtons = () => {
        return [
            <Button
                key={UUID()}
                variant="outlined"
                onClick={() => handleSubmit()}
                disabled={!submitEnabled}
            >
                Submit
            </Button>
        ];
    };

    return (
        <Box>
            <Fragment>

                {isUploading ? (
                    <>
                        <HeaderBlock
                            title={"Please Wait: Holiday Calendar Data is being uploaded"}
                        />
                        <PageContainer>
                            <LoadingComponent></LoadingComponent>
                        </PageContainer>
                    </>
                ) : (
                    <>
                        <HeaderBlock
                            title={"Holiday Calendar Mass Upload"}
                            right={
                                renderSubmitAndCancelButtons() ? (
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        justify="flex-end"
                                    >
                                        <Grid item>
                                            <ButtonGroup>{renderSubmitAndCancelButtons()}</ButtonGroup>
                                        </Grid>
                                    </Grid>
                                ) : null
                            }
                        />

                        <PageContainer>
                            <div className={styles.contentGrid}>
                                <Grid container spacing={3}>
                                    <Grid key={"migrationUploadSegment"} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <MigrationUploadSegment
                                            csvData={csvData}
                                            setCsvData={setCsvData}
                                            setDataChanged={setDataChanged}
                                            title="Upload Holiday Calendar Data"
                                            targetsEdited={targetsEdited}
                                            setTargetsEdited={setTargetsEdited}
                                            isEditing={isEditing}
                                            setFormEdited={setFormEdited}
                                            description="Please upload a CSV containing the Holiday Calendar Data that you would like uploaded to SaM-M"
                                            columns={["Region", "Name", "Date"]}
                                            templateFileName="RegionHolidays.csv"
                                            allDataUrl={templateUrl}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </PageContainer>
                    </>)}
            </Fragment>
        </Box>
    );
};

const hoc = withRouter(HolidayCalendarMigrationPage);

// EXPORT COMPONENT
export { hoc as HolidayCalendarMigrationPage };