// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Divider,
    Snackbar
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./CustomerItemPage.module.scss";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";
import CustomizedSnackbars from "../../components/snackbar/Snackbar";

// Actions
import {
    createServicePolicy,
    getServicePolicy,
    updateServicePolicy,
    downloadServicePolicy
} from "../../state/actions/CustomerActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import {PageContainer} from "../../components/page-container/PageContainer";

// Models
import { CustomerServicePolicyModel } from "../../models/CustomerServicePolicyModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";
import { getApiConfig } from "../../config/apiConfig";
import {handleDownloadOnClickActions} from "../../state/actions/DownloadActions.js";

const API_CONFIG = getApiConfig();

const CustomerServicePolicyItemPage = (props) => {
    const currentPolicy = useSelector(
        (state) => state.CustomerReducer.Policy
    );

    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [formProperties, setFormProperties] = useState(CustomerServicePolicyModel);
    const [ServicePolicy, setServicePolicy] = useState({});
    const [uneditedCustomerServicePolicy, setUneditedCustomerServicePolicy] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingCustomerServicePolicy, setIsExistingCustomerServicePolicy] = useState(true);
    const [ServicePolicyId, setServicePolicyId] = useState("");
    const [dataChanged, setDataChanged] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const [getServicePolicyCallMade, setGetServicePolicyCallMade] = useState(false);
    const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");


    //Use Effects
    useEffect(() => {
        setLoading(true);
        if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
            dispatch(
                getLoggedInUserByEmail(() => {
                    setLoggedInUserCallMade(true);
                })
            );
        } else {
            setLoggedInUserCallMade(true);
        }
        setServicePolicy(CustomerServicePolicyModel);
    }, []);

    //Use Effects
    useEffect(() => {
        setLoading(true);

        setIsExistingCustomerServicePolicy(
            props.type != null && props.type === FormType.VIEW
        );
    }, []);

    useEffect(() => {
        if (props.type != null && props.type === FormType.VIEW) {
            dispatch(
                getServicePolicy(props.match.params.CustomerServicePolicyId, () => {
                    setGetServicePolicyCallMade(true);
                    setServicePolicyId(props.match.params.CustomerServicePolicyId);
                })
            );
        } else {
            dispatch(
                getServicePolicy(undefined, () => {
                    setGetServicePolicyCallMade(true);
                    setIsEditing(true);
                })
            );
        }
    }, []);

    useEffect(() => {
        if (getServicePolicyCallMade) {
            setLoading(false);
            setDataChanged(!dataChanged);
        }
    }, [getServicePolicyCallMade]);


    useEffect(() => {
        // Update the DisplayName of the call schedule as we only get the code down from the backend
        let localPolicy = currentPolicy;
        setServicePolicy(localPolicy);

    }, [currentPolicy]);

    // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
    // This is so the values in the form fields updates correctly on change.
    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, ServicePolicy);

        localData[name] = value;

        setServicePolicy(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };

    /*
       Generic validation to perform on all field types
      */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            ServicePolicy[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    /*
        Validate the form before it is submitted.
        Dates not only need to be checked for validity they also need to be compared with each other.
     */
    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }

        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;
        ServicePolicy.CustomerNumber = props.match.params["CustomerNumber"];

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";

            // Validate specifically against effectivedate.
            if (key === "effectiveDate") {
                errorMessage = checkGenericField(key, formProperties[key].required);
                console.log(ServicePolicy[key].toString());
                if (errorMessage === "") {
                    if (
                        isValid(ServicePolicy["effectiveDate"])
                    ) {
                        // ServicePolicy["effectiveDate"].toISOString(),
                        //     ServicePolicy[key].toISOString()
                    }
                }
            }
            errorMessage = checkGenericField(key, formProperties[key].required);
            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            return true;
        }
    };

    /* Transform the activity group data into the form that is required by the backend */
    const createRequestBody = () => {
        let requestBody = {
            ...ServicePolicy,
            CreatedBy: loggedInUser.userId,
            ModifiedBy: loggedInUser.userId
        };
        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            dispatch(
                createServicePolicy(createRequestBody(true), (success, id) => {
                    if (success) {
                        setIsEditing(false);
                        props.history.push(`/customer/${id}`);
                    } else {
                        setIsEditing(true);
                        setSubmitEnabled(true);
                        setFormEdited(true);
                    }
                })
            );
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            dispatch(
                updateServicePolicy(ServicePolicy, () => {
                    setIsEditing(false);
                })
            );
        }
    };

    /*
      Functionality for when the user clicks the cancel button on the edit or create form
    */
    const handleCancelEditButtonClicked = () => {
            setIsEditing(false);

            //if (!isExistingCustomerServicePolicy) {
                props.history.goBack();
            //}
    };

    const handleServicePolicyDownload = () => {
        if (ServicePolicyId != null) {
            let url =
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9002/api/GetCustomerServicePolicy?CustomerServicePolicyId=${ServicePolicyId}&DownloadResult=true`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/GetCustomerServicePolicy?CustomerServicePolicyId=${ServicePolicyId}&DownloadResult=true`;
            handleDownloadOnClickActions(url, "CustomerServicePolicy");
        } else {
            setSnackbarOpen(true);
            setSnackbarMessage("Only existing policies can be downloaded.");
        }

    }

    // Display Customers and a list of their associated service policies below.
    const renderData = () => {
        return (
            <Fragment>
                <div className={styles.contentGrid}>
                    <CustomCard title="Main Details" actionButton={[
                        <Button
                            key={"BBBBBBBBBBBBB"}
                            className={styles.buttonStyle}
                            variant="outlined"
                            color="secondary"
                            onClick={(event) => handleServicePolicyDownload(event)}
                        >
                            Download Policy
                        </Button>
                    ]}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Day1Hours.value}
                                    name={formProperties.Day1Hours.value}
                                    label={formProperties.Day1Hours.value}
                                    placeholder="Monday Hours"
                                    value={
                                        ServicePolicy !== undefined &&
                                            Object.keys(ServicePolicy).length > 0 &&
                                            ServicePolicy.Day1Hours !== undefined
                                            ? ServicePolicy.Day1Hours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Day1Hours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Day1Hours.errorMessage !== ""}
                                    helperText={formProperties.Day1Hours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Day2Hours.value}
                                    name={formProperties.Day2Hours.value}
                                    label={formProperties.Day2Hours.value}
                                    placeholder="Tuesday Hours"
                                    value={
                                        ServicePolicy !== undefined &&
                                            Object.keys(ServicePolicy).length > 0 &&
                                            ServicePolicy.Day2Hours !== undefined
                                            ? ServicePolicy.Day2Hours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Day2Hours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Day2Hours.errorMessage !== ""}
                                    helperText={formProperties.Day2Hours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Day3Hours.value}
                                    name={formProperties.Day3Hours.value}
                                    label={formProperties.Day3Hours.value}
                                    placeholder="Wednesday Hours"
                                    value={
                                        ServicePolicy !== undefined &&
                                            Object.keys(ServicePolicy).length > 0 &&
                                            ServicePolicy.Day3Hours !== undefined
                                            ? ServicePolicy.Day3Hours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Day3Hours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Day3Hours.errorMessage !== ""}
                                    helperText={formProperties.Day3Hours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Day4Hours.value}
                                    name={formProperties.Day4Hours.value}
                                    label={formProperties.Day4Hours.value}
                                    placeholder="Thursday Hours"
                                    value={
                                        ServicePolicy !== undefined &&
                                            Object.keys(ServicePolicy).length > 0 &&
                                            ServicePolicy.Day4Hours !== undefined
                                            ? ServicePolicy.Day4Hours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Day4Hours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Day4Hours.errorMessage !== ""}
                                    helperText={formProperties.Day4Hours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Day5Hours.value}
                                    name={formProperties.Day5Hours.value}
                                    label={formProperties.Day5Hours.value}
                                    placeholder="Friday Hours"
                                    value={
                                        ServicePolicy !== undefined &&
                                            Object.keys(ServicePolicy).length > 0 &&
                                            ServicePolicy.Day5Hours !== undefined
                                            ? ServicePolicy.Day5Hours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Day5Hours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Day5Hours.errorMessage !== ""}
                                    helperText={formProperties.Day5Hours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Day6Hours.value}
                                    name={formProperties.Day6Hours.value}
                                    label={formProperties.Day6Hours.value}
                                    placeholder="Saturday Hours"
                                    value={
                                        ServicePolicy !== undefined &&
                                            Object.keys(ServicePolicy).length > 0 &&
                                            ServicePolicy.Day6Hours !== undefined
                                            ? ServicePolicy.Day6Hours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Day6Hours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Day6Hours.errorMessage !== ""}
                                    helperText={formProperties.Day6Hours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Day7Hours.value}
                                    name={formProperties.Day7Hours.value}
                                    label={formProperties.Day7Hours.value}
                                    placeholder="Sunday Hours"
                                    value={
                                        ServicePolicy !== undefined &&
                                            Object.keys(ServicePolicy).length > 0 &&
                                            ServicePolicy.Day7Hours !== undefined
                                            ? ServicePolicy.Day7Hours
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Day7Hours.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Day7Hours.errorMessage !== ""}
                                    helperText={formProperties.Day7Hours.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="dense"
                                        disabled={!isEditing}
                                        disableToolbar
                                        fullWidth
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        label={formProperties.EffectiveDate.label}
                                        value={
                                            ServicePolicy !== undefined &&
                                                Object.keys(ServicePolicy).length > 0 &&
                                                ServicePolicy.EffectiveDate !== undefined
                                                ? ServicePolicy.EffectiveDate
                                                : new Date()
                                        }
                                        onChange={(date) => {
                                            handleInputChange(formProperties.EffectiveDate.value, date);
                                        }}
                                        error={formProperties.EffectiveDate.errorMessage !== ""}
                                        helperText={formProperties.EffectiveDate.errorMessage}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsActive.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                ServicePolicy !== undefined &&
                                                    ServicePolicy.IsActive !== undefined
                                                    ? ServicePolicy.IsActive
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsActive.value,
                                                    !ServicePolicy.IsActive
                                                )
                                            }
                                            name={formProperties.IsActive.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </div>
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <HeaderBlock
                title={"Policy Management"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setUneditedCustomerServicePolicy(ServicePolicy);
                                        } else if (isEditing && isExistingCustomerServicePolicy) {
                                            handleUpdateClicked();
                                        } else {
                                            handleSubmitClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingCustomerServicePolicy
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />
            <CustomizedSnackbars
                handleClose={() => {
                    setSnackbarOpen(false);
                }}
                open={snackbarOpen}
                variant="info"
                message={snackbarMessage}
            />
            <PageContainer hasHelp={props.type !== FormType.VIEW}>
                {renderData()}
            </PageContainer>
        </div>
    );
};

const hoc = withRouter(CustomerServicePolicyItemPage);

// EXPORT COMPONENT
export { hoc as CustomerServicePolicyItemPage };
