import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeaderBlock.module.scss'; // Import css modules stylesheet as styles

export class HeaderBlock extends React.Component {

  render() {
    const { title } = this.props;

    return (
      <div className={styles.headerBlock}>
        <div className={styles.leftContainer}>
          {title}
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.rightItem}>
            {this.props.right}
          </div>
        </div>
      </div>
    )
  }
}

HeaderBlock.propTypes = {
  title: PropTypes.string
};
