import { AuthenticationContext, adalFetch } from "react-adal";

const tenantEnv = process.env.REACT_APP_TENANT;
const clientIdEnv = process.env.REACT_APP_CLIENT_ID;
const redirectUriEnv = process.env.REACT_APP_REDIRECT_URI;

let config = {
  tenant: tenantEnv,
  clientId: clientIdEnv,
  redirectUri: redirectUriEnv,
  cacheLocation: "sessionStorage"
};

export const authContext = new AuthenticationContext(config);

export const getToken = () => authContext.getCachedToken(config.clientId);
export const getTokenAsync = (callback) =>
  authContext.acquireToken(config.clientId, callback);
export const getUser = () => authContext.getCachedUser(config.clientId);
export const logout = () => authContext.logOut();
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, config.clientId, fetch, url, options);
