// React
import React from "react";

// UI and Styling
import { Button } from "@material-ui/core";

// Other
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// Component used to export the past in data to an .xlsx file
const ExportExcel = ({ csvData, fileName, classes }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  //Export the data
  const exportData = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  //Render the button
  return (
    <Button
      variant="outlined"
      size="large"
      onClick={() => {
        exportData(csvData, fileName);
      }}
      className={classes}
    >
      Export to Excel
    </Button>
  );
};

export default ExportExcel;
