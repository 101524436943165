import {
    GET_ALL_CALL_TYPES,
    UPSERT_CALL_TYPE,
    GET_CALL_TYPE
} from "../constants/action-types";


// INITIALIZE STATE
const initialState = {
    CallTypes: [],
    Current: {},
};

// REDUCER
export const CallsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CALL_TYPES: {
            return {
                ...state,
                CallTypes: action.payload.data
            }
        }
        case UPSERT_CALL_TYPE: {
            return {
                ...state,
                Current: action.payload.data
            }
        }
        case GET_CALL_TYPE: {
            return {
                ...state,
                Current: action.payload.data
            }
        }

        default:
            return state;
    }
};
