// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Divider
} from "@material-ui/core";

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./MerchandiserItemPage.module.scss";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// Actions
import {
    getMerchandiserById,
    updateMerchandiser,
    getSubRegions
} from "../../state/actions/MerchandiserActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import {PageContainer} from "../../components/page-container/PageContainer";
import EditingAlert from "../../components/editing-alert/EditingAlert";

// Models
import { MerchandiserModel } from "../../models/MerchandiserModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";
import DropdownSelect from "../../components/dropdown-select/DropdownSelect";

const MerchandiserItemPage = (props) => {
    // Lists retrieved via reducer fields.
    const currentMerchandiser = useSelector((state) => state.MerchandiserReducer.Current);
    const allSubRegions = useSelector((state) => state.MerchandiserReducer.SubRegions);
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [formProperties, setFormProperties] = useState(MerchandiserModel);
    const [Merchandiser, setMerchandiser] = useState({});
    const [SubRegions, setSubRegions] = useState({});
    const [uneditedMerchandiser, setUneditedMerchandiser] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingMerchandiser, setIsExistingMerchandiser] = useState(true);
    const [MerchandiserId, setMerchandiserId] = useState("");
    const [dataChanged, setDataChanged] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const [getMerchandiserByIdCallMade, setGetMerchandiserByIdCallMade] = useState(false);
    const [getSubRegionsCallMade, setGetSubRegionsCallMade] = useState(false);

    //Use Effects
    useEffect(() => {
        setLoading(true);

        setIsExistingMerchandiser(
            props.type != null && props.type === FormType.VIEW
        );
    }, []);

    useEffect(() => {
        if (props.type != null && props.type === FormType.VIEW) {
            dispatch(
                getSubRegions(() => {
                    setGetSubRegionsCallMade(true);
                })
            );
            dispatch(
                getMerchandiserById(props.match.params.MerchandiserId, () => {
                    setGetMerchandiserByIdCallMade(true);
                    setMerchandiserId(props.match.params.MerchandiserId);
                })
            );
        } else {
            dispatch(
                getMerchandiserById(undefined, () => {
                    setGetMerchandiserByIdCallMade(true);
                    setIsEditing(true);
                })
            );
        }
    }, []);

    useEffect(() => {
        if (getMerchandiserByIdCallMade) {
            setLoading(false);
            setDataChanged(!dataChanged);
        }
    }, [getMerchandiserByIdCallMade]);


    useEffect(() => {
        let localMerchandiser = {};

        // State will invalidate on updates if I don't have this fallback call.
        // I have no idea why it works, why we need it, or why it doesn't work like CustomerItemPage.
        // What even is this language anyway?
        if (currentMerchandiser != null) {
            localMerchandiser = currentMerchandiser;
        } else {
            localMerchandiser = Merchandiser;
        }

        setMerchandiser(localMerchandiser);

    }, [currentMerchandiser]);

    useEffect(() => {
        let localSubRegions = allSubRegions;
        setSubRegions(localSubRegions);
    }, [allSubRegions]);

    const getPreferredDaysList = (data, fileName) => {
        const listData =
            Merchandiser != null &&
                Merchandiser.PreferredDays != null
                ? Merchandiser.PreferredDays.map((item) => {
                    return {
                        id: item.Id,
                        day1: item.Day1 == true ? "Yes" : "No",
                        day2: item.Day2 == true ? "Yes" : "No",
                        day3: item.Day3 == true ? "Yes" : "No",
                        day4: item.Day4 == true ? "Yes" : "No",
                        day5: item.Day5 == true ? "Yes" : "No",
                        day6: item.Day6 == true ? "Yes" : "No",
                        day7: item.Day7 == true ? "Yes" : "No",
                        effectiveDate: item.EffectiveDate.split('T')[0],
                        isActive: item.IsActive ? "Yes" : "No"
                    };
                })
                : [];

        const columns = [
            {
                name: "id",
                label: "Id",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const url = `/merchandiser/${Merchandiser.Merchandiser.EmployeeNumber}/preferred-days/${value}`;
                        return <a href={url}>{value}</a>;
                    }
                }
            },
            {
                name: "day1",
                label: "Monday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day2",
                label: "Tuesday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day3",
                label: "Wednesday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day4",
                label: "Thursday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day5",
                label: "Friday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day6",
                label: "Saturday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day7",
                label: "Sunday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "effectiveDate",
                label: "Effective Date",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "isActive",
                label: "Active?",
                options: {
                    filter: false,
                    sort: true
                }
            }
        ];

        const options = {
            filter: true,
            responsive: "stacked",
            rowsPerPage: 20,
            rowsPerPageOptions: [5, 20, 50, 100, 200],
            viewColumns: false,
            filterType: "checkbox",
            selectableRows: "none",
            print: false,
            download: true,
            downloadOptions: {
                filename: { fileName },
                separator: ",",
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                }
            }
        };

        options.customToolbar = () => {
            return (
                <Fragment>
                    <Tooltip title="Add Policy">
                        <IconButton
                            onClick={() =>
                                props.history.push(
                                    `/merchandiser/${Merchandiser.Merchandiser.EmployeeNumber}/preferred-days/new`
                                )
                            }
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Fragment>
            );
        };

        return (
            <MUIDataTable
                title="Preferred Days"
                data={listData}
                columns={columns}
                options={options}
            />
        );
    }

    // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
    // This is so the values in the form fields updates correctly on change.
    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, Merchandiser);

        // All controls except SubRegion are nested within an object, so handle with care.
        if (name == "SubRegions") {
            localData[name] = value;
        } else {
            localData.Merchandiser[name] = value;
        }
        setMerchandiser(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };

    /*
       Generic validation to perform on all field types
      */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            Merchandiser[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    /*
        Validate the form before it is submitted.
        Dates not only need to be checked for validity they also need to be compared with each other.
     */
    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }

        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";
            errorMessage = checkGenericField(key, formProperties[key].required);
            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            setSubmitEnabled(true);
            return true;
        }
    };

    /* Transform the activity group data into the form that is required by the backend */
    const createRequestBody = () => {
        let requestBody = {
            ...Merchandiser
        };

        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            Merchandiser.Merchandiser.ModifiedBy = loggedInUser.userName;
            dispatch(
                updateMerchandiser(Merchandiser, (success, id) => {
                    if (success) {
                        setIsEditing(false);
                        props.history.push(`/merchandiser/${props.match.params.MerchandiserId}`);
                    } else {
                        setIsEditing(true);
                        setSubmitEnabled(true);
                        setFormEdited(true);
                    }
                })
            );
        }
    };

    /*
      Functionality for when the user clicks the cancel button on the edit or create form
    */
    const handleCancelEditButtonClicked = () => {
        if (formEdited) {
            setCancelEditingAlertOpen(true);
        } else {
            setIsEditing(false);

            if (!isExistingMerchandiser) {
                props.history.push(`/merchandiser`);
            }
        }
    };

    // Display a merchandiser and their preferred day listings below that.
    const renderData = () => {
        return (
            <Fragment>
                <EditingAlert
                    isEditing={isEditing}
                    title={"Information"}
                    severity={"info"}
                    body={["Not all fields of this data are editable, non-editable fields will be derived from ", <strong>SuccessFactors</strong>, " when available."]}
                />
                <div className={styles.contentGrid}>
                    <CustomCard title="Details" actionButton={[]}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.EmployeeNumber.value}
                                    name={formProperties.EmployeeNumber.value}
                                    label={formProperties.EmployeeNumber.value}
                                    placeholder="Merchandiser Number"
                                    value={
                                        Merchandiser != null &&
                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.EmployeeNumber !== undefined
                                            ? Merchandiser.Merchandiser.EmployeeNumber
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.EmployeeNumber.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.EmployeeNumber.errorMessage !== ""}
                                    helperText={formProperties.EmployeeNumber.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Site.value}
                                    name={formProperties.Site.value}
                                    label={formProperties.Site.value}
                                    placeholder="Territory"
                                    value={
                                        Merchandiser != null &&

                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.Site !== undefined
                                            ? Merchandiser.Merchandiser.Site
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Site.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Site.errorMessage !== ""}
                                    helperText={formProperties.Site.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.FirstName.value}
                                    name={formProperties.FirstName.value}
                                    label={formProperties.FirstName.label}
                                    placeholder="First Name"
                                    value={
                                        Merchandiser != null &&

                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.FirstName !== undefined
                                            ? Merchandiser.Merchandiser.FirstName
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.FirstName.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.FirstName.errorMessage !== ""}
                                    helperText={formProperties.FirstName.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.PreferredName.value}
                                    name={formProperties.PreferredName.value}
                                    label={formProperties.PreferredName.label}
                                    placeholder="Preferred Name"
                                    value={
                                        Merchandiser != null &&

                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.PreferredName !== undefined
                                            ? Merchandiser.Merchandiser.PreferredName
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.PreferredName.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.PreferredName.errorMessage !== ""}
                                    helperText={formProperties.PreferredName.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.LastName.value}
                                    name={formProperties.LastName.value}
                                    label={formProperties.LastName.label}
                                    placeholder="Last Name"
                                    value={
                                        Merchandiser != null &&

                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.LastName !== undefined
                                            ? Merchandiser.Merchandiser.LastName
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.LastName.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.LastName.errorMessage !== ""}
                                    helperText={formProperties.LastName.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.Email.value}
                                    name={formProperties.Email.value}
                                    label={formProperties.Email.value}
                                    placeholder="Email"
                                    value={
                                        Merchandiser != null &&

                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.Email !== undefined
                                            ? Merchandiser.Merchandiser.Email
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Email.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Email.errorMessage !== ""}
                                    helperText={formProperties.Email.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={4} md={4}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.Phone.value}
                                    name={formProperties.Phone.value}
                                    label={formProperties.Phone.value}
                                    placeholder="Telephone Number"
                                    value={
                                        Merchandiser != null &&

                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.Phone !== undefined
                                            ? Merchandiser.Merchandiser.Phone
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Phone.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Phone.errorMessage !== ""}
                                    helperText={formProperties.Phone.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={4} md={4}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.SalesOffice.value}
                                    name={formProperties.SalesOffice.value}
                                    label={formProperties.SalesOffice.label}
                                    placeholder="Sales Office"
                                    value={
                                        Merchandiser != null &&

                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.SalesOffice !== undefined
                                            ? Merchandiser.Merchandiser.SalesOffice
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.SalesOffice.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.SalesOffice.errorMessage !== ""}
                                    helperText={formProperties.SalesOffice.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <DropdownSelect
                                    fullWidth={true}
                                    label="Preferred Sub Region"
                                    disabled={
                                        !isEditing
                                    }
                                    handleChange={(value) => {
                                        var localMerchandiser = Object.assign({}, Merchandiser);
                                        localMerchandiser.Merchandiser.PreferredSubRegionId = value;

                                        setMerchandiser(localMerchandiser);
                                    }}
                                    data={Object.values(allSubRegions).map(
                                        x => x.Id
                                    )}
                                    value={
                                        Merchandiser != null && Merchandiser.Merchandiser != null ? Merchandiser.Merchandiser.PreferredSubRegionId : ""
                                    }
                                    valueName={"PreferredSubRegionId"}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.PreferredBanner.value}
                                    name={formProperties.PreferredBanner.value}
                                    label={formProperties.PreferredBanner.label}
                                    placeholder="Preferred Banner"
                                    value={
                                        Merchandiser != null &&

                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.PreferredBanner !== undefined
                                            ? Merchandiser.Merchandiser.PreferredBanner
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.PreferredBanner.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.PreferredBanner.errorMessage !== ""}
                                    helperText={formProperties.PreferredBanner.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.TradeChannelCompliance.value}
                                    name={formProperties.TradeChannelCompliance.value}
                                    label={formProperties.TradeChannelCompliance.label}
                                    placeholder="Trade Channel Compliance"
                                    value={
                                        Merchandiser != null &&
                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.TradeChannelCompliance !== undefined
                                            ? Merchandiser.Merchandiser.TradeChannelCompliance
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.TradeChannelCompliance.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.TradeChannelCompliance.errorMessage !== ""}
                                    helperText={formProperties.TradeChannelCompliance.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.ManagerId.value}
                                    name={formProperties.ManagerId.value}
                                    label={formProperties.ManagerId.label}
                                    placeholder="Manager Id"
                                    value={
                                        Merchandiser != null &&
                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.Merchandiser.ManagerId !== undefined
                                            ? Merchandiser.Merchandiser.ManagerId
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.ManagerId.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.TradeChannelCompliance.errorMessage !== ""}
                                    helperText={formProperties.TradeChannelCompliance.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsFullTime.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                Merchandiser != null &&
                                                    Merchandiser.Merchandiser !== undefined &&
                                                    Merchandiser.Merchandiser.IsFullTime !== undefined
                                                    ? Merchandiser.Merchandiser.IsFullTime
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsFullTime.value,
                                                    !Merchandiser.Merchandiser.IsFullTime
                                                )
                                            }
                                            name={formProperties.IsFullTime.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsStudent.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                Merchandiser != null &&
                                                    Merchandiser.Merchandiser !== undefined &&
                                                    Merchandiser.Merchandiser.IsStudent !== undefined
                                                    ? Merchandiser.Merchandiser.IsStudent
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsStudent.value,
                                                    !Merchandiser.Merchandiser.IsStudent
                                                )
                                            }
                                            name={formProperties.IsStudent.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsWorkingForCompetition.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                Merchandiser != null &&
                                                    Merchandiser.Merchandiser !== undefined &&
                                                    Merchandiser.Merchandiser.IsWorkingForCompetition !== undefined
                                                    ? Merchandiser.Merchandiser.IsWorkingForCompetition
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsWorkingForCompetition.value,
                                                    !Merchandiser.Merchandiser.IsWorkingForCompetition
                                                )
                                            }
                                            name={formProperties.IsWorkingForCompetition.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsActive.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                Merchandiser != null &&
                                                    Merchandiser.Merchandiser !== undefined &&
                                                    Merchandiser.Merchandiser.IsActive !== undefined
                                                    ? Merchandiser.Merchandiser.IsActive
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsActive.value,
                                                    !Merchandiser.Merchandiser.IsActive
                                                )
                                            }
                                            name={formProperties.IsActive.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MultiSelectDropDown
                                    fullWidth={true}
                                    id={"merchSubRegions"}
                                    multiple={true}
                                    disabledCloseOnSelect={false}
                                    disabled={!isEditing}
                                    label={"Available Sub Regions"}
                                    options={allSubRegions !== undefined ? allSubRegions : []}
                                    selectedValues={
                                        Merchandiser != null &&
                                            Object.keys(Merchandiser).length > 0 &&
                                            Merchandiser.SubRegions !== undefined &&
                                            Merchandiser.SubRegions.length > 0
                                            ? Merchandiser.SubRegions.map(r => {
                                                if (r.SubRegionName != null) {
                                                    return { DisplayName: r.SubRegionName + "(" + r.SubRegionId + ")", Id: r.SubRegionId }
                                                } else {
                                                    return { DisplayName: r.DisplayName, Id: r.Id }
                                                }
                                            })
                                            : []
                                    }
                                    variant="outlined"
                                    textFieldLabel="Select Sub Regions"
                                    textFieldPlaceHolder="Sub Region"
                                    onChange={(event, value) => {
                                        handleInputChange(formProperties.SubRegions.value, value)
                                    }}
                                />
                            </Grid>


                        </Grid>
                    </CustomCard>
                </div>
                {/* Only show these if we're on an existing Customer. */}
                {!loading && (
                    <Fragment>
                        <div className={styles.contentGrid}>{getPreferredDaysList()}</div>
                    </Fragment>
                )}
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <HeaderBlock
                title={Merchandiser != null && Merchandiser.Merchandiser != null ? Merchandiser.Merchandiser.FirstName + " " + Merchandiser.Merchandiser.LastName + " (" + Merchandiser.Merchandiser.EmployeeNumber + ")" : "New Merchandiser"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setUneditedMerchandiser(Merchandiser);
                                        } else if (isEditing && isExistingMerchandiser) {
                                            handleUpdateClicked();
                                        } else {
                                            handleUpdateClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingMerchandiser
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />


            <PageContainer>
                {renderData()}
            </PageContainer>
        </div>
    );
};

const hoc = withRouter(MerchandiserItemPage);

// EXPORT COMPONENT
export { hoc as MerchandiserItemPage };
