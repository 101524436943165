import React from "react";
import { css } from "@emotion/core";
import ClockLoader from "react-spinners/ClockLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class LoadingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {
        return (
            <>
                <div
                    style={{
                        width: "80%",
                        marginLeft: "10%",
                        marginBottom: "40px",
                        whiteSpace: 'nowrap',
                    }}
                >
                </div>
                <div className="sweet-loading">
                    <ClockLoader
                        css={override}
                        size={100}
                        color={"gray"}
                        loading={this.state.loading}
                    />
                </div>
            </>
        );
    }
}
export { LoadingComponent as LoadingComponent };