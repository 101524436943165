/* eslint-disable no-use-before-define */

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelectDropDown = (props) => {
  if (props.id != "merchSubRegions") {
    return (
      <Autocomplete
        disabled={props.disabled}
        multiple={props.multiple}
        fullWidth={props.fullWidth}
        size="small"
        margin="dense"
        id={props.key}
        options={props.options}
        disableCloseOnSelect={props.disableCloseOnSelect}
        getOptionLabel={(option) => (option ? option.DisplayName : "")}
        getOptionSelected={(optionToEval, valueToEval) =>
          optionToEval.Id === valueToEval.Id
        }
        value={props.selectedValues}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {props.showCheckbox && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option.DisplayName}
          </React.Fragment>
        )}
        style={{ marginTop: 8, marginBottom: 8 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={props.textFieldLabel}
            placeholder={props.textFieldPlaceHolder}
          />
        )}
        onChange={props.onChange}
      />
    );
  } else {
    return (
      <Autocomplete
        disabled={props.disabled}
        multiple={props.multiple}
        fullWidth={props.fullWidth}
        size="small"
        margin="dense"
        id={props.key}
        options={props.options}
        disableCloseOnSelect={props.disableCloseOnSelect}
        getOptionLabel={(option) => (option ? option.DisplayName : "")}
        getOptionSelected={(optionToEval, valueToEval) =>
          optionToEval.Id === valueToEval.Id
        }
        value={props.selectedValues}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            {props.showCheckbox && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option.DisplayName}
          </React.Fragment>
        )}
        style={{ marginTop: 8, marginBottom: 8 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={props.textFieldLabel}
            placeholder={props.textFieldPlaceHolder}
          />
        )}
        onChange={props.onChange}
      />
    );
  }
};

export default MultiSelectDropDown;
