// IMPORT PACKAGE REFERENCES
import { combineReducers } from "redux";

// IMPORT REDUCERS
import { VersionReducer } from "./VersionReducer";
import { MigrationReducer } from "./MigrationReducer";
import { RoleReducer } from "./RoleReducer";
import { RegionReducer } from "./RegionReducer";
import { CustomerReducer } from "./CustomerReducer";
import { CallsReducer } from "./CallsReducer";
import { UserReducer } from "./UserReducer";
import { RosterReducer } from "./RosterReducer";
import { GlobalsReducer } from "./GlobalsReducer";
import { MerchandiserReducer } from "./MerchandiserReducer";
import { TimesheetReducer } from "./TimesheetReducer";
import { VERSION_UPDATE } from "../constants/action-types";

const AppReducer = combineReducers({
  VersionReducer,
  MigrationReducer,
  RoleReducer,
  RegionReducer,
  CustomerReducer,
  CallsReducer,
  UserReducer,
  RosterReducer,
  GlobalsReducer,
  MerchandiserReducer,
  TimesheetReducer
});

export const RootReducer = (state, action) => {
  if (action.type === VERSION_UPDATE) {
    state = {};
  }

  return AppReducer(state, action);
};
