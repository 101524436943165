import Axios from "axios";
import { adalApiFetch } from "../../config/azureConfig";


export const handleDownloadOnClickActions = async (downloadUrl, fileName = "downloaded-file") => {
    try {
        const response = await adalApiFetch(
            Axios,
            downloadUrl,
            {
                method: "get",
                responseType: "blob"
            }
        );

        const contentType = response.headers['content-type'];
        const contentDisposition = response.headers['content-disposition'];

        // Extract filename from Content-Disposition (e.g., attachment; filename="example.pdf")
        // currently not working because reading contentDisposition are not allowed by backend CORS Policy
        if (contentDisposition && contentDisposition.includes('filename=')) {
            fileName = contentDisposition
                .split('filename=')[1]
                .replace(/"/g, ''); // Remove double quotes around the filename
        }

        // Axios stores the response data (file) in response.data
        const blob = new Blob([response.data], { type: contentType });

        // Create a URL for the blob and trigger a download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url); // Clean up the URL object

        console.log('File downloaded:', fileName, 'of type:', contentType);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};


export const handleShowOnClickActions = async (downloadUrl, fileName = "downloaded-file") => {
    try {
        const response = await adalApiFetch(
            Axios,
            downloadUrl,
            {
                method: "get",
                responseType: "blob"
            }
        );

        const contentType = response.headers['content-type'];
        const contentDisposition = response.headers['content-disposition'];

        // Extract filename from Content-Disposition (e.g., attachment; filename="example.pdf")
        // currently not working because reading contentDisposition are not allowed by backend CORS Policy
        if (contentDisposition && contentDisposition.includes('filename=')) {
            fileName = contentDisposition
                .split('filename=')[1]
                .replace(/"/g, ''); // Remove double quotes around the filename
        }

        // Axios stores the response data (file) in response.data
        const blob = new Blob([response.data], { type: contentType });

        // Create a URL for the blob and open the url on new tab
        const url = window.URL.createObjectURL(blob);
        window.open(url)

        console.log('File downloaded:', fileName, 'of type:', contentType);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};
