export const PreferredDaysModel = {
    Day1: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "Day1",
        label: "Monday "
    },
    Day2: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "Day2",
        label: "Tuesday "
    },
    Day3: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "Day3",
        label: "Wednesday "
    },
    Day4: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "Day4",
        label: "Thursday "
    },
    Day5: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "Day5",
        label: "Friday "
    },
    Day6: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "Day6",
        label: "Saturday "
    },
    Day7: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "Day7",
        label: "Sunday "
    },
    EffectiveDate: {
        type: "date",
        required: true,
        errorMessage: "",
        value: "EffectiveDate",
        label: "Effective Date"
    },
    IsActive: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "IsActive",
        label: "Is Active?"
    },
}
export const PreferredDaysFormModel = {
    Day1: true,
    Day2: true,
    Day3: true,
    Day4: true,
    Day5: true,
    Day6: true,
    Day7: true,
    EffectiveDate: null,
    IsActive: true,
}