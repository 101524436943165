// React
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Grid,
} from "@material-ui/core";
import styles from "./MerchandiserListPage.module.scss";

// Actions
import { getAllMerchandisers } from "../../state/actions/MerchandiserActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import {PageContainer} from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";
import ScrollableTabs from "../../components/tabs/Tabs";


// Other
import { getIcon } from "../../icon/icon";

// Start of main component body
const MerchandiserListPage = (props) => {
    const allMerchandisers = useSelector((state) => state.MerchandiserReducer.Merchandisers);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [currentMerchandiser, setCurrentMerchandiser] = useState("");
    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        setLoading(true);

        dispatch(getAllMerchandisers(() => { }));
    }, []);

    useEffect(() => {
        setupData();
    }, [currentMerchandiser]);

    useEffect(() => {
        if (allMerchandisers !== undefined) {
            const data = createData(allMerchandisers);

            setOriginalData(data);
            setSearchedData(data);
            generateCurrentMerchandiser(allMerchandisers);
            setupData();
            if (loading) {
                setLoading(false);
            }
        }
    }, [allMerchandisers]);

    const setupData = () => {
        const filteredMerchandisers = allMerchandisers.filter((c) =>
            c.Name === currentMerchandiser
        );
        const data = createData(filteredMerchandisers);

        setOriginalData(data);
        setSearchedData(data);
    };

    const generateCurrentMerchandiser = (allMerchandisers) => {
        var c = 0;
        var data = [];
        allMerchandisers.forEach(merchandiser => {
            data.push({ index: c, id: merchandiser.RegionId });
            c += 1;
        });

        // This is because generateThingy is called in the wrong useEffect,
        // But I have no idea how to fix it. So let's just be very strict.
        if (data != null && data.length > 0) { setCurrentMerchandiser(data[0].id) };
    }

    const createData = (allMerchandisers) => {
        const merchandiserKeys = Object.keys(allMerchandisers);

        return merchandiserKeys.map((merchandiserKey) => {
            const merchandiser = allMerchandisers[merchandiserKey];
            return {
                name: merchandiser.EmployeeNumber,
                fname: merchandiser.FirstName + " " + merchandiser.LastName,
                site: merchandiser.Site == "1" ? "Australia" : "New Zealand",
                active: merchandiser.IsActive === true ? "True" : "False"
            };
        });
    };

    // The headers of the table
    const createHeaders = () => {
        return [
            {
                id: "name",
                clickable: true,
                action: { path: "/merchandiser/{id}", identifier: "name" },
                align: "left",
                label: "Emp. Number"
            },
            {
                id: "fname",
                align: "left",
                label: "Name"
            },
            {
                id: "site",
                align: "left",
                label: "Territory"
            },
            {
                id: "active",
                align: "left",
                label: "Is Active"
            }
        ];
    };

    // Create the cells for the table and configure them.
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};

            cell.id = header.id;

            if (header.hasOwnProperty("action")) {
                cell.handleClick = (event, row) => {
                    let path = header.action.path.replace(
                        "{id}",
                        row[header.action.identifier]
                    );
                    props.history.push(path);
                };
            }

            cell.align = header.align;
            cell.clickable = header.clickable;

            cells.push(cell);
        });

        return cells;
    };

    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("licencse.svg")}
                    text="No Merchandisers found."
                />
            ) : (
                    <SimpleTable
                        handleSelection={null}
                        isSelectable={false}
                        onClick={() => { }}
                        dataId={"activityGroupId"}
                        cells={cells}
                        headers={headers}
                        rows={searchedData}
                    />
                );

        return data;
    };

    return (
        <div>
            <HeaderBlock
                title={"Merchandiser List"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <Search
                                searchField={["fname", "name"]}
                                returnSearchMatches={(data) => {
                                    setSearchedData(data);
                                }}
                                data={originalData}
                                searchTitle="Search Merchandisers"
                            />
                        </Grid>
                        <ExportExcel csvData={searchedData} fileName="merchandisers" />
                    </Grid>
                }
            />
            <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
        </div>
    );
};

const hoc = withRouter(MerchandiserListPage);

// EXPORT COMPONENT
export { hoc as MerchandiserListPage };