export const VERSION_UPDATE = "VERSION_UPDATE";
export const VERSION_TEST = "VERSION_TEST";

// Roles
export const GET_ROLES = "GET_ROLES";
export const GET_ROLE = "GET_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const GET_ROLES_FOR_USER = "GET_ROLES_FOR_USER";
export const GET_ROLES_FOR_LOGGED_IN_USER = "GET_ROLES_FOR_LOGGED_IN_USER";
export const ASSIGN_USERS_TO_ROLE = "ASSIGN_USERS_TO_ROLE";
export const REMOVE_USERS_FROM_ROLE = "REMOVE_USERS_FROM_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

// Users
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const GET_USER_BY_EMAIL = "GET_USER_BY_EMAIL";
export const GET_LOGGED_IN_USER_BY_EMAIL = "GET_LOGGED_IN_USER_BY_EMAIL";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const ASSIGN_USER_TO_ROLES = "ASSIGN_USER_TO_ROLES";
export const REMOVE_USER_FROM_ROLES = "REMOVE_USER_FROM_ROLES";
export const GET_USERS_FOR_ROLE = "GET_USERS_FOR_ROLE";
export const GET_MERCHANDISER_DATA = "GET_MERCHANDISER_DATA";

// Content
export const GET_ALL_CONTENT = "GET_ALL_CONTENT";
export const GET_CONTENT_BY_ID = "GET_CONTENT_BY_ID";
export const MASS_UPLOAD_IMAGES = "MASS_UPLOAD_IMAGES";
export const CREATE_NEW_CONTENT = "CREATE_NEW_CONTENT";
export const MASS_UPLOAD_CONTENT = "MASS_UPLOAD_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const GET_CONTENT_LOOKUP_DATA = "GET_CONTENT_LOOKUP_DATA";
export const CREATE_TARGETED_CONTENT = "CREATE_TARGETED_CONTENT";
export const GET_ALL_TARGETED_CONTENT = "GET_ALL_TARGETED_CONTENT";
export const GET_ALL_TARGETED_CONTENT_BY_TYPE =
  "GET_ALL_TARGETED_CONTENT_BY_TYPE";
export const GET_ALL_TARGETED_CONTENT_FOR_POS =
  "GET_ALL_TARGETED_CONTENT_FOR_POS";
export const GET_TARGETED_CONTENT_BY_ID = "GET_TARGETED_CONTENT_BY_ID";
export const RESET_CURRENT_TARGETED_CONTENT = "RESET_CURRENT_TARGETED_CONTENT";
export const UPDATE_TARGETED_CONTENT = "UPDATE_TARGETED_CONTENT";
export const GET_3D_POS_ITEM_BY_ID = "GET_3D_POS_ITEM_BY_ID";
export const SET_CURRENT_POS_ITEM = "SET_CURRENT_POS_ITEM";
export const GET_SIZE_METADATA = "GET_SIZE_METADATA";

// Resource Images
export const CLEAR_RESOURCE_IMAGES = "CLEAR_RESOURCE_IMAGES";
export const GET_RESOURCE_IMAGES = "GET_RESOURCE_IMAGES";
export const GET_RESOURCE_IMAGE_URLS = "GET_RESOURCE_IMAGE_URLS";
export const SET_CURRENT_RESOURCE_IMAGE = "SET_CURRENT_RESOURCE_IMAGE";

//Targeting
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const GET_ALL_CAMPAIGNS = "GET_ALL_CAMPAIGNS";
export const GET_CAMPAIGNS_BY_TARGET_GROUP_ID =
  "GET_CAMPAIGNS_BY_TARGET_GROUP_ID";
export const GET_CURRENT_CAMPAIGN = "GET_CURRENT_CAMPAIGN";
export const RESET_CURRENT_CAMPAIGN = "RESET_CURRENT_CAMPAIGN";
export const UPDATE_CURRENT_CAMPAIGN = "UPDATE_CURRENT_CAMPAIGN";

export const GET_TARGET_GROUPS = "GET_TARGET_GROUPS";
export const GET_TARGET_GROUP = "GET_TARGET_GROUP";
export const GET_TARGET_RULES = "GET_TARGET_RULES";
export const GET_TARGET_GROUPS_BY_IDS = "GET_TARGET_GROUPS_BY_IDS";
export const CREATE_TARGET_GROUP = "CREATE_TARGET_GROUPS";
export const UPDATE_TARGET_GROUP = "UPDATE_TARGET_GROUP";
export const UPDATE_CURRENT_TARGET_GROUP = "UPDATE_CURRENT_TARGET_GROUP";
export const RESET_CURRENT_TARGET_GROUP = "RESET_CURRENT_TARGET_GROUP";
export const DELETE_TARGET_GROUP = "DELETE_TARGET_GROUP";

//Activities
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const CREATE_ACTIVITY_GROUP = "CREATE_ACTIVITY_GROUP";
export const CREATE_CHOICE = "CREATE_CHOICE";
export const CREATE_REWARD = "CREATE_REWARD";
export const CREATE_TARGET = "CREATE_TARGET";
export const GET_ALL_ACTIVITY_GROUPS = "GET_ALL_ACTIVITY_GROUPS";
export const GET_ACTIVITY_BY_ID = "GET_ACTIVITY_BY_ID";
export const GET_ACTIVITY_GROUP_BY_ID = "GET_ACTIVITY_GROUP_BY_ID";
export const GET_CHOICE_BY_ID = "GET_CHOICE_BY_ID";
export const GET_REWARD_BY_ID = "GET_REWARD_BY_ID";
export const GET_TARGET_BY_ID = "GET_TARGET_BY_ID";
export const GET_DROPDOWN_LOOKUPS = "GET_DROPDOWN_LOOKUPS";
export const PPP_MASS_UPLOAD_SUCCESS = "PPP_MASS_UPLOAD_SUCCESS";
export const PPP_MASS_UPLOAD_FAILURE = "PPP_MASS_UPLOAD_FAILURE";

//SFE Activation
export const GET_ALL_SFE_ACTIVATIONS = "GET_ALL_SFE_ACTIVATIONS";
export const GET_SFE_ACTIVATION_CALCULATION_BY_ID =
  "GET_SFE_ACTIVATION_CALCULATION_BY_ID";
export const CREATE_SFE_ACTIVATION_CALCULATION =
  "CREATE_SFE_ACTIVATION_CALCULATION";

// SAM-M
// Calls
export const GET_ALL_CALL_TYPES = "GET_ALL_CALL_TYPES";
export const UPSERT_CALL_TYPE = "UPSERT_CALL_TYPE";
export const GET_CALL_TYPE = "GET_CALL_TYPE";

// Regions
export const GET_ALL_REGIONS = "GET_ALL_REGIONS";
export const GET_SUBREGION = "GET_SUBREGION";
export const CREATE_SUBREGION = "CREATE_SUBREGION";
export const UPDATE_SUBREGION = "UPDATE_SUBREGION";
export const GET_LOOKUPS = "GET_LOOKUPS"

// Customers
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";

// Service Policies
export const GET_SERVICE_POLICY = "GET_SERVICE_POLICY";
export const CREATE_SERVICE_POLICY = "CREATE_SERVICE_POLICY";
export const UPDATE_SERVICE_POLICY = "UPDATE_SERVICE_POLICY";
export const DOWNLOAD_SERVICE_POLICY = "DOWNLOAD_SERVICE_POLICY";

// SAM-M Migration
export const MIGRATE_REGION = "MIGRATE_REGION";
export const MIGRATE_MERCHANDISER = "MIGRATE_MERCHANDISER";
export const MIGRATE_SERVICE_POLICY = "MIGRATE_SERVICE_POLICY";
export const PREVIEW_MIGRATE_MERCHANDISER = "PREVIEW_MIGRATE_MERCHANDISER";
export const MIGRATE_HOLIDAYS = "MIGRATE_HOLIDAYS";
// ROSTERS
export const GET_ROSTERS_BY_MERCHANDISER = "GET_ROSTERS_BY_MERCHANDISER";

// GLOBALS
export const GET_GLOBALS = "GET_GLOBALS";
export const UPDATE_GLOBALS = "UPDATE_GLOBALS";

// MERCHANDISERS
export const GET_ALL_MERCHANDISERS = "GET_ALL_MERCHANDISERS";
export const GET_MERCHANDISER_BY_ID = "GET_MERCHANDISER_BY_ID";
export const UPDATE_MERCHANDISER = "UPDATE_MERCHANDISER";
export const GET_ALL_SUBREGIONS = "GET_ALL_SUBREGIONS";
export const SET_PREFERRED_DAYS = "SET_PREFERRED_DAYS";
export const GET_PREFERRED_DAYS = "GET_PREFERRED_DAYS";

// TIMESHEETS
export const GET_TIMESHEET_LIST = "GET_TIMESHEET_LIST";
export const GET_TIMESHEET_LIST_PENDING = "GET_TIMESHEET_LIST_PENDING";
export const GET_TIMESHEET_REASONS = "GET_TIMESHEET_REASONS";
export const GET_CURRENT_TIMESHEET = "GET_CURRENT_TIMESHEET";
export const GET_TIMESHEETS = "GET_TIMESHEETS";
export const UPSERT_TIMESHEET = "UPSERT_TIMESHEET";