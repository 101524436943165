import React from "react";
import styles from './NotFoundPage.module.scss';

class NotFoundPage extends React.Component {
  render() {
    return (
      <div className={styles.notFound}>
        <h1>404 Not Found</h1>
      </div>
    );
  }
}

export default NotFoundPage;