// React
import { Grid, TextField } from "@material-ui/core";
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import CustomCard from "../../components/custom-card/CustomCard";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Button } from "@material-ui/core";

import { generateCalls } from "../../state/actions/GenerationActions";
import { Alert } from "@material-ui/lab";

const DataGenerationPage = (props) => {
    const [formData, setFormData] = useState({});


    const handleFieldChange = (field, value) => {
        let localData = { ...formData }
        localData[field] = value;
        setFormData(localData);
        console.log(localData);
    }

    const submit = (event) => {
        let localData = { ...formData };
        generateCalls(localData.StartTime, localData.EndTime, localData.CallAmount, localData.Email, (success, reason) => {
            if (success) alert("Calls Successfully Generated");
            else alert(`Failed, reason: ${reason}`);
        });
    }

    return (
        <Fragment>
            <CustomCard title="Call Generation" actionButton={[
                <Button
                    key={"CalLGenerationSubmit"}
                    variant="outlined"
                    color="secondary"
                    onClick={(event) => submit(event)}
                >
                    Generate Calls
                </Button>
            ]}>
                <Grid>
                    <Grid sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                value={formData.StartTime}
                                onChange={(value) => handleFieldChange("StartTime", value)}
                                label="Start Time"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                value={formData.EndTime}
                                onChange={(value) => handleFieldChange("EndTime", value)}
                                label="Start Time"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <TextField
                        id="standard-number"
                        label="Number"
                        type="number"
                        // value={formData.CallAmount}
                        onChange={(event) => handleFieldChange("CallAmount", event.target.value)}
                    />
                    <TextField
                        id="standard-email"
                        label="Email"
                        type="email"
                        // value={formData.Email}
                        onChange={(event) => handleFieldChange("Email", event.target.value)}
                    />
                </Grid>
            </CustomCard>
        </Fragment>
    )
}
const hoc = withRouter(DataGenerationPage);

// EXPORT COMPONENT
export { hoc as DataGenerationPage };