import {
    GET_GLOBALS,
    UPDATE_GLOBALS
} from "../constants/action-types";


// INITIALIZE STATE
const initialState = {
    Current: {}
};

// REDUCER
export const GlobalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GLOBALS: {
            return {
                ...state,
                Current: action.payload.data
            }
        }

        default:
            return state;
    }
};
