// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Divider
} from "@material-ui/core";

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./CallTypeItemPage.module.scss";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// Actions
import {
    upsertCallType,
    getCallType,
    getAllCallTypes
} from "../../state/actions/CallActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import {PageContainer} from "../../components/page-container/PageContainer";
import EditingAlert from "../../components/editing-alert/EditingAlert";

// Models
import { CallTypeModel } from "../../models/CallTypeModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";

const CallTypeItemPage = (props) => {
    const currentCallType = useSelector(
        (state) => state.CallsReducer.Current
    );
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [formProperties, setFormProperties] = useState(CallTypeModel);
    const [CallType, setCallType] = useState({});
    const [uneditedCallType, setuneditedCallType] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingCallType, setisExistingCallType] = useState(true);
    const [CallTypeId, setCallTypeId] = useState("");
    const [dataChanged, setDataChanged] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const [getCallTypeCallMade, setGetCallTypeCallMade] = useState(false);

    //Use Effects
    useEffect(() => {
        setLoading(true);

        setisExistingCallType(
            props.type != null && props.type === FormType.VIEW
        );
    }, []);

    useEffect(() => {
        if (props.type != null && props.type === FormType.VIEW) {
            dispatch(
                getCallType(props.match.params.CallTypeId, () => {
                    setGetCallTypeCallMade(true);
                    setCallTypeId(props.match.params.CallTypeId);
                })
            );
        } else {
            dispatch(
                getCallType(undefined, () => {
                    setGetCallTypeCallMade(true);
                    setIsEditing(true);
                })
            );
        }
    }, []);

    useEffect(() => {
        if (getCallTypeCallMade) {
            setLoading(false);
            setDataChanged(!dataChanged);
        }
    }, [getCallTypeCallMade]);


    useEffect(() => {
        let localCallType = currentCallType;
        setCallType(localCallType);

    }, [currentCallType]);

    // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
    // This is so the values in the form fields updates correctly on change.
    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, CallType);

        localData[name] = value;

        setCallType(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };

    /*
       Generic validation to perform on all field types
      */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            CallType[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    /*
        Validate the form before it is submitted.
        Dates not only need to be checked for validity they also need to be compared with each other.
     */
    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }

        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";
            errorMessage = checkGenericField(key, formProperties[key].required);
            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            return true;
        }
    };

    /* Transform the activity group data into the form that is required by the backend */
    const createRequestBody = (newitem) => {
        if (newitem) {
            CallType.CreatedBy = loggedInUser.userName;
            CallType.CreatedDate = new Date();
        }
        CallType.ModifiedBy = loggedInUser.userName;
        CallType.ModifiedDate = new Date();
        let requestBody = {
            ...CallType
        };

        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            dispatch(
                upsertCallType(createRequestBody(true), (success, id) => {
                    if (success) {
                        props.history.push(`/CallTypes/`);
                    } else {
                        setIsEditing(true);
                        setSubmitEnabled(true);
                        setFormEdited(true);
                    }
                })
            );
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            dispatch(
                upsertCallType(createRequestBody(true), () => {
                    props.history.push(`/CallTypes/`);
                })
            );
        }
    };

    /*
      Functionality for when the user clicks the cancel button on the edit or create form
    */
    const handleCancelEditButtonClicked = () => {
        if (formEdited) {
            setCancelEditingAlertOpen(true);
        } else {
            setIsEditing(false);

            if (!isExistingCallType) {
                props.history.push(`/CallType`);
            }
        }
    };

    // Display CallTypes and a list of their associated service policies below.
    const renderData = () => {
        return (
            <Fragment>
                <div className={styles.contentGrid}>
                    <CustomCard title="Call Type Data" actionButton={[]}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.Id.value}
                                    name={formProperties.Id.value}
                                    label={formProperties.Id.value}
                                    placeholder="Call Type Id"
                                    value={
                                        CallType != null && Object.keys(CallType).length > 0 &&
                                            CallType.Id !== undefined
                                            ? CallType.Id
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Id.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Id.errorMessage !== ""}
                                    helperText={formProperties.Id.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.CallTypeName.value}
                                    name={formProperties.CallTypeName.value}
                                    label={formProperties.CallTypeName.label}
                                    placeholder="Name"
                                    value={
                                        CallType != null && Object.keys(CallType).length > 0 &&
                                            CallType.CallTypeName !== undefined
                                            ? CallType.CallTypeName
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.CallTypeName.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.CallTypeName.errorMessage !== ""}
                                    helperText={formProperties.CallTypeName.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsActive.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                CallType !== undefined &&
                                                    CallType.IsActive !== undefined
                                                    ? CallType.IsActive
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsActive.value,
                                                    !CallType.IsActive
                                                )
                                            }
                                            name={formProperties.IsActive.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </div>
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }
    return (
        <div>
            <HeaderBlock
                title={CallType != null && Object.keys(CallType).length > 0 ? CallType.Name : "New CallType"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setuneditedCallType(CallType);
                                        } else if (isEditing && isExistingCallType) {
                                            handleUpdateClicked();
                                        } else {
                                            handleSubmitClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingCallType
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />


            <PageContainer>{renderData()}</PageContainer>
        </div>
    );
};

const hoc = withRouter(CallTypeItemPage);

// EXPORT COMPONENT
export { hoc as CallTypeItemPage };
