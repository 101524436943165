// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styles from "./DrawerItems.module.scss";
import { ListSubheader, Collapse } from "@material-ui/core";

// Actions
import { getRolesForLoggedInUser } from "../../state/actions/RoleActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";

// Other
import { getIcon } from "../../icon/icon";
import { UserRoles } from "../../state/constants/UserRoles";

export default function DrawerItems(props) {
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const loggedInUser = useSelector(
    (state) => state.UserReducer.loggedInUser
  );

  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [items, setItems] = useState([]);
  const [getLoggedInUserCallMade, setGetLoggedInUserCallMade] = useState(false);

  useEffect(() => {
    dispatch(getRolesForLoggedInUser(() => { }));

  }, []);

  useEffect(() => {
    dispatch(getLoggedInUserByEmail(() => { }));

  }, [])


  useEffect(() => {
    if (loggedInUserRoles && loggedInUser != null) {
      generateItems();
    }
  }, [loggedInUser]);



  const handleClick = (route) => {
    props.history.push(route);
    setOpen(!open);

    props.handleClick();
  };

  const generateItems = () => {
    const {
      SAM_M_ADMIN,
      SAM_M_MANAGER,
      SAM_M_MERCH
    } = UserRoles;

    if (Object.keys(loggedInUserRoles).length > 0) {
      let items = [];
      items.push({
        icon: getIcon("home.svg", styles.icon),
        name: "Home",
        route: "/",
        type: "link",
        roles: []
      });
      items.push({
        name: "Data Management",
        type: "header",
        roles: [
          SAM_M_ADMIN
        ]
      });
      items.push({
        icon: getIcon("map.svg", styles.icon),
        name: "Regions",
        route: "/sub-region",
        type: "link",
        roles: [SAM_M_ADMIN]
      });
      items.push({
        icon: getIcon("customer.svg", styles.icon),
        name: "Merchandiser",
        route: "/merchandiser",
        type: "link",
        roles: [SAM_M_ADMIN]
      });
      items.push({
        icon: getIcon("coin.svg", styles.icon),
        name: "Customers",
        route: "/customer",
        type: "link",
        roles: [SAM_M_ADMIN]
      });
      items.push({
        name: "Mass Upload",
        type: "header",
        roles: [SAM_M_ADMIN]
      });
      // items.push({
      //   icon: getIcon("users.svg", styles.icon),
      //   name: "Merchandisers",
      //   route: "/merch-mass-upload",
      //   type: "link",
      //   roles: [SAM_M_ADMIN]
      // });
      // items.push({
      //   icon: getIcon("briefcase.svg", styles.icon),
      //   name: "Merchandiser Contracts",
      //   route: "/merch-contract-mass-upload",
      //   type: "link",
      //   roles: [SAM_M_ADMIN]
      // });
      // items.push({
      //   icon: getIcon("dashboard.svg", styles.icon),
      //   name: "Merchandiser Leave",
      //   route: "/merch-leave-mass-upload",
      //   type: "link",
      //   roles: [SAM_M_ADMIN]
      // });
      items.push({
        icon: getIcon("certificate.svg", styles.icon),
        name: "Merchandiser Card",
        route: "/merch-cards-mass-upload",
        type: "link",
        roles: [SAM_M_ADMIN]
      });
      items.push({
        icon: getIcon("drop.svg", styles.icon),
        name: "Regions",
        route: "/region-mass-upload",
        type: "link",
        roles: [SAM_M_ADMIN]
      });
      items.push({
        icon: getIcon("money-protection.svg", styles.icon),
        name: "Customers",
        route: "/customer-mass-upload",
        type: "link",
        roles: [SAM_M_ADMIN]
      });
      items.push({
        icon: getIcon("money-location.svg", styles.icon),
        name: "Customer Merch Window",
        route: "/customer-merch-window-mass-upload",
        type: "link",
        roles: [SAM_M_ADMIN]
      });
      items.push({
        icon: getIcon("book.svg", styles.icon),
        name: "Customer Service Policy",
        route: "/customer-service-policy-mass-upload",
        type: "link",
        roles: [SAM_M_ADMIN]
      });
      items.push({
        name: "Model Editing",
        type: "header",
        roles: [
          SAM_M_ADMIN
        ]
      });
      items.push({
        icon: getIcon("grow.svg", styles.icon),
        name: "Globals",
        route: `/globals/${loggedInUser.territory === "nz" ? 2 : 1}`, // I know it'll come up again but its 4:10pm.
        type: "link",
        roles: [SAM_M_ADMIN]
      });

      items.push({
        icon: getIcon("phone.svg", styles.icon),
        name: "Call Types",
        route: `/CallTypes/`,
        type: "link",
        roles: [SAM_M_ADMIN]
      });

      items.push({
        icon: getIcon("credit-cards.svg", styles.icon),
        name: "Card Types",
        route: `/CardTypes/`,
        type: "link",
        roles: [SAM_M_ADMIN]
      });

      items.push({
        icon: getIcon("sun.svg", styles.icon),
        name: "Holiday Dates",
        route: `/HolidayDates/`,
        type: "link",
        roles: [SAM_M_ADMIN]
      });

      items.push({
        icon: getIcon("exit.svg", styles.icon),
        name: "Leave Types",
        route: `/LeaveTypes/`,
        type: "link",
        roles: [SAM_M_ADMIN]
      });

      items.push({
        name: "Merchandiser Links",
        type: "header",
        roles: [
          SAM_M_MERCH
        ]
      });
      items.push({
        icon: getIcon("book.svg", styles.icon),
        name: "Rosters",
        route: `/rosters`,
        type: "link",
        roles: [SAM_M_MERCH]
      });
      items.push({
        icon: getIcon("calendar.svg", styles.icon),
        name: "Timesheets",
        route: `/timesheets/`,
        type: "link",
        roles: [SAM_M_MERCH]
      });


      setItems(
        items.filter((item) => {
          let success = item.roles.length === 0;

          for (let i = 0; i < item.roles.length; i++) {
            if (success) {
              return success;
            }
            success = loggedInUserRoles.includes(item.roles[i]);
          }

          return success;
        })
      );
    }
  };

  const isActive = (route) => {
    return props.history.location.pathname.endsWith(route);
  };

  const renderItem = (item) => {
    return (
      <ListItem
        key={item.route}
        selected={isActive(item.route)}
        onClick={() => handleClick(item.route)}
        button
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    );
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {items.map((item) => {
        let listItemMarkup = null;

        if (item.type === "header") {
          listItemMarkup = (
            <ListSubheader key={item.name}>{item.name}</ListSubheader>
          );
        } else if (item.type === "link") {
          if (item.children) {
            listItemMarkup = (
              <Fragment>
                <ListItem key={item.route} button onClick={handleClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {item.children.map((childItem) => {
                    return (
                      <List
                        key={childItem.route}
                        component="div"
                        disablePadding
                      >
                        {renderItem(childItem)}
                      </List>
                    );
                  })}
                </Collapse>
              </Fragment>
            );
          } else {
            listItemMarkup = renderItem(item);
          }
        }

        return listItemMarkup;
      })}
    </List>
  );
}
