// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams, withRouter } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import {
    Grid, Paper, Tab, Tabs,
} from "@material-ui/core";
import moment from "moment";
import {
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./CallTypeItemPage.module.scss";
import { makeStyles } from '@material-ui/core/styles';
import { updateTimesheet } from "../../state/actions/TimesheetActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import { PageContainer } from "../../components/page-container/PageContainer";
import Weekpicker from "../../components/weekpicker/Weekpicker";
import { getTimesheetCalls, getTimesheetReasons } from "../../state/actions/TimesheetActions";
import TimesheetTable from "./TimesheetTable";
import { TimesheetTabs } from "./TimesheetTabs";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});


const TimesheetItemPage = (props) => {
    const classes = useStyles();
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    const timesheetCalls = useSelector((state) => state.TimesheetReducer.CurrentTimesheetCalls);
    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [isExisting, setIsExisting] = useState(false);
    const [getCallsCallMade, setGetCallsCallMade] = useState(false);
    const [startOfWeek, setStartOfWeek] = useState("");
    const [endOfWeek, setEndOfWeek] = useState("");
    const [mappedData, setMappedData] = useState([]);
    const [dataNeedsUpdate, setDataNeedsUpdate] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    var match = new URLSearchParams(useLocation().search);

    useEffect(() => {
        var a = match.get("StartTime");
        var b = match.get("EndTime");
        dispatch(getTimesheetReasons((callback) => { }));
        if (a && b) {
            setStartOfWeek(a);
            setEndOfWeek(b);
            setIsExisting(true);
        }
    }, []);

    useEffect(() => {
        setLoading(false);
        setMappedData(timesheetCalls);
    }, [dataNeedsUpdate, getCallsCallMade])

    useEffect(() => {
        let userEmail = loggedInUser.userName.split('\\')[1];
        setLoading(true);
        if (startOfWeek !== "") {
            dispatch(
                getTimesheetCalls(userEmail, startOfWeek, endOfWeek, () => {
                    setLoading(true);
                    setGetCallsCallMade(true);
                })
            );
        } else {
            setLoading(false);
        }
    }, [startOfWeek, endOfWeek, dataNeedsUpdate])

    function handleWindowSizeChange() {
        if (window.innerWidth < window.innerHeight) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        handleWindowSizeChange();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const generateTables = () => {
        var tableList = [];
        mappedData.forEach((timesheet, index) => {
            tableList.push(<TimesheetTable data={timesheet.Entries} title={timesheet.TimesheetDay} updateData={updateData} index={index} />);
        });
        return tableList;
    }

    const generateMobileTables = () => {
        return (<TimesheetTabs updateData={updateData} data={mappedData}></TimesheetTabs>)
    }


    const updateData = (data, index) => {
        var toSubmitTimesheet = mappedData[index];
        toSubmitTimesheet.Entries = data;
        dispatch(
            updateTimesheet(toSubmitTimesheet, (success, id) => {
                if (success) {
                    console.log("Uploaded Timesheet");
                    var localData = [...mappedData]
                    localData[index] = toSubmitTimesheet;
                    setMappedData(localData);
                } else {
                    // Refresh the values
                    setMappedData([...mappedData]);
                }
            })
        );
    };

    // Display CallTypes and a list of their associated service policies below.
    const renderData = () => {
        return (
            <Fragment>
                <div className={styles.contentGrid}>
                    {true}
                    {(!isExisting && startOfWeek == "") &&
                        <CustomCard title="Timesheet week selection" actionButton={[]}>
                            <Grid container spacing={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
                                    <Weekpicker style={{ margin: '20px' }} startOfWeek={setStartOfWeek} endOfWeek={setEndOfWeek} />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </CustomCard>
                    }
                    {startOfWeek != "" && !isMobile && generateTables()}
                </div>
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }
    return (
        <div>
            {!isMobile &&
                <div>
                    <HeaderBlock
                        title={startOfWeek == "" ? `New Timesheet` : `Timesheet ${moment(startOfWeek).format("LLLL")} - ${moment(endOfWeek).format("LLLL")}`}
                    />
                    <PageContainer>
                        <Fragment>
                            <div className={styles.contentGrid}>
                                {true}
                                {(!isExisting && startOfWeek == "") &&
                                    <CustomCard title="Timesheet week selection" actionButton={[]}>
                                        <Grid container spacing={3}>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
                                                <Weekpicker style={{ margin: '20px' }} startOfWeek={setStartOfWeek} endOfWeek={setEndOfWeek} />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </CustomCard>
                                }
                                {startOfWeek != "" && !isMobile && generateTables()}
                            </div>
                        </Fragment>
                    </PageContainer>
                </div>
            }
            {startOfWeek == "" && isMobile &&
            <CustomCard title="Timesheet week selection" actionButton={[]}>
                <Grid container spacing={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="en">
                        <Weekpicker style={{ margin: '20px' }} startOfWeek={setStartOfWeek} endOfWeek={setEndOfWeek} />
                    </MuiPickersUtilsProvider>
                </Grid>
            </CustomCard>}
            {startOfWeek != "" && isMobile && <PageContainer noHeader>{generateMobileTables()}</PageContainer>}
        </div>
    );
};

const hoc = withRouter(TimesheetItemPage);

// EXPORT COMPONENT
export { hoc as TimesheetItemPage };
