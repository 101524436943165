import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MenuIcon from "@material-ui/icons/Menu";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import styles from "./ShrinkingButtonGroup.module.scss";

export default function ShrinkingButtonGroup(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  function handleToggle() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(event) {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  return (
    <Grid container>
      <Grid item xs={12} align="center">
        <ButtonGroup
          variant="outlined"
          color="secondary"
          ref={anchorRef}
          aria-label="split button"
        >
          {props.secondary !== undefined && (
            <Button
              color="secondary"
              size="small"
              aria-owns={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <MenuIcon />
            </Button>
          )}
        </ButtonGroup>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          placement="bottom-end"
          className={styles.popper}
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps}>
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {props.secondary.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        onClick={(event) => {
                          handleClose(event);
                          option.action();
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
