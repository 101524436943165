import React from "react";
import { Route, withRouter } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <Route
      {...props}
      path="*"
      render={(routeProps) => {
        let parts = routeProps.location.pathname.split("/");
        let length = parts.length;

        const place = parts[parts.length - 1];
        parts = parts.slice(1, parts.length - 1);

        if (length > 2) {
          return (
            <div className={props.styles.breadcrumb}>
              {parts.map(props.crumb)}
              {place}
            </div>
          );
        }
      }}
    />
  );
};

const hoc = withRouter(Breadcrumb);

// EXPORT COMPONENT
export { hoc as Breadcrumb };
