// Auth
import { runWithAdal } from 'react-adal';
import { authContext } from './config/azureConfig';
const DO_NOT_LOGIN = false;

runWithAdal(
  authContext,
  () => { require('./reduxApp'); },
  DO_NOT_LOGIN
);
