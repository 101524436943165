// Config Imports
import jwt from "jsonwebtoken";
import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch, getTokenAsync } from "../../config/azureConfig";

// Action imports
import {
    GET_LOGGED_IN_USER_BY_EMAIL,
    GET_MERCHANDISER_DATA
} from "../constants/action-types";

// Misc
const API_CONFIG = getApiConfig();

/*
    Get the logged in user details by logged in users upn/email.
*/
export const getLoggedInUserByEmail = (callback) => (dispatch) => {
    getTokenAsync((errorDesc, token, error) => {
        if (process.env.NODE_ENV !== "production") {
            token = process.env.REACT_APP_LOCAL_HOST_DEV_TOKEN;
        }
        const decodedToken = jwt.decode(token);
        if (decodedToken) {
            const userName = `ausdom\\${decodedToken.upn}`;
            adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:7075/api/GetUserByEmail?email=${userName}&checkAD=false`
                    : `${API_CONFIG.URL}/${API_CONFIG.CORE}/Users/GetUserByEmail?email=${userName}&checkAD=false`,
                {
                    method: "post",
                    data: JSON.stringify(token)
                }
            )
                .then((res) => {
                    dispatch({
                        type: GET_LOGGED_IN_USER_BY_EMAIL,
                        payload: res.data
                    });

                    callback(true);
                })
                .catch((error) => {
                    console.log(`Exception Occurred: ${error}`);
                    callback(false);
                });
        }
    });
};

export const getMerchandiserId = (userEmail, callback) => (dispatch) => {
    adalApiFetch(
        Axios,
        process.env.NODE_ENV !== "production"
            ? `http://localhost:9004/api/GetMerchandiserDataForWA?Email=${userEmail}`
            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/GetMerchandiserDataForWA?Email=${userEmail}`,
        {
            method: "get"
        }
    )
        .then((res) => {
            dispatch({
                type: GET_MERCHANDISER_DATA,
                payload: res.data
            });
            callback(true);
        })
        .catch((error) => {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        })
}