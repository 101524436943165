// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Divider
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./NewCustomerPage.module.scss";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// Actions
import {
    createCustomer,
    getCustomer,
    updateCustomer
} from "../../state/actions/CustomerActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";


// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import {PageContainer} from "../../components/page-container/PageContainer";

// Models
import { CustomerModel } from "../../models/CustomerModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";

const NewCustomerPage = (props) => {
    // Class Global Refs & State
    const currentCustomer = useSelector(
        (state) => state.CustomerReducer.Current
    );
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [formProperties, setFormProperties] = useState(CustomerModel);
    const [Customer, setCustomer] = useState({});
    const [uneditedCustomer, setUneditedCustomer] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingCustomer, setIsExistingCustomer] = useState(false);
    const [CustomerId, setCustomerId] = useState("");
    const [dataChanged, setDataChanged] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const [getCustomerCallMade, setGetCustomerCallMade] = useState(false);
    const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);

    //Use Effects
    useEffect(() => {
        setLoading(true);
        if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
            dispatch(
                getLoggedInUserByEmail(() => {
                    setLoggedInUserCallMade(true);
                })
            );
        } else {
            setLoggedInUserCallMade(true);
        }
        setCustomer(CustomerModel);
    }, []);


    useEffect(() => {
        if (props.type != null && props.type === FormType.VIEW) {
            dispatch(
                getCustomer(props.match.params.CustomerNumber, () => {
                    setGetCustomerCallMade(true);
                    setCustomerId(props.match.params.CustomerNumber);
                })
            );
        } else {
            dispatch(
                getCustomer(undefined, () => {
                    setGetCustomerCallMade(true);
                    setIsEditing(true);
                })
            );
        }
    }, []);

    useEffect(() => {
        if (getCustomerCallMade) {
            setLoading(false);
            setDataChanged(!dataChanged);
        }
    }, [getCustomerCallMade]);


    useEffect(() => {
        // Update the DisplayName of the call schedule as we only get the code down from the backend
        let localCustomer = currentCustomer;
        setCustomer(localCustomer);

    }, [currentCustomer]);

    // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
    // This is so the values in the form fields updates correctly on change.
    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, Customer);

        localData[name] = value;
        setCustomer(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };

    /*
       Generic validation to perform on all field types
      */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            formProperties[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    /*
        Validate the form before it is submitted.
        Dates not only need to be checked for validity they also need to be compared with each other.
     */
    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }

        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";
            errorMessage = checkGenericField(key, formProperties[key].required);
            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            return true;
        }
    };

    /* Transform the activity group data into the form that is required by the backend */
    const createRequestBody = () => {
        let requestBody = {
            ...Customer,
            CreatedBy: loggedInUser.userId,
            ModifiedBy: loggedInUser.userId
        };

        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            dispatch(
                createCustomer(createRequestBody(true), (success, id) => {
                    if (success) {
                        setIsEditing(false);
                        props.history.push(`/customer/${id}`);
                    } else {
                        setIsEditing(true);
                        setSubmitEnabled(true);
                        setFormEdited(true);
                    }
                })
            );
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            dispatch(
                updateCustomer(Customer, () => {
                    setIsEditing(false);
                })
            );
        }
    };

    /*
      Functionality for when the user clicks the cancel button on the edit or create form
    */
    const handleCancelEditButtonClicked = () => {
        if (formEdited) {
            setCancelEditingAlertOpen(true);
        } else {
            setIsEditing(false);

            if (!isExistingCustomer) {
                props.history.push(`/customer`);
            }
        }
    };

    //Display Activity group and associated list of activities
    const renderData = () => {
        return (
            <Fragment>
                <div className={styles.contentGrid}>
                    <CustomCard title="Details" actionButton={[]}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={false}
                                    id={formProperties.CustomerNumber.value}
                                    name={formProperties.CustomerNumber.value}
                                    label={formProperties.CustomerNumber.value}
                                    placeholder="Customer Number"
                                    value={
                                        Customer !== undefined && Object.keys(Customer).length > 0 &&
                                            Customer.CustomerNumber !== undefined
                                            ? Customer.CustomerNumber
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.CustomerNumber.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.CustomerNumber.errorMessage !== ""}
                                    helperText={formProperties.CustomerNumber.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={false}
                                    id={formProperties.Budget.value}
                                    name={formProperties.Budget.value}
                                    label={formProperties.Budget.value}
                                    placeholder="Budget"
                                    value={
                                        Customer !== undefined && Object.keys(Customer).length > 0 &&
                                            Customer.Budget !== undefined
                                            ? Customer.Budget
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Budget.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Budget.errorMessage !== ""}
                                    helperText={formProperties.Budget.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={false}
                                    labelPlacement="start"
                                    label={formProperties.IsActive.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                Customer !== undefined &&
                                                    Customer.IsActive !== undefined
                                                    ? Customer.IsActive
                                                    : true
                                            }
                                            onChange={(event) =>
                                                handleInputChange(
                                                    formProperties.IsActive.value,
                                                    event.target.checked
                                                )
                                            }
                                            name={formProperties.IsActive.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </div>
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <HeaderBlock
                title={"New Customer Meta Data"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setUneditedCustomer(Customer);
                                        } else if (isEditing && isExistingCustomer) {
                                            handleUpdateClicked();
                                        } else {
                                            handleSubmitClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingCustomer
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />


            <PageContainer>{renderData()}</PageContainer>
        </div>
    );
};

const hoc = withRouter(NewCustomerPage);

// EXPORT COMPONENT
export { hoc as NewCustomerPage };
