import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
    GET_ALL_CUSTOMERS,
    GET_CUSTOMER,
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
    CREATE_SERVICE_POLICY,
    UPDATE_SERVICE_POLICY,
    GET_SERVICE_POLICY,
    DOWNLOAD_SERVICE_POLICY
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

export const getAllCustomers = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9002/api/GetAllCustomers`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/GetAllCustomers`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_ALL_CUSTOMERS,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getCustomer = (CustomerNumber, callback) => {
    return async (dispatch) => {
        try {
            const response =
                CustomerNumber !== undefined
                    ? await adalApiFetch(
                        Axios,
                        process.env.NODE_ENV !== "production"
                            ? `http://localhost:9002/api/GetCustomer?CustomerNumber=${CustomerNumber}`
                            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/GetCustomer?CustomerNumber=${CustomerNumber}`,
                        {
                            method: "get"
                        }
                    )
                    : {};

            dispatch({
                type: GET_CUSTOMER,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};

export const getServicePolicy = (CustomerServicePolicyId, callback) => {
    return async (dispatch) => {
        try {
            const response =
                CustomerServicePolicyId !== undefined
                    ? await adalApiFetch(
                        Axios,
                        process.env.NODE_ENV !== "production"
                            ? `http://localhost:9002/api/GetCustomerServicePolicy?CustomerServicePolicyId=${CustomerServicePolicyId}&DownloadResult=false`
                            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/GetCustomerServicePolicy?CustomerServicePolicyId=${CustomerServicePolicyId}&DownloadResult=false`,
                        {
                            method: "get"
                        }
                    )
                    : {};
            dispatch({
                type: GET_SERVICE_POLICY,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};

export const downloadServicePolicy = (CustomerServicePolicyId, callback) => {
    return async (dispatch) => {
        try {
            const response =
                CustomerServicePolicyId !== undefined
                    ? await adalApiFetch(
                        Axios,
                        process.env.NODE_ENV !== "production"
                            ? `http://localhost:9002/api/GetCustomerServicePolicy?CustomerServicePolicyId=${CustomerServicePolicyId}&DownloadResult=true`
                            : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/GetCustomerServicePolicy?CustomerServicePolicyId=${CustomerServicePolicyId}&DownloadResult=true`,
                        {
                            method: "get"
                        }
                    )
                    : {};
            dispatch({
                type: DOWNLOAD_SERVICE_POLICY,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};

export const createCustomer = (cust, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9002/api/CreateCustomer` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/CreateCustomer`,
                {
                    method: "post",
                    data: cust,
                    headers: headers
                });

            dispatch({
                type: CREATE_CUSTOMER,
                payload: cust
            });

            callback(true, cust.CustomerNumber);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};

export const createServicePolicy = (cust, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9002/api/CreateCustomerServicePolicy` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/CreateCustomerServicePolicy`,
                {
                    method: "post",
                    data: cust,
                    headers: headers
                });

            dispatch({
                type: CREATE_SERVICE_POLICY,
                payload: cust
            });

            callback(true, cust.CustomerNumber);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};

export const updateCustomer = (cust, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9002/api/UpdateCustomer?CustomerNumber=${cust.CustomerNumber}` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/UpdateCustomer?CustomerNumber=${cust.CustomerNumber}`,
                {
                    method: "post",
                    data: cust,
                    headers: headers
                });

            dispatch({
                type: UPDATE_CUSTOMER,
                payload: cust
            });

            callback(true);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};

export const updateServicePolicy = (cust, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9002/api/UpdateCustomerServicePolicy?PolicyId=${cust.id}` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Customer/UpdateCustomerServicePolicy`,
                {
                    method: "post",
                    data: cust,
                    headers: headers
                });

            dispatch({
                type: UPDATE_SERVICE_POLICY,
                payload: cust
            });

            callback(true, cust.CustomerNumber);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};

// export const getCustomer = (callback) => { };
// export const getAllCustomers = (callback) => { };