// React
import DateFnsUtils from "@date-io/date-fns";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Box, Button, Checkbox, Chip,
    Grid, IconButton, Paper, Tab, Tabs, TextField, Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { CheckBoxOutlined, CheckOutlined, Close, DriveEtaOutlined, FreeBreakfastOutlined, IndeterminateCheckBoxOutlined, PhoneOutlined } from "@material-ui/icons";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Components
import { TabContext, TabPanel } from "@material-ui/lab";
import {
    MuiPickersUtilsProvider,
    TimePicker
} from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TimesheetReasonModal from "./TimesheetReasonModal";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '45%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        paddingLeft: "5px",
        alignContent: "right",
        alignItems: "right",
    },
    gridMargin: {
        padding: "2px",
    },
    ButtonContainer: {
        marginBottom: "4px",
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export const TimesheetTabs = (props) => {
    const classes = useStyles();
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    const [tab, setTab] = useState(0);
    const [expanded, setExpanded] = useState(null);
    const anchorRef = React.useRef(null);
    const [selectedItems, setSelectedItems] = useState(null);
    const [data, setData] = useState([]);
    const [reasonNeeded, setReasonNeeded] = useState(false);
    const [reasonIndex, setReasonIndex] = useState({ timesheetIndex: null, rowIndex: null });
    const [reasonIndexSuccesses, setReasonIndexSuccesses] = useState([]);

    useEffect(() => {
        let selectedItemsFromProps = [];
        props.data.forEach(item => {
            let timesheetValues = []
            item.Entries.forEach(item => timesheetValues.push(false));
            selectedItemsFromProps.push(timesheetValues);
        });
        setData(props.data);
        setSelectedItems(selectedItemsFromProps);
        setReasonIndexSuccesses(selectedItemsFromProps);
    }, []);

    const getValueForIndexItem = (label, timesheetIndex, entryIndex, defaultValue = false) => {
        let localData = data[timesheetIndex].Entries[entryIndex];
        if (label === "StartTime") {
            if (defaultValue) return localData.DeviceStartTime;
            return localData.ReviewerAmendment?.AmendedStartTime ?? localData.SubmitterAmendment?.AmendedStartTime ?? localData.DeviceStartTime;
        } else if (label === "Distance") {
            if (defaultValue) return localData.EstimatedDistanceMeters / 1000;
            let val = localData.ReviewerAmendment?.AmendedDistanceMeters ?? localData.SubmitterAmendment?.AmendedDistanceMeters ?? localData.EstimatedDistanceMeters;
            return val / 1000;
        } else if (label === "Duration") {
            if (localData.EstimatedDurationMinutes == 0 && localData.SubmitterAmendment?.AmendedDurationMinutes == null) { return moment(localData.DeviceEndTime).diff(moment(localData.DeviceStartTime), 'minutes') }
            if (defaultValue) return localData.EstimatedDurationMinutes;
            var abc = localData.ReviewerAmendment?.AmendedDurationMinutes ?? localData.SubmitterAmendment?.AmendedDurationMinutes ?? localData.EstimatedDurationMinutes;
            return abc;
        }
        else if (label === "EndTime") {
            if (defaultValue) return localData.DeviceEndTime;
            return localData.ReviewerAmendment?.AmendedEndTime ?? localData.SubmitterAmendment?.AmendedEndTime ?? localData.DeviceEndTime;
        }
        return defaultValue;
    }

    const isRowUneditable = (timesheetIndex, entryIndex) => {
        let state = data[timesheetIndex].Entries[entryIndex].LatestState;
        return !(state == "Pending" || state == "Edited");
    }

    const updateSubmitterValues = (newValue, label, timesheetIndex, entryIndex) => {
        var localData = [...data];
        var entry = localData[timesheetIndex].Entries[entryIndex];
        if (entry.SubmitterAmendment == null) {
            entry.SubmitterAmendment = {
                UserId: "",
                UserDisplayName: loggedInUser.userName.split('\\')[1],
                ReasonId: "",
                ReasonMessage: "",
                UpdateDate: new Date(),
                AmendedStartTime: entry.DeviceStartTime,
                AmendedEndTime: entry.DeviceEndTime,
                AmendedDistanceMeters: entry.EstimatedDistanceMeters,
                Active: true
            }
        }

        if (label === "StartTime") {
            entry.SubmitterAmendment.AmendedStartTime = newValue;
            var dur = moment.duration(moment(entry.SubmitterAmendment.AmendedEndTime).diff(moment(newValue)));
            entry.SubmitterAmendment.AmendedDurationMinutes = dur.asMinutes();
            if (dur.asMinutes() >= entry.EstimatedDurationMinutes) {
                if (!reasonIndexSuccesses[timesheetIndex][entryIndex]) {
                    setReasonNeeded(true);
                    setReasonIndex({ timesheetIndex: timesheetIndex, rowIndex: entryIndex });
                } else {
                    // This shouldn't trigger at all.
                    entry[label] = newValue;
                }
            }
        } else if (label === "EndTime") {
            entry.SubmitterAmendment.AmendedEndTime = newValue;
            var dur = moment.duration(moment(newValue).diff(moment(entry.SubmitterAmendment.AmendedStartTime)));
            entry.SubmitterAmendment.AmendedDurationMinutes = dur.asMinutes();
            if (dur.asMinutes() >= entry.EstimatedDurationMinutes) {
                if (!reasonIndexSuccesses[timesheetIndex][entryIndex]) {
                    setReasonNeeded(true);
                    setReasonIndex({ timesheetIndex: timesheetIndex, rowIndex: entryIndex });
                } else {
                    // This shouldn't trigger at all.
                    entry[label] = newValue;
                }
            }
            } else if (label === "Duration") {
                debugger;
                entry.SubmitterAmendment.AmendedDurationMinutes = newValue;
                entry.SubmitterAmendment.AmendedEndTime = moment(entry.SubmitterAmendment.AmendedStartTime).add(newValue, 'minutes').format();
                if (newValue >= entry.EstimatedDurationMinutes) {
                    if (!reasonIndexSuccesses[timesheetIndex][entryIndex]) {
                        setReasonNeeded(true);
                        setReasonIndex({ timesheetIndex: timesheetIndex, rowIndex: entryIndex });
                    } else {
                        // This shouldn't trigger at all.
                        entry[label] = newValue;
                    }
                }
        } else if (label === "Distance") {
            entry.SubmitterAmendment.AmendedDistanceMeters = newValue * 1000;
            if (newValue >= entry.EstimatedDistanceMeters) {
                if (!reasonIndexSuccesses[timesheetIndex][entryIndex]) {
                    let local = { ...reasonIndexSuccesses }
                    local[timesheetIndex][entryIndex] = true;
                    setReasonIndexSuccesses(local)
                    setReasonNeeded(true);
                    setReasonIndex({ timesheetIndex: timesheetIndex, rowIndex: entryIndex });
                }
            } else {
                // This shouldn't trigger at all.
                entry[label] = newValue;
            }
        }
        entry.LatestState = "Edited";
        localData[timesheetIndex].Entries[entryIndex] = entry;

        setData([...localData]);
    }

    const getIconForType = (type) => {
        if (type === "Call") {
            return (
                <PhoneOutlined />
            )
        } else if (type === "Travel") {
            return (
                <DriveEtaOutlined />
            )
        } else if (type === "Break") {
            return (
                <FreeBreakfastOutlined />
            )
        }
    }

    const selectAllItems = (tabIndex) => {
        let localItems = [...selectedItems];
        if (allSelectedForTab(tabIndex)) {
            localItems[tabIndex] = localItems[tabIndex].map(item => false);
        } else {
            localItems[tabIndex] = localItems[tabIndex].map(item => true);
        }
        setSelectedItems([...localItems]);
    }

    const submitData = (tabIndex) => {
        let localItems = [...data];
        let entries = data[tabIndex].Entries;
        selectedItems[tabIndex].forEach((item, index) => {
            if (item && entries[index].LatestState !== "Incomplete") entries[index].LatestState = "Submitted";
        });
        localItems[tabIndex].Entries = entries;
        setData([...localItems]);
        props.updateData(entries, tabIndex);
    }

    const getCheckboxSelected = (index, entryIndex) => {
        return selectedItems[index][entryIndex];
    }

    const submitTimesheetReason = (reasonId, reasonMessage) => {
        var localData = [...data];
        localData[reasonIndex.timesheetIndex].Entries[reasonIndex.rowIndex].SubmitterAmendment.ReasonId = reasonId;
        localData[reasonIndex.timesheetIndex].Entries[reasonIndex.rowIndex].SubmitterAmendment.ReasonMessage = reasonMessage;
        setReasonNeeded(false);
        setReasonIndex({ timesheetIndex: null, rowIndex: null });
        setData(localData);
    }

    const setIncompleteClicked = (tabIndex) => {
        let localItems = [...data];
        let entries = data[tabIndex].Entries;
        selectedItems[tabIndex].forEach((item, index) => {
            if (item && entries[index].LatestState !== "Submitted") entries[index].LatestState = "Incomplete";
        });
        localItems[tabIndex].Entries = entries;
        setData([...localItems]);
    }

    const getColorByApprovalValue = (value) => {
        switch (value) {
            case 'Incomplete':
            case 'Submitted':
                return 'primary'
            case 'Edited':
            case 'Approved':
                return 'secondary'
            case 'Rejected':
            case 'Pending':
            default:
                return 'default'
        }
    }

    const allSelectedForTab = (tabIndex) => {
        let allSelected = true;
        selectedItems[tabIndex].forEach((item) => { if (!item) allSelected = false });
        return allSelected;
    }

    const generateTabData = (tab) => {
        if (selectedItems != null) {
            let tabList = data.map((timesheet, index) => {
                return (
                    <TabPanel value={index} index={index}>
                        <Paper className={classes.ButtonContainer}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <IconButton style={{ paddingLeft: "25px" }} onClick={(_) => selectAllItems(index)}>
                                        {allSelectedForTab(index) ? <IndeterminateCheckBoxOutlined /> : <CheckBoxOutlined />}
                                    </IconButton>
                                </Grid>
                                <Grid item container justify="flex-end" xs={10}>
                                    <Button
                                        onClick={(_) => setIncompleteClicked(index)}
                                        size="small"
                                        className={classes.button}
                                        startIcon={<Close />}
                                    >Incomplete</Button>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<CheckOutlined />}
                                        onClick={(_) => submitData(index)}
                                    >Submit</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                        {timesheet.Entries.map((item, entryIndex) => {
                            let disabled = isRowUneditable(index, entryIndex);
                            let isTravel = item.EntryType == "Travel";
                            return (
                                <Accordion
                                    className={classes.accordionStyle}
                                    expanded={expanded === entryIndex}
                                    onChange={(_, status) => status ? setExpanded(entryIndex) : setExpanded(null)}
                                    TransitionProps={{ unmountOnExit: true }}
                                    disabled={disabled}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Checkbox
                                            checked={disabled ? false : getCheckboxSelected(index, entryIndex)}
                                            value={getCheckboxSelected(index, entryIndex)}
                                            onClick={(event) => {
                                                let localSelectedItems = selectedItems;
                                                localSelectedItems[index][entryIndex] = !localSelectedItems[index][entryIndex];
                                                setSelectedItems([...localSelectedItems]);
                                                event.stopPropagation();
                                            }}
                                            disabled={disabled}
                                            onFocus={(event) => event.stopPropagation()}
                                        />
                                        <Typography className={classes.heading}>{item.CustomerName}:<br></br>{getIconForType(item.EntryType)}</Typography>
                                        <Typography className={classes.secondaryHeading}>
                                            {moment.duration(getValueForIndexItem("Duration", index, entryIndex), 'm').toISOString().slice(2)}
                                            <br />
                                            {isTravel && `${parseFloat(getValueForIndexItem("Distance", index, entryIndex)).toFixed(2)}KM`}
                                            <Chip color={getColorByApprovalValue(item.LatestState)} label={item.LatestState} />
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid justify="space-between" container>
                                            <Grid item sm={12} xs={12}>
                                                <Grid justify="space-between" alignItems="flex-end" container>
                                                    <Grid item sm={6} xs={6} className={classes.gridMargin}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <TimePicker
                                                                label={"Start Time"}
                                                                value={getValueForIndexItem("StartTime", index, entryIndex)}
                                                                disabled={disabled}
                                                                onChange={(newData) => updateSubmitterValues(newData, "StartTime", index, entryIndex)}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Grid>
                                                    <Grid item sm={6} xs={6} className={classes.gridMargin}>
                                                        <Typography>Estimated: </Typography><Typography variant="body2">{moment(getValueForIndexItem("StartTime", index, entryIndex, true)).format("hh:mm a")}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {isTravel && <Grid item sm={12} xs={12}>
                                                <Grid justify="space-between" alignItems="flex-end" container>
                                                    <Grid item sm={6} xs={6} className={classes.gridMargin}>
                                                        <TextField
                                                            label={"Distance (KM)"}
                                                            defaultValue={getValueForIndexItem("Distance", index, entryIndex)}
                                                            type='number'
                                                            onMouseOut={(event, _) => { updateSubmitterValues(event.target.value, "Distance", index, entryIndex) }}
                                                            onBlur={(event, _) => { updateSubmitterValues(event.target.value, "Distance", index, entryIndex) }}
                                                            disabled={disabled}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={6} className={classes.gridMargin}>
                                                        <Typography>Estimated: </Typography><Typography variant="body2">{getValueForIndexItem("Distance", index, entryIndex, true)}KM</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}
                                            {!isTravel &&
                                                <Grid item sm={12} xs={12}>
                                                    <Grid justify="space-between" alignItems="flex-end" container>
                                                        <Grid item sm={6} xs={6} className={classes.gridMargin}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <TimePicker
                                                                    label={"End Time"}
                                                                    value={getValueForIndexItem("EndTime", index, entryIndex)}
                                                                    disabled={disabled}
                                                                    onChange={(newData) => updateSubmitterValues(newData, "EndTime", index, entryIndex)}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </Grid>
                                                        <Grid item sm={6} xs={6} className={classes.gridMargin}>
                                                            <Typography>Estimated: </Typography><Typography variant="body2">{moment(getValueForIndexItem("EndTime", index, entryIndex, true)).format("hh:mm a")}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid item sm={12} xs={12}>
                                                <Grid justify="space-between" alignItems="flex-end" container>
                                                    <Grid item sm={6} xs={6} className={classes.gridMargin}>
                                                        <TextField
                                                            label={"Time Taken (Minutes)"}
                                                            defaultValue={getValueForIndexItem("Duration", index, entryIndex)}
                                                            disabled={!isTravel}
                                                            type='number'
                                                            onBlur={(event, newData) => { updateSubmitterValues(event.target.value, "Duration", index, entryIndex) }}
                                                            onMouseOut={(event, newData) => { updateSubmitterValues(event.target.value, "Duration", index, entryIndex) }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} xs={6} className={classes.gridMargin}>
                                                        <Typography>Estimated: </Typography><Typography variant="body2">{getValueForIndexItem("Duration", index, entryIndex, true)}m</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm={12} xs={12}>
                                                {item.SubmitterAmendment && item.SubmitterAmendment.ReasonMessage && <Typography style={{ margin: "10px" }} variant="body2">Reason for going over allocated time: {item.SubmitterAmendment.ReasonMessage}</Typography>}
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                        }
                    </TabPanel >
                )
            });
            return tabList;
        } else {
            return <Box></Box>
        }
    }

    const generateTabs = () => {
        let tabList = data.map((timesheet, index) => {
            let formattedString = moment(timesheet.StartDateTime).format("ddd Do MMM YY");
            return (
                <Tab label={formattedString}>
                    {generateTabData(timesheet.Entries, index, props.updateData)}
                </Tab>
            )
        });
        return tabList;
    }

    return (
        <Paper className={classes.root}>
            <TimesheetReasonModal
                open={reasonNeeded}
                submitTimesheetReason={submitTimesheetReason}>
            </TimesheetReasonModal>
            <TabContext value={tab}>
                <Tabs
                    value={tab}
                    onChange={(_, value) => setTab(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {generateTabs()}
                </Tabs>
                {generateTabData(tab)}
            </TabContext>
        </Paper>
    )
}