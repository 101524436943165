import {
    GET_ALL_MERCHANDISERS,
    GET_MERCHANDISER_BY_ID,
    UPDATE_MERCHANDISER,
    GET_ALL_SUBREGIONS,
    GET_PREFERRED_DAYS
} from "../constants/action-types";


// INITIALIZE STATE
const initialState = {
    Merchandisers: [],
    Current: {},
    SubRegions: [],
    PreferredDays: {},
};

// REDUCER
export const MerchandiserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_MERCHANDISERS: {
            return {
                ...state,
                Merchandisers: action.payload.data
            }
        }

        case GET_MERCHANDISER_BY_ID: {
            return {
                ...state,
                Current: action.payload.data
            }
        }

        case UPDATE_MERCHANDISER: {
            return {
                ...state,
                Current: action.payload.data
            }
        }

        case GET_ALL_SUBREGIONS: {
            return {
                ...state,
                SubRegions: action.payload.data
            }
        }
        case GET_PREFERRED_DAYS: {
            return {
                ...state,
                PreferredDays: action.payload.data
            }
        }

        default:
            return state;
    }
};
