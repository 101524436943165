import React from "react";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      searchValue: "",

      searchedData: [],
      originalData: []
    };
  }

  componentDidMount = () => {
    this.setState({
      searchValue: "",
      searchedData: this.props.data,
      originalData: this.props.data
    });
  };

  componentDidUpdate = () => {
    if (
      JSON.stringify(this.props.data) !==
      JSON.stringify(this.state.originalData)
    ) {
      this.setState({
        searchValue: "",
        searchedData: this.props.data,
        originalData: this.props.data
      });
    }
  };

  deepValue(obj, path) {
    for (
      var i = 0, loopPath = path.split("."), len = path.length;
      i < len;
      i++
    ) {
      if (loopPath[i] && Array.isArray(obj[loopPath[i]])) {
        obj = obj[loopPath[i]][loopPath[i + 1]];
        i++;
      } else {
        if (loopPath[i]) {
          obj = obj[loopPath[i]];
        }
      }
    }
    return obj;
  }

  search(searchText) {
    const text = searchText.toLowerCase();

    if (text === "") {
      this.setState({ searchedData: this.state.originalData });
    }

    const foundData = this.state.originalData.filter((data) => {
      let foundObjectViaTerm = false;

      // For each search term, try find matching data. If a term matches any of the fields, hold onto it.
      this.props.searchField.forEach(term => {
        foundObjectViaTerm = foundObjectViaTerm != true ? this.deepValue(data, term)
          .toLowerCase()
          .includes(text) : true;
      })

      return foundObjectViaTerm;
    });

    this.setState({ searchedData: foundData, searchValue: searchText });

    this.props.returnSearchMatches(foundData);
  }

  render() {
    return (
      <div>
        <TextField
          fullWidth
          id="outlined-name"
          label={this.props.searchTitle}
          value={this.state.searchValue}
          onChange={(event) => this.search(event.target.value)}
          variant="outlined"
          margin="dense"
          InputProps={
            this.state.searchValue !== ""
              ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.search("")}>
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
              : null
          }
        />
      </div>
    );
  }
}

export default Search;
