import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styles from "./Tabs.module.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    zIndex: 1,
    position: "absolute"
  },
  appBar: {
    height: "48px"
  }
}));

export default function ScrollableTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.value || 0);

  function handleChange(event, newValue) {
    setValue(newValue);

    if (props.handleTabChange) {
      props.handleTabChange(newValue);
    }
  }

  return (
    <Box style={{ position: "relative", width: "100%", height: "100%" }}>
      <AppBar className={classes.root} position="static" color="default">
        <Tabs
          value={props.value != null ? props.value : value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {props.tabs.map((tab) => {
            return <Tab key={tab.key} label={tab.name} />;
          })}
        </Tabs>
      </AppBar>

      {props.tabs.map((tab, index) => {
        const indexValue = props.value != null ? props.value : value;
        if (index === indexValue) {
          return (
            <div key={tab.key} className={styles.content}>
              {tab.content}
            </div>
          );
        }
      })}
    </Box>
  );
}
