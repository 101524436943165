import React from "react";
import clsx from "clsx";
import { amber, green, blue } from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import styles from './Snackbar.module.scss';

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: blue[500]
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, showLoading, ...other } = props;

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          { props.showLoading ?
            <CircularProgress size={20} color='inherit' className={styles.progress} /> :
            <div/>
          }
          { props.message }
        </span>
      }
      {...other}
    />
  );
}


export default function CustomizedSnackbars(props) {

  return (
    <Snackbar
    anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
    }}
    open={props.open}
    autoHideDuration={props.autoHideDuration != null ? props.autoHideDuration : 6000}
    onClose={props.handleClose}
    >
    <MySnackbarContentWrapper
        variant={props.variant}
        showLoading={props.showLoading}
        message={props.message}
    />
    </Snackbar>
  );
}
