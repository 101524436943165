// React
import React from "react";

// UI and Styling
import {
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500]
  },
  modal: {
    height: "70vh"
  },
  buttons: {
    padding: theme.spacing(2)
  }
}));

const Modal = ({
  actions,
  children,
  fixedHeight,
  fullWidth,
  handleClose,
  open,
  title
}) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={fullWidth}
      open={open}
      aria-labelledby="scroll-dialog-title"
      onClose={handleClose}
    >
      <MuiDialogTitle
        disableTypography
        className={classes.root}
        id="scroll-dialog-title"
      >
        <Typography variant="h6">{title}</Typography>
      </MuiDialogTitle>
      <DialogContent classes={{ root: fixedHeight ? classes.modal : null }}>
        {children}
      </DialogContent>
      <DialogActions className={classes.buttons}>{actions}</DialogActions>
    </Dialog>
  );
};

export default Modal;
