import {
    GET_ALL_CUSTOMERS,
    GET_CUSTOMER,
    CREATE_CUSTOMER,
    GET_SERVICE_POLICY,
    CREATE_SERVICE_POLICY
} from "../constants/action-types";


// INITIALIZE STATE
const initialState = {
    Customers: [],
    Current: {},
    Policy: {}
};

// REDUCER
export const CustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CUSTOMERS: {
            return {
                ...state,
                Customers: action.payload.data
            }
        }

        case GET_CUSTOMER: {
            return {
                ...state,
                Current: action.payload.data
            }
        }

        case CREATE_CUSTOMER: {
            return {
                ...state,
                Current: action.payload.data
            }
        }

        case GET_SERVICE_POLICY: {
            return{
                ...state,
                Policy: action.payload.data
            }
        }
        

        case CREATE_SERVICE_POLICY: {
            return{
                ...state,
                Policy: action.payload.data
            }
        }

        default:
            return state;
    }
};
