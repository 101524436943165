import {
    GET_TIMESHEETS,
    GET_TIMESHEET_LIST,
    GET_TIMESHEET_LIST_PENDING,
    GET_CURRENT_TIMESHEET,
    UPSERT_TIMESHEET,
    GET_TIMESHEET_REASONS
} from "../constants/action-types";


const initialState = {
    CurrentTimesheet: {},
    CurrentTimesheetCalls: [],
    TimesheetList: [],
    TimesheetReasons: [{ Key: "", Value: "" }]
};

export const TimesheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIMESHEET_LIST:
            return {
                ...state,
                TimesheetList: action.payload.data
            }
        case GET_TIMESHEET_LIST_PENDING:
            return {
                ...state,
                TimesheetList: []
            }
        case GET_TIMESHEET_REASONS:
            return {
                ...state,
                TimesheetReasons: action.payload.data
            }
        case GET_TIMESHEETS:
            return {
                ...state,
                CurrentTimesheetCalls: action.payload.data
            }
        case GET_CURRENT_TIMESHEET:
            return {
                ...state,
                CurrentTimesheet: action.payload.data
            }
        default:
            return state;
    };
}