// React
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Grid,
} from "@material-ui/core";
import styles from "./CallTypeListPage.module.scss";

// Actions
import { getAllCallTypes } from "../../state/actions/CallActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import {PageContainer} from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";


// Other
import { getIcon } from "../../icon/icon";

// Start of main component body
const CallTypeListPage = (props) => {
    const allCallTypes = useSelector((state) => state.CallsReducer.CallTypes);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [currentCallType, setcurrentCallType] = useState("");

    useEffect(() => {
        setLoading(true);
        dispatch(getAllCallTypes(() => { }));
    }, []);

    useEffect(() => {
        setupData();
    }, [currentCallType]);

    useEffect(() => {
        if (allCallTypes !== undefined) {
            const data = createData(allCallTypes);
            setOriginalData(data);
            setSearchedData(data);
            generateCurrentCallType(allCallTypes);
            setupData();
            if (loading) {
                setLoading(false);
            }
        }
    }, [allCallTypes]);

    const setupData = () => {
        let filteredCallTypes = allCallTypes;
        const data = createData(filteredCallTypes);

        setOriginalData(data);
        setSearchedData(data);
    };

    const generateCurrentCallType = (allCallTypes) => {
        var c = 0;
        var data = [];
        allCallTypes.forEach(callType => {
            data.push({ index: c, id: callType.Id });
            c += 1;
        });

        // This is because generateThingy is called in the wrong useEffect,
        // But I have no idea how to fix it. So let's just be very strict.
        if (data != null && data.length > 0) { setcurrentCallType(data[0].id) };
    }

    const createData = (allCallTypes) => {
        const callTypeKeys = Object.keys(allCallTypes);

        return callTypeKeys.map((callTypeKey) => {
            const callType = allCallTypes[callTypeKey];
            return {
                number: callType.Id,
                name: callType.CallTypeName
            };
        });
    };

    // The headers of the table
    const createHeaders = () => {
        return [
            {
                id: "number",
                clickable: true,
                action: { path: "/CallTypes/{id}", identifier: "number" },
                align: "left",
                label: "Call Type Id"
            },
            {
                id: "name",
                clickable: true,
                action: { path: "/CallTypes/{id}", identifier: "number" },
                align: "left",
                label: "Call Type Name"
            }
        ];
    };

    // Create the cells for the table and configure them.
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};

            cell.id = header.id;

            if (header.hasOwnProperty("action")) {
                cell.handleClick = (event, row) => {
                    let path = header.action.path.replace(
                        "{id}",
                        row[header.action.identifier]
                    );
                    props.history.push(path);
                };
            }

            cell.align = header.align;
            cell.clickable = header.clickable;

            cells.push(cell);
        });

        return cells;
    };

    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("licencse.svg")}
                    text="No Customers found."
                />
            ) : (
                <SimpleTable
                    handleSelection={null}
                    isSelectable={false}
                    onClick={() => { }}
                    dataId={"activityGroupId"}
                    cells={cells}
                    headers={headers}
                    rows={searchedData}
                />
            );

        return data;
    };

    return (
        <div>
            <HeaderBlock
                title={"Call Type List"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <Search
                                searchField={["Id", "name"]}
                                returnSearchMatches={(data) => {
                                    setSearchedData(data);
                                }}
                                data={originalData}
                                searchTitle="Search Call Types"
                            />
                        </Grid>
                        <ExportExcel csvData={searchedData} fileName="callTypes" />
                        <Grid item>
                            <ButtonGroup classes={{ root: styles.buttonGroup }}>
                                <Button
                                    onClick={() => {
                                        props.history.push("/CallTypes/new");
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                >
                                    New Call Type
                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />
            <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
        </div>
    );
};

const hoc = withRouter(CallTypeListPage);

// EXPORT COMPONENT
export { hoc as CallTypeListPage };