export const SubRegionModel = {
  Site: {
    type: "int",
    required: true,
    errorMessage: "",
    value: "Site",
    label: "Territory"
  },
  SubRegionId: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "SubRegionId",
    label: "Sub Region Id"
  },
  SubRegionName: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "SubRegionName",
    label: "Sub Region Name"
  },
  RegionId: {
    type: "string",
    required: true,
    errorMessage: "",
    value: "RegionId",
    label: "Region Id"
  },
  LocalHolidayId: {
    type: "string",
    required: false,
    errorMessage: "",
    value: "LocalHolidayId",
    label: "Local Holiday Id"
  },
  AnniversaryName: {
    type: "string",
    required: false,
    errorMessage: "",
    value: "AnniversaryName",
    label: "Holiday Name"
  },
  LocalHolidayDate: {
    type: "date",
    required: false,
    errorMessage: "",
    value: "LocalHolidayDate",
    label: "Holiday Date"
  },
  IsActive: {
    type: "checkbox",
    required: false,
    errorMessage: "",
    value: "IsActive",
    label: "Active"
  }
};
