// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Divider,
    Snackbar
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./MerchandiserPreferredDaysItemPage.module.scss";
import CustomizedSnackbars from "../../components/snackbar/Snackbar";

import {
    getPreferredDays,
    createPreferredDays,
    updatePreferredDays
} from "../../state/actions/MerchandiserActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import {PageContainer} from "../../components/page-container/PageContainer";

// Models
import { PreferredDaysModel, PreferredDaysFormModel } from "../../models/PreferredDaysModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";
import { getApiConfig } from "../../config/apiConfig";

const API_CONFIG = getApiConfig();

const MerchandiserPreferredDaysItemPage = (props) => {
    const currentDays = useSelector(
        (state) => state.MerchandiserReducer.PreferredDays
    );

    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);
    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [formProperties, setFormProperties] = useState(PreferredDaysModel);
    const [PreferredDays, setPreferredDays] = useState({});
    const [uneditedPreferredDays, setUneditedPreferredDays] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingPreferredDays, setIsExistingPreferredDays] = useState(true);
    const [PreferredDaysId, setPreferredDaysId] = useState("");
    const [dataChanged, setDataChanged] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const [getPreferredDaysCallMade, setGetPreferredDaysCallMade] = useState(false);
    const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");


    //Use Effects
    useEffect(() => {
        setLoading(true);
        if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
            dispatch(
                getLoggedInUserByEmail(() => {
                    setLoggedInUserCallMade(true);
                })
            );
        } else {
            setLoggedInUserCallMade(true);
        }
        setPreferredDays(PreferredDaysModel);
    }, []);

    //Use Effects
    useEffect(() => {
        setLoading(true);

        setIsExistingPreferredDays(
            props.type != null && props.type === FormType.VIEW
        );
    }, []);

    useEffect(() => {
        if (props.type != null && props.type === FormType.VIEW) {
            dispatch(
                getPreferredDays(props.match.params.PreferredDaysId, () => {
                    setGetPreferredDaysCallMade(true);
                    setPreferredDaysId(props.match.params.PreferredDaysId);
                })
            );
        } else {
            dispatch(
                getPreferredDays(undefined, () => {
                    setGetPreferredDaysCallMade(true);
                    setIsEditing(true);
                })
            );
        }
    }, []);

    useEffect(() => {
        if (getPreferredDaysCallMade) {
            setLoading(false);
            setDataChanged(!dataChanged);
        }
    }, [getPreferredDaysCallMade]);


    useEffect(() => {
        // Update the DisplayName of the call schedule as we only get the code down from the backend
        let localDays = currentDays != null ? currentDays : PreferredDaysFormModel;
        setPreferredDays(localDays);

    }, [currentDays]);

    // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
    // This is so the values in the form fields updates correctly on change.
    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, PreferredDays);

        localData[name] = value;

        setPreferredDays(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };

    /*
       Generic validation to perform on all field types
      */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            PreferredDays[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    /*
        Validate the form before it is submitted.
        Dates not only need to be checked for validity they also need to be compared with each other.
     */
    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }

        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;
        PreferredDays.CustomerNumber = props.match.params["CustomerNumber"];

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";

            // Validate specifically against effectivedate.
            if (key === "effectiveDate") {
                errorMessage = checkGenericField(key, formProperties[key].required);
                console.log(PreferredDays[key].toString());
                if (errorMessage === "") {
                    if (
                        isValid(PreferredDays["effectiveDate"])
                    ) {
                        // ServicePolicy["effectiveDate"].toISOString(),
                        //     ServicePolicy[key].toISOString()
                    }
                }
            }
            errorMessage = checkGenericField(key, formProperties[key].required);
            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            setSubmitEnabled(true);
            return true;
        }
    };

    /* Transform the activity group data into the form that is required by the backend */
    const createRequestBody = () => {
        let requestBody = {
            ...PreferredDays,
            CreatedBy: loggedInUser.userId,
            ModifiedBy: loggedInUser.userId
        };
        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            dispatch(
                updatePreferredDays(createRequestBody(), props.match.params.EmployeeNumber, (success) => {
                    if (success) {
                        setIsEditing(false);
                        props.history.push(`/merchandiser/${props.match.params.EmployeeNumber}`);
                    } else {
                        setIsEditing(true);
                        setSubmitEnabled(true);
                        setFormEdited(true);
                    }
                })
            );
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            dispatch(
                updatePreferredDays(createRequestBody(), props.match.params.EmployeeNumber, (success) => {
                    setIsEditing(false);
                })
            );
        }
    };

    /*
      Functionality for when the user clicks the cancel button on the edit or create form
    */
    const handleCancelEditButtonClicked = () => {
        if (formEdited) {
            // setCancelEditingAlertOpen(true); Earmarked for removal. Leaving in case we want to revert this.
            setIsEditing(false);
        } else {
            setIsEditing(false);

            if (!isExistingPreferredDays) {
                props.history.push(`/merchandiser/${props.match.params.EmployeeNumber}`);
            }
        }
    };


    // Display possible day toggles, effective start, and active toggle.
    const renderData = () => {
        return (
            <Fragment>
                <div className={styles.contentGrid}>
                    <CustomCard title="Main Details">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <ToggleButtonGroup>
                                    <ToggleButton
                                        value={formProperties.Day1.value}
                                        disabled={!isEditing}
                                        selected={
                                            PreferredDays !== undefined &&
                                                PreferredDays.Day1 !== undefined
                                                ? PreferredDays.Day1
                                                : false
                                        }
                                        onClick={(event) =>
                                            handleInputChange(
                                                formProperties.Day1.value,
                                                !PreferredDays.Day1
                                            )
                                        }>
                                        Monday
                            </ToggleButton>
                                    <ToggleButton
                                        value={formProperties.Day2.value}
                                        disabled={!isEditing}
                                        selected={
                                            PreferredDays !== undefined &&
                                                PreferredDays.Day2 !== undefined
                                                ? PreferredDays.Day2
                                                : false
                                        }
                                        onClick={(event) =>
                                            handleInputChange(
                                                formProperties.Day2.value,
                                                !PreferredDays.Day2
                                            )
                                        }>
                                        Tuesday
                            </ToggleButton>
                                    <ToggleButton
                                        value={formProperties.Day3.value}
                                        disabled={!isEditing}
                                        selected={
                                            PreferredDays !== undefined &&
                                                PreferredDays.Day3 !== undefined
                                                ? PreferredDays.Day3
                                                : false
                                        }
                                        onClick={(event) =>
                                            handleInputChange(
                                                formProperties.Day3.value,
                                                !PreferredDays.Day3
                                            )
                                        }>
                                        Wednesday
                            </ToggleButton>
                                    <ToggleButton
                                        value={formProperties.Day4.value}
                                        disabled={!isEditing}
                                        selected={
                                            PreferredDays !== undefined &&
                                                PreferredDays.Day4 !== undefined
                                                ? PreferredDays.Day4
                                                : false
                                        }
                                        onClick={(event) =>
                                            handleInputChange(
                                                formProperties.Day4.value,
                                                !PreferredDays.Day4
                                            )
                                        }>
                                        Thursday
                            </ToggleButton>
                                    <ToggleButton
                                        value={formProperties.Day5.value}
                                        disabled={!isEditing}
                                        selected={
                                            PreferredDays !== undefined &&
                                                PreferredDays.Day5 !== undefined
                                                ? PreferredDays.Day5
                                                : false
                                        }
                                        onClick={(event) =>
                                            handleInputChange(
                                                formProperties.Day5.value,
                                                !PreferredDays.Day5
                                            )
                                        }>
                                        Friday
                            </ToggleButton>
                                    <ToggleButton
                                        value={formProperties.Day6.value}
                                        disabled={!isEditing}
                                        selected={
                                            PreferredDays !== undefined &&
                                                PreferredDays.Day6 !== undefined
                                                ? PreferredDays.Day6
                                                : false
                                        }
                                        onClick={(event) =>
                                            handleInputChange(
                                                formProperties.Day6.value,
                                                !PreferredDays.Day6
                                            )
                                        }>
                                        Saturday
                            </ToggleButton>
                                    <ToggleButton
                                        value={formProperties.Day7.value}
                                        disabled={!isEditing}
                                        color="primary"
                                        selected={
                                            PreferredDays !== undefined &&
                                                PreferredDays.Day7 !== undefined
                                                ? PreferredDays.Day7
                                                : false
                                        }
                                        onClick={(event) =>
                                            handleInputChange(
                                                formProperties.Day7.value,
                                                !PreferredDays.Day7
                                            )
                                        }>
                                        Sunday
                            </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="dense"
                                        disabled={!isEditing}
                                        disableToolbar
                                        fullWidth
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        label={formProperties.EffectiveDate.label}
                                        value={
                                            PreferredDays !== undefined &&
                                                Object.keys(PreferredDays).length > 0 &&
                                                PreferredDays.EffectiveDate !== undefined
                                                ? PreferredDays.EffectiveDate
                                                : new Date()
                                        }
                                        onChange={(date) => {
                                            handleInputChange(formProperties.EffectiveDate.value, date);
                                        }}
                                        error={formProperties.EffectiveDate.errorMessage !== ""}
                                        helperText={formProperties.EffectiveDate.errorMessage}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsActive.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                PreferredDays !== undefined &&
                                                    PreferredDays.IsActive !== undefined
                                                    ? PreferredDays.IsActive
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsActive.value,
                                                    !PreferredDays.IsActive
                                                )
                                            }
                                            name={formProperties.IsActive.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </div>
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <HeaderBlock
                title={"Preferred Merchandising Days Management"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setUneditedPreferredDays(PreferredDays);
                                        } else if (isEditing && isExistingPreferredDays) {
                                            handleUpdateClicked();
                                        } else {
                                            handleSubmitClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingPreferredDays
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />
            <CustomizedSnackbars
                handleClose={() => {
                    setSnackbarOpen(false);
                }}
                open={snackbarOpen}
                variant="info"
                message={snackbarMessage}
            />
            <PageContainer hasHelp={props.type !== FormType.VIEW}>
                {renderData()}
            </PageContainer>
        </div>
    );
};

const hoc = withRouter(MerchandiserPreferredDaysItemPage);

// EXPORT COMPONENT
export { hoc as MerchandiserPreferredDaysItemPage };
