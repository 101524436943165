export const CustomerModel = {
    CustomerNumber: {
        type: "string",
        required: true,
        errorMessage: "",
        value: "CustomerNumber",
        label: "Customer Number"
    },
    Name: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Name",
        label: "Name"
    },
    City: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "City",
        label: "City"
    },
    RegionId: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "RegionId",
        label: "Region Id"
    },
    SubRegionId: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "SubRegionId",
        label: "Sub-Region Id"
    },
    PostalCode: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "PostalCode",
        label: "Postal Code"
    },
    TelNumber: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "TelNumber",
        label: "Telephone Number"
    },
    StreetAddress: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "StreetAddress",
        label: "Street Address"
    },
    Latitude: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Latitude",
        label: "Latitude"
    },
    Longitude: {
        type: "string",
        required: false,
        errorMessage: "",
        value: "Longitude",
        label: "Longitude"
    },
    Budget: {
        type: "string",
        required: true,
        errorMessage: "",
        value: "Budget",
        label: "Budget"
    },
    IsActive: {
        type: "checkbox",
        required: true,
        errorMessage: "",
        value: "IsActive",
        label: "Is Active?"
    }
}