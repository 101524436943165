import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import styles from "./SplitView.module.scss";
import { withRouter } from "react-router-dom";
import DrawerItems from "./DrawerItems";
import { AccountPopover } from "../account-popover/AccountPopover";
import { NavLink } from "react-router-dom";
import { getIcon } from "../../icon/icon";
import { Chip } from "@material-ui/core";
import { Breadcrumb } from "../breadcrumb/Breadcrumb";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#EBEBEB"
  },
  content: {
    flexGrow: 1,
    marginTop: "50px"
  },
  divider: {
    marginLeft: "8px",
    marginRight: "8px",
    fontSize: "14px"
  }
}));
function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <div className={styles.drawerItems}>
      <DrawerItems
        handleClick={() => setMobileOpen(false)}
        history={props.history}
      />
    </div>
  );


  const Breadcrumbs = () => (
    <Breadcrumb styles={styles} crumb={crumb} location={props.location} />
  );

  const crumb = (part, partIndex, parts) => {
    const path = ["", ...parts.slice(0, partIndex + 1)].join("/");

    return (
      <Link className={styles.breadcrumbLink} key={path} to={path}>
        {part}
        <span className={styles.divider}>/</span>
      </Link>
    );

  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={styles.appBar}>
        <div className={styles.appBarLeft}>
          {getIcon("menu.svg", styles.hamburger, null, handleDrawerToggle)}
          <div
            onClick={() => props.history.push("/")}
            className={styles.appNameContainer}
          >
            {/* Uncomment once we get the logo from CCA */
            /* <img
              className={styles.logo}
              src={require("../../media/images/branding/logo.svg")}
              alt="site logo"
            /> */}
            <div className={styles.appName}>SAM M Admin</div>
            <Chip
              className={styles.environmentChip}
              variant="outlined"
              label={process.env.REACT_APP_ENVIRONMENT}
            />
          </div>
          <div className={styles.breadcrumbContainer}>
            <Breadcrumbs
              separator={<b> / </b>}
              item={NavLink}
              finalItem={"b"}
              finalProps={{
                style: { color: "red" }
              }}
            />
          </div>
        </div>
        {/* { props.isLoading && <div className={styles.loading}><CircularProgress thickness={3.0} size={20} /></div> } */}
        <div className={styles.appBarRight}>
          <AccountPopover />
        </div>
      </div>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{props.children}</main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.object
};

const hoc = withRouter(ResponsiveDrawer);

// EXPORT COMPONENT

export default hoc;
