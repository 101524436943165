// React
import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Divider
} from "@material-ui/core";

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import styles from "./CustomerItemPage.module.scss";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// Actions
import {
    createCustomer,
    getCustomer,
    updateCustomer
} from "../../state/actions/CustomerActions";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import MultiSelectDropDown from "../../components/multi-select-drop-down/MultiSelectDropDown";
import {PageContainer} from "../../components/page-container/PageContainer";
import EditingAlert from "../../components/editing-alert/EditingAlert";

// Models
import { CustomerModel } from "../../models/CustomerModel";

// Other
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";
import { FormType } from "../../state/constants/FormType";
import { compareDates, validateField } from "../../helpers/validation";

const CustomerItemPage = (props) => {
    const currentCustomer = useSelector(
        (state) => state.CustomerReducer.Current
    );

    const dispatch = useDispatch();

    //Setup State
    const [loading, setLoading] = useState(false);
    const [formProperties, setFormProperties] = useState(CustomerModel);
    const [Customer, setCustomer] = useState({});
    const [uneditedCustomer, setUneditedCustomer] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [isExistingCustomer, setIsExistingCustomer] = useState(true);
    const [CustomerId, setCustomerId] = useState("");
    const [dataChanged, setDataChanged] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [cancelEditingAlertOpen, setCancelEditingAlertOpen] = useState(false);
    const [getCustomerCallMade, setGetCustomerCallMade] = useState(false);

    //Use Effects
    useEffect(() => {
        setLoading(true);

        setIsExistingCustomer(
            props.type != null && props.type === FormType.VIEW
        );
    }, []);

    useEffect(() => {
        if (props.type != null && props.type === FormType.VIEW) {
            dispatch(
                getCustomer(props.match.params.CustomerNumber, () => {
                    setGetCustomerCallMade(true);
                    setCustomerId(props.match.params.CustomerNumber);
                })
            );
        } else {
            dispatch(
                getCustomer(undefined, () => {
                    setGetCustomerCallMade(true);
                    setIsEditing(true);
                })
            );
        }
    }, []);

    useEffect(() => {
        if (getCustomerCallMade) {
            setLoading(false);
            setDataChanged(!dataChanged);
        }
    }, [getCustomerCallMade]);


    useEffect(() => {
        let localCustomer = currentCustomer;
        setCustomer(localCustomer);

    }, [currentCustomer]);

    // Whenever a change to any of the form fields happens this method is called to update the state of the activity group
    // This is so the values in the form fields updates correctly on change.
    const handleInputChange = (name, value) => {
        let localData = Object.assign({}, Customer);

        localData[name] = value;

        setCustomer(localData);

        if (!formEdited) {
            setFormEdited(true);
        }
    };

    /*
       Generic validation to perform on all field types
      */
    const checkGenericField = (key, required) => {
        return validateField(
            required,
            Customer[key],
            formProperties[key].type,
            formProperties[key].label
        );
    };

    /*
        Validate the form before it is submitted.
        Dates not only need to be checked for validity they also need to be compared with each other.
     */
    const validateForm = () => {
        if (!submitEnabled) {
            return;
        }

        //Prevent the user from button mashing
        setSubmitEnabled(false);

        let numErrors = 0;
        let localProperties = formProperties;

        Object.keys(formProperties).forEach((key) => {
            let errorMessage = "";
            errorMessage = checkGenericField(key, formProperties[key].required);
            if (errorMessage.length > 0) {
                numErrors++;
            }

            localProperties[key].errorMessage = errorMessage;
        });

        //TODO: Find a better way to deal with form changes. This just forces a re-render after validation
        setDataChanged(!dataChanged);

        if (numErrors > 0) {
            setSubmitEnabled(true);
            return false;
        } else {
            return true;
        }
    };

    /* Transform the activity group data into the form that is required by the backend */
    const createRequestBody = () => {
        let requestBody = {
            ...Customer
        };

        return requestBody;
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleSubmitClicked = () => {
        if (validateForm()) {
            dispatch(
                createCustomer(createRequestBody(true), (success, id) => {
                    if (success) {
                        setIsEditing(false);
                        props.history.push(`/customer/${id}`);
                    } else {
                        setIsEditing(true);
                        setSubmitEnabled(true);
                        setFormEdited(true);
                    }
                })
            );
        }
    };

    // When submit is clicked, validate the data and if ok call the create action
    const handleUpdateClicked = () => {
        if (validateForm()) {
            dispatch(
                updateCustomer(Customer, () => {
                    setIsEditing(false);
                })
            );
        }
    };

    /*
      Functionality for when the user clicks the cancel button on the edit or create form
    */
    const handleCancelEditButtonClicked = () => {
        if (formEdited) {
            setCancelEditingAlertOpen(true);
        } else {
            setIsEditing(false);

            if (!isExistingCustomer) {
                props.history.push(`/customer`);
            }
        }
    };

    /*
    Generate the shape/structure of the other metadata: Service Policies.
    */
    const getServicePoliciesList = (data, fileName) => {
        const listData =
            Customer.ServicePolicy != null
                ? Customer.ServicePolicy.map((item) => {
                    return {
                        id: item.Id,
                        day1hours: item.Day1Hours !== null ? item.Day1Hours : "None",
                        day2hours: item.Day2Hours !== null ? item.Day2Hours : "None",
                        day3hours: item.Day3Hours !== null ? item.Day3Hours : "None",
                        day4hours: item.Day4Hours !== null ? item.Day4Hours : "None",
                        day5hours: item.Day5Hours !== null ? item.Day5Hours : "None",
                        day6hours: item.Day6Hours !== null ? item.Day6Hours : "None",
                        day7hours: item.Day7Hours !== null ? item.Day7Hours : "None",
                        effectiveDate: item.EffectiveDate.split('T')[0],
                        isActive: item.IsActive ? "Yes" : "No"
                    };
                })
                : [];

        const columns = [
            {
                name: "id",
                label: "Id",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const url = `/customer/${Customer.CustomerNumber}/policy/${value}?customerServicePolicyId=${Customer.id}`;
                        return <a href={url}>{value}</a>;
                    }
                }
            },
            {
                name: "day1hours",
                label: "Monday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day2hours",
                label: "Tuesday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day3hours",
                label: "Wednesday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day4hours",
                label: "Thursday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day5hours",
                label: "Friday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day6hours",
                label: "Saturday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "day7hours",
                label: "Sunday",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "effectiveDate",
                label: "Effective Date",
                options: {
                    filter: false,
                    sort: true
                }
            },
            {
                name: "isActive",
                label: "Active?",
                options: {
                    filter: false,
                    sort: true
                }
            }
        ];

        const options = {
            filter: true,
            responsive: "stacked",
            rowsPerPage: 20,
            rowsPerPageOptions: [5, 20, 50, 100, 200],
            viewColumns: false,
            filterType: "checkbox",
            selectableRows: "none",
            print: false,
            download: true,
            downloadOptions: {
                filename: { fileName },
                separator: ",",
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                }
            }
        };

        options.customToolbar = () => {
            return (
                <Fragment>
                    <Tooltip title="Add Policy">
                        <IconButton
                            onClick={() =>
                                props.history.push(
                                    `/customer/${Customer.CustomerNumber}/policy/new`
                                )
                            }
                        >
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                </Fragment>
            );
        };

        return (
            <MUIDataTable
                title="Service Policies"
                data={listData}
                columns={columns}
                options={options}
            />
        );
    }

    // Display Customers and a list of their associated service policies below.
    const renderData = () => {
        return (
            <Fragment>
                <EditingAlert
                    isEditing={isEditing}
                    title={"Information"}
                    severity={"info"}
                    body={["Not all fields of this data are editable, non-editable fields will be derived from ", <strong>SuccessFactors</strong>, " when available."]}
                />
                <div className={styles.contentGrid}>
                    <CustomCard title="Master Data" actionButton={[]}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.CustomerNumber.value}
                                    name={formProperties.CustomerNumber.value}
                                    label={formProperties.CustomerNumber.value}
                                    placeholder="Customer Number"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.CustomerNumber !== undefined
                                            ? Customer.CustomerNumber
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.CustomerNumber.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.CustomerNumber.errorMessage !== ""}
                                    helperText={formProperties.CustomerNumber.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.Name.value}
                                    name={formProperties.Name.value}
                                    label={formProperties.Name.label}
                                    placeholder="Name"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.Name !== undefined
                                            ? Customer.Name
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Name.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Name.errorMessage !== ""}
                                    helperText={formProperties.Name.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.RegionId.value}
                                    name={formProperties.RegionId.value}
                                    label={formProperties.RegionId.value}
                                    placeholder="Region"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.RegionId !== undefined
                                            ? Customer.RegionId
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.RegionId.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.RegionId.errorMessage !== ""}
                                    helperText={formProperties.RegionId.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.SubRegionId.value}
                                    name={formProperties.SubRegionId.value}
                                    label={formProperties.SubRegionId.value}
                                    placeholder="Sub Region"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.SubRegionId !== undefined
                                            ? Customer.SubRegionId
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.SubRegionId.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.SubRegionId.errorMessage !== ""}
                                    helperText={formProperties.SubRegionId.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.City.value}
                                    name={formProperties.City.value}
                                    label={formProperties.City.value}
                                    placeholder="City"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.City !== undefined
                                            ? Customer.City
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.City.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.City.errorMessage !== ""}
                                    helperText={formProperties.City.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.StreetAddress.value}
                                    name={formProperties.StreetAddress.value}
                                    label={formProperties.StreetAddress.value}
                                    placeholder="Street Address"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.StreetAddress !== undefined
                                            ? Customer.StreetAddress
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.StreetAddress.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.StreetAddress.errorMessage !== ""}
                                    helperText={formProperties.StreetAddress.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.PostalCode.value}
                                    name={formProperties.PostalCode.value}
                                    label={formProperties.PostalCode.value}
                                    placeholder="Postal Code"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.PostalCode !== undefined
                                            ? Customer.PostalCode
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.PostalCode.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.PostalCode.errorMessage !== ""}
                                    helperText={formProperties.PostalCode.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.TelNumber.value}
                                    name={formProperties.TelNumber.value}
                                    label={formProperties.TelNumber.value}
                                    placeholder="Telephone Number"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.TelNumber !== undefined
                                            ? Customer.TelNumber
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.TelNumber.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.TelNumber.errorMessage !== ""}
                                    helperText={formProperties.TelNumber.errorMessage}
                                />
                            </Grid>

                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.Latitude.value}
                                    name={formProperties.Latitude.value}
                                    label={formProperties.Latitude.value}
                                    placeholder="Latitude"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.Latitude !== undefined
                                            ? Customer.Latitude
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Latitude.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Latitude.errorMessage !== ""}
                                    helperText={formProperties.Latitude.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={true}
                                    id={formProperties.Longitude.value}
                                    name={formProperties.Longitude.value}
                                    label={formProperties.Longitude.value}
                                    placeholder="Longitude"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.Longitude !== undefined
                                            ? Customer.Longitude
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Longitude.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Longitude.errorMessage !== ""}
                                    helperText={formProperties.Longitude.errorMessage}
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </div>
                <div className={styles.contentGrid}>
                    <CustomCard title="Meta Data" actionButton={[]}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    disabled={!isEditing}
                                    id={formProperties.Budget.value}
                                    name={formProperties.Budget.value}
                                    label={formProperties.Budget.value}
                                    placeholder="Budget"
                                    value={
                                        Object.keys(Customer).length > 0 &&
                                            Customer.Budget !== undefined
                                            ? Customer.Budget
                                            : ""
                                    }
                                    onChange={(event) =>
                                        handleInputChange(
                                            formProperties.Budget.value,
                                            event.target.value
                                        )
                                    }
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={formProperties.Budget.errorMessage !== ""}
                                    helperText={formProperties.Budget.errorMessage}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    disabled={!isEditing}
                                    labelPlacement="start"
                                    label={formProperties.IsActive.label}
                                    control={
                                        <Checkbox
                                            checked={
                                                Customer !== undefined &&
                                                    Customer.IsActive !== undefined
                                                    ? Customer.IsActive
                                                    : true
                                            }
                                            onChange={() =>
                                                handleInputChange(
                                                    formProperties.IsActive.value,
                                                    !Customer.IsActive
                                                )
                                            }
                                            name={formProperties.IsActive.value}
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CustomCard>
                </div>
                {/* Only show these if we're on an existing Customer. */}
                {!loading && Customer.CustomerNumber !== "" && Customer.CustomerNumber !== undefined && (
                    <Fragment>
                        <div className={styles.contentGrid}>{getServicePoliciesList()}</div>
                    </Fragment>
                )}
            </Fragment>
        );
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <HeaderBlock
                title={Customer !== undefined ? Customer.Name + " (" + Customer.CustomerNumber + ")" : "New Customer"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <ButtonGroup>
                                {isEditing ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => handleCancelEditButtonClicked(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        if (!isEditing) {
                                            setIsEditing(true);
                                            setUneditedCustomer(Customer);
                                        } else if (isEditing && isExistingCustomer) {
                                            handleUpdateClicked();
                                        } else {
                                            handleSubmitClicked();
                                        }
                                    }}
                                >
                                    {isEditing
                                        ? isExistingCustomer
                                            ? "Done"
                                            : "Submit"
                                        : "Edit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />


            <PageContainer>{renderData()}</PageContainer>
        </div>
    );
};

const hoc = withRouter(CustomerItemPage);

// EXPORT COMPONENT
export { hoc as CustomerItemPage };
