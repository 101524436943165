import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import {
    GET_ALL_REGIONS,
    GET_SUBREGION,
    CREATE_SUBREGION,
    UPDATE_SUBREGION,
    GET_LOOKUPS
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

export const getAllRegions = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9004/api/GetRegions`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/GetRegions`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_ALL_REGIONS,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getSubRegionById = (SubRegionId, callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9004/api/GetSubRegionById?SubRegionId=${SubRegionId}`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/GetSubRegionById?SubRegionId=${SubRegionId}`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_SUBREGION,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};

export const createSubRegion = (cust, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9004/api/CreateSubRegion` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/CreateSubRegion`,
                {
                    method: "post",
                    data: cust,
                    headers: headers
                });

            dispatch({
                type: CREATE_SUBREGION,
                payload: cust
            });

            callback(true, cust.SubRegionId);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};

export const updateSubRegion = (cust, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9004/api/UpdateSubRegion` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/UpdateSubRegion`,
                {
                    method: "post",
                    data: cust,
                    headers: headers
                });

            dispatch({
                type: UPDATE_SUBREGION,
                payload: cust
            });

            callback(true);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};

export const getLookups = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9004/api/GetLookups`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Region/GetLookups`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_LOOKUPS,
                payload: {
                    data: response.data
                }
            });
            callback(true);

        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    }
};