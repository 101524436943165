import { makeStyles, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./PageContainer.module.scss"; // Import css modules stylesheet as styles


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "89px",
    scrollPaddingTop: "89px",
    left: "0",
    top: "50px",
    right: "0",
    bottom: "0",
    paddingLeft: "270px",
    position: "absolute",
  },

  containerWithNoSidePadding: {
    left: "0",
    right: "0",
    top: "50px",
    bottom: "0",
    position: "absolute",
    paddingTop: "60px",
    scrollPaddingTop: "60px",
  },
  containerWithNoTopPadding: {
    left: "0",
    right: "0",
    top: "50px",
    bottom: "0",
    position: "absolute",
    scrollPaddingTop: "50px",
  },

  containerFooter: {
    paddingBottom: "70px",
    scrollPaddingBottom: "70px",
  },

  containerHelp: {
    paddingTop: "161px",
    scrollPaddingTop: "161px",
  }
}));

export const PageContainer = (props: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const classes = useStyles();

  function handleWindowSizeChange() {
    if (window.innerWidth < window.innerHeight) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <Box
      className={
        isMobile ? props.noHeader ? classes.containerWithNoTopPadding : classes.containerWithNoSidePadding : classes.container +
          " " +
          (props.hasFooter ?? classes.containerFooter) +
          " " +
          (props.hasHelp ?? classes.containerHelp)
      }
    >
      {props.children}
    </Box>
  )
}

interface Props {
  isMobile?: boolean,
  hasFooter?: boolean,
  hasHelp?: boolean,
  noHeader?: boolean,
  children?: React.ReactNode
}