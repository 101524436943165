// React
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Data and Setup
import Axios from "axios";
import packageJson from "../package.json";

// UI and Styling
import "./App.scss";
import "./index.css";
import "./Theme.scss";

// Actions
import { updateVersion } from "./state/actions/VersionActions";
import { getRolesForLoggedInUser } from "./state/actions/RoleActions";

// Pages
import AuthPage from "./pages/auth/AuthPage";
import HomePage from "./pages/home/HomePage";
import LoginPage from "./pages/login/LoginPage";
import NotFoundPage from "./pages/not-found/NotFoundPage";

// Region
import { RegionListPage } from "./pages/region/RegionListPage";
import { ViewSubRegionPage } from "./pages/region/ViewSubRegionPage";

// Customer
import { CustomerListPage } from "./pages/customer/CustomerListPage";
import { CustomerItemPage } from "./pages/customer/CustomerItemPage";
import { NewCustomerPage } from "./pages/customer/NewCustomerPage";
import { CustomerServicePolicyItemPage } from "./pages/customer/CustomerServicePolicyItemPage";

// Migration Pages
import { MigrationCompletionPage } from "./pages/migration/MigrationCompletionPage";
import { CustomerMerchWindowMigrationPage } from "./pages/migration/CustomerMerchWindowMigrationPage";
import { CustomerMigrationPage } from "./pages/migration/CustomerMigrationPage";
import { RegionMigrationPage } from "./pages/migration/RegionMigrationPage";
import { MerchCardsMigrationPage } from "./pages/migration/MerchCardsMigrationPage";
import { MerchMigrationPage } from "./pages/migration/MerchMigrationPage";
import { MerchContractMigrationPage } from "./pages/migration/MerchContractMigrationPage";
import { MerchLeaveMigrationPage } from "./pages/migration/MerchLeaveMigrationPage";
import { CustomerServicePolicyMigrationPage } from "./pages/migration/CustomerServicePolicyMigrationPage";
import { HolidayCalendarMigrationPage } from "./pages/migration/HolidayCalendarMigrationPage";

// Components
import PrimarySearchAppBar from "./components/layout/SplitView";
import { PrivateRoute } from "./components/private-route/PrivateRoute";
import CustomizedSnackbars from "./components/snackbar/Snackbar";

// Roster Pages
import { RosterListPage } from "./pages/roster/RosterListPage";

// Globals
import { GlobalItemPage } from "./pages/globals/GlobalItemPage";

// Merchandiser
import { MerchandiserListPage } from "./pages/merchandiser/MerchandiserListPage";
import { MerchandiserItemPage } from "./pages/merchandiser/MerchandiserItemPage";
import { MerchandiserPreferredDaysItemPage } from "./pages/merchandiser/MerchandiserPreferredDaysItemPage";

// Timesheets
import { TimesheetListPage } from "./pages/timesheets/TimesheetListPage";
import { TimesheetItemPage } from "./pages/timesheets/TimesheetPage";

// Other
import { UserRoles } from "./state/constants/UserRoles";
import { FormType } from "./state/constants/FormType.js";
import { CallTypeListPage } from "./pages/calls/CallTypeListPage";
import { CallTypeItemPage } from "./pages/calls/CallTypeItemPage";
import { DataGenerationPage } from "./pages/datageneration/DataGenerationPage";

const App = () => {
  const version = useSelector((state) => state.VersionReducer.version);
  const loggedInUserRoles = useSelector(
    (state) => state.RoleReducer.loggedInUserRoles
  );

  const dispatch = useDispatch();

  const [requestCount, setRequestCount] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [webRoutes, setWebRoutes] = useState(null);

  useEffect(() => {
    const currenVersion = packageJson.version;

    setupInterceptor();

    if (version !== currenVersion) {
      dispatch(updateVersion(currenVersion));
    }

    dispatch(getRolesForLoggedInUser(() => { }));
  }, []);

  useEffect(() => {
    if (loggedInUserRoles) {
      renderRoutes();
    }
  }, [loggedInUserRoles]);

  const setupInterceptor = () => {
    // Append the access token to all requests being sent...
    Axios.interceptors.request.use((config) => {
      setRequestCount(requestCount + 1);

      return config;
    });

    Axios.interceptors.response.use(
      (response) => {
        setRequestCount(requestCount - 1);
        setSuccess("Success!");

        // Do something with response data...
        return response;
      },
      (error) => {
        var errorMessage = "Error";
        if (error.response && error.response.statusText) {
          errorMessage += `: ${error.response.statusText}`;

          if (error.response.data) {
            if (Array.isArray(error.response.data)) {
              error.response.data.forEach((error) => {
                if (error.error_reason) {
                  errorMessage += ` - ${error.error_reason}`;
                }
              });
            } else {
              if (error.response.data.error_message) {
                errorMessage += ` - ${error.response.data.error_message}`;
              }
            }
          }
          if (error.response.data.errors) {
            error.response.data.errors.forEach((error) => {
              errorMessage += ` - ${error}`;
            });
          }
        }
        setRequestCount(requestCount - 1);
        setError(errorMessage);

        // Do something with response error...
        return Promise.reject(error);
      }
    );
  };

  const renderRoutes = () => {
    const {
      SAM_M_ADMIN,
      SAM_M_MANAGER,
      SAM_M_MERCH
    } = UserRoles;

    if (Object.keys(loggedInUserRoles).length > 0) {
      setWebRoutes(
        <Switch>
          {/* Public Routes */}
          <Route exact path="/" component={HomePage} />
          <Route path="/login" component={LoginPage} />
          <PrivateRoute path="/auth" component={AuthPage} />

          <PrivateRoute
            path="/post-migration"
            component={MigrationCompletionPage}
            roles={[
              SAM_M_ADMIN
            ]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/sub-region"
            component={RegionListPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/sub-regions/new"
            component={ViewSubRegionPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.CREATE}
          />

          <PrivateRoute
            path="/sub-region/:SubRegionId"
            component={ViewSubRegionPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/customer"
            component={CustomerListPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/customer/new"
            component={NewCustomerPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.CREATE}
          />

          <PrivateRoute
            path="/customer/:CustomerNumber"
            component={CustomerItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/customer/:CustomerNumber/policy/new"
            component={CustomerServicePolicyItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.CREATE}
          />

          <PrivateRoute
            path="/customer/:CustomerNumber/policy/:CustomerServicePolicyId"
            component={CustomerServicePolicyItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/merch-mass-upload"
            component={MerchMigrationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/merch-contract-mass-upload"
            component={MerchContractMigrationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/merch-leave-mass-upload"
            component={MerchLeaveMigrationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/merch-cards-mass-upload"
            component={MerchCardsMigrationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/region-mass-upload"
            component={RegionMigrationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/customer-mass-upload"
            component={CustomerMigrationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/customer-merch-window-mass-upload"
            component={CustomerMerchWindowMigrationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/customer-service-policy-mass-upload"
            component={CustomerServicePolicyMigrationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/rosters"
            component={RosterListPage}
            exact
            roles={[SAM_M_MERCH, SAM_M_ADMIN, SAM_M_MANAGER]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/rosters/:MerchandiserId"
            component={RosterListPage}
            exact
            roles={[SAM_M_ADMIN, SAM_M_MANAGER, SAM_M_MERCH]}
            userRoles={loggedInUserRoles}
          />

          <PrivateRoute
            path="/globals/:Site"
            component={GlobalItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/merchandiser/"
            component={MerchandiserListPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/merchandiser/:MerchandiserId"
            component={MerchandiserItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/merchandiser/:EmployeeNumber/preferred-days/new"
            component={MerchandiserPreferredDaysItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.CREATE}
          />

          <PrivateRoute
            path="/merchandiser/:EmployeeNumber/preferred-days/:PreferredDaysId"
            component={MerchandiserPreferredDaysItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/CallTypes/"
            component={CallTypeListPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/CallTypes/new"
            component={CallTypeItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.CREATE}
          />

          <PrivateRoute
            path="/CallTypes/:CallTypeId"
            component={CallTypeItemPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          {/* Timesheets */}
          <PrivateRoute
            path="/timesheets"
            component={TimesheetListPage}
            exact
            roles={[SAM_M_MERCH]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/timesheets/new"
            component={TimesheetItemPage}
            exact
            roles={[SAM_M_MERCH]}
            userRoles={loggedInUserRoles}
            type={FormType.CREATE}
          />

          <PrivateRoute
            path="/HolidayDates"
            component={HolidayCalendarMigrationPage}
            exact
            roles={[SAM_M_MERCH, SAM_M_ADMIN, SAM_M_MANAGER]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/timesheets/existing"
            component={TimesheetItemPage}
            exact
            roles={[SAM_M_MERCH, SAM_M_ADMIN, SAM_M_MANAGER]}
            userRoles={loggedInUserRoles}
            type={FormType.VIEW}
          />

          <PrivateRoute
            path="/datageneration"
            component={DataGenerationPage}
            exact
            roles={[SAM_M_ADMIN]}
            userRoles={loggedInUserRoles}
          />

          <Route path="*" component={NotFoundPage} />
        </Switch>
      );
    }
  };

  return (
    <div
      className={"content theme " + (true ? "theme--dark" : "theme--default")}
    >
      <Router basename="/">
        <PrimarySearchAppBar isLoading={requestCount > 0}>
          <CustomizedSnackbars
            open={requestCount > 0}
            showLoading
            message={"Loading..."}
          />
          <CustomizedSnackbars
            autoHideDuration={1000}
            handleClose={() => {
              setSuccess(null);
            }}
            open={success != null}
            variant="success"
            message={success}
          />
          <CustomizedSnackbars
            handleClose={() => {
              setError(null);
            }}
            open={error != null}
            variant="error"
            message={error}
          />
          {webRoutes}
        </PrimarySearchAppBar>
      </Router>
    </div>
  );
};

const hoc = App;

// EXPORT COMPONENT

export default hoc;
