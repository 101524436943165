// Data and Config
import jwt from "jsonwebtoken";
import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch, getTokenAsync } from "../../config/azureConfig";

// Other
import {
  CREATE_ROLE,
  DELETE_ROLE,
  GET_ROLES,
  GET_ROLE,
  UPDATE_ROLE,
  GET_ROLES_FOR_USER,
  GET_ROLES_FOR_LOGGED_IN_USER,
  ASSIGN_USERS_TO_ROLE,
  REMOVE_USERS_FROM_ROLE
} from "../constants/action-types";
import { UserRoles } from "../../state/constants/UserRoles";

const API_CONFIG = getApiConfig();

/* 
    Get a list of roles.
*/
export const getRoles = (callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      process.env.NODE_ENV !== 'production' ?
        `http://localhost:7078/api/GetRoles` :
        `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/GetRoles`,
      {
        method: "get"
      }
    );

    dispatch({
      type: GET_ROLES,
      payload: response.data
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

/* 
    Get a role.
*/

export const getRole = (roleId, callback) => async (dispatch) => {
  try {
    const response = await adalApiFetch(
      Axios,
      process.env.NODE_ENV !== 'production' ?
        `http://localhost:7078/api/GetRole/?roleId=${roleId}` :
        `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/GetRole/?roleId=${roleId}`,
      {
        method: "get"
      }
    );

    dispatch({
      type: GET_ROLE,
      payload: response.data
    });

    callback(true);
  } catch (error) {
    console.log(error);
    callback(false);
  }
};

/*
    Create a new role
*/
export const createRole = (role, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    const data = {
      RoleName: role.name,
      Description: role.description
    };

    try {
      await adalApiFetch(Axios,
        process.env.NODE_ENV !== 'production' ?
          `http://localhost:7078/api/CreateRole` :
          `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/CreateRole`,
        {
          method: "post",
          data: data,
          headers: headers
        });

      dispatch({
        type: CREATE_ROLE,
        payload: role
      });

      callback(true);
    } catch (error) {
      console.log(`Exception occured: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Update a role
*/
export const updateRole = (roleId, role, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    const data = {
      RoleName: role.name,
      Description: role.description
    };

    try {
      await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== 'production' ?
          `http://localhost:7078/api/UpdateRole?roleId=${roleId}` :
          `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/UpdateRole?roleId=${roleId}`,
        {
          method: "post",
          data: data,
          headers: headers
        }
      );

      dispatch({
        type: UPDATE_ROLE,
        payload: role
      });

      callback(true);
    } catch (error) {
      console.log(`Exception occurred: ${error.message}`);
      callback(false);
    }
  };
};

/*
    Update role locally only
*/
export const updateRoleLocally = (role) => (dispatch) => {
  dispatch({
    type: UPDATE_ROLE,
    payload: role
  });
};

/*
    Delete a role
*/
export const deleteRole = (roleId, callback) => {
  return async (dispatch) => {
    const headers = { "Content-Type": "application/json" };

    try {
      await adalApiFetch(
        Axios,
        process.env.NODE_ENV !== 'production' ?
          `http://localhost:7078/api/DeleteRole?roleId=${roleId}` :
          `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/DeleteRole?roleId=${roleId}`,
        {
          method: "delete",
          headers: headers
        }
      );

      dispatch({
        type: DELETE_ROLE
      });

      callback(true);
    } catch (error) {
      console.log(`Exception occurred: ${error.message}`);
      callback(false);
    }
  };
};

/* 
    Get a list of roles for a specific user.
*/
export const getRolesForUser = (userId, callback) => (dispatch) => {
  adalApiFetch(
    Axios,
    process.env.NODE_ENV !== 'production' ?
      `http://localhost:7078/api/GetRolesForUser?userID=${userId}` :
      `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/GetRolesForUser?userID=${userId}`,
    {
      method: "get"
    }
  )
    .then((res) => {
      dispatch({
        type: GET_ROLES_FOR_USER,
        payload: res.data
      });

      callback(true);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    });
};

/*
    Get a list of the roles that the logged in user belongs to so they can only access the areas they have permissions to view
*/
export const getRolesForLoggedInUser = (callback) => (dispatch) => {
  const {
    SAM_M_ADMIN,
    SAM_M_MANAGER,
    SAM_M_MERCH
  } = UserRoles;

  getTokenAsync((errorDesc, token, error) => {
    const decodedToken = jwt.decode(token);

    let roles = [];

    if (decodedToken) {
      const userName = `ausdom\\${decodedToken.upn}`;
      // Get the logged in users roles from SaM core
      adalApiFetch(
        Axios,
        process.env.NODE_ENV !== 'production' ?
          `http://localhost:7078/api/GetRolesForUsername?Username=${userName}` :
          `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/GetRolesForUsername?Username=${userName}`,
        {
          method: "get"
        }
      )
        .then((res) => {
          roles = res.data.map((role) => {
            const roleName = role.RoleName.substring(
              role.RoleName.indexOf("\\") + 1
            );

            switch (roleName) {
              case SAM_M_ADMIN:
                return SAM_M_ADMIN;
              case SAM_M_MANAGER:
                return SAM_M_MANAGER;
              case SAM_M_MERCH:
                return SAM_M_MERCH;
              default:
                return null;
            }
          });

          dispatch({
            type: GET_ROLES_FOR_LOGGED_IN_USER,
            payload: roles.filter((role) => role !== null)
          });

          callback(true);
        })
        .catch((error) => {
          console.log(`Exception Occurred: ${error}`);
          if (callback) {
            callback(false);
          }
        });
    } else {
      callback(false);
    }
  });
};

/* 
    Assign users to a role.
*/
export const assignUsersToRole = (userIds, roleId, callback) => (dispatch) => {
  adalApiFetch(
    Axios,
    process.env.NODE_ENV !== 'production' ?
      `http://localhost:7078/api/AssignUsersToRole?roleId=${roleId}` :
      `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/AssignUsersToRole?roleId=${roleId}`,
    {
      method: "post",
      data: userIds
    }
  )
    .then((_) => {
      dispatch({
        type: ASSIGN_USERS_TO_ROLE,
        payload: { userIds, roleId }
      });

      callback(true);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    });
};

/*
    Remove users from role(s)
*/
export const removeUsersFromRole = (userIds, roleId, callback) => (
  dispatch
) => {
  adalApiFetch(
    Axios,
    process.env.NODE_ENV !== 'production' ?
      `http://localhost:7078/api/RemoveUsersFromRole?roleId=${roleId}` :
      `${API_CONFIG.URL}/${API_CONFIG.CORE}/Roles/RemoveUsersFromRole?roleId=${roleId}`,
    {
      method: "delete",
      data: userIds
    }
  )
    .then((_) => {
      dispatch({
        type: REMOVE_USERS_FROM_ROLE,
        payload: { userIds, roleId }
      });

      callback(true);
    })
    .catch((error) => {
      console.log(`Exception Occurred: ${error}`);
      callback(false);
    });
};
