// React
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { withRouter, useParams, useLocation } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Grid,
} from "@material-ui/core";
import styles from "./TimesheetListPage.module.scss";

// Actions
import { getTimesheetList } from "../../state/actions/TimesheetActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import { PageContainer } from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";
import ScrollableTabs from "../../components/tabs/Tabs";

// Other
import { getIcon } from "../../icon/icon";
import moment from "moment";

const TimesheetListPage = (props) => {
    // Dispatcher
    const dispatch = useDispatch();

    // Page Interaction States
    const [loading, setLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);

    // Locals
    const timesheets = useSelector((state) => state.TimesheetReducer.TimesheetList);
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);


    // Initial Load Trigger and outgoing call
    useEffect(() => {
        setLoading(true);

        // Get the merchandiser ID from the route.
        let userEmail = loggedInUser.userName.split('\\')[1];
        dispatch(
            getTimesheetList(userEmail, () => {

            }));
    }, []);

    // On alteration of allRosters
    useEffect(() => {
        if (timesheets !== undefined) {
            const data = createData(timesheets);

            // Setup default search params, etc.
            setOriginalData(data);
            setSearchedData(data);
            //setupData();

            // If loading, end loading.
            if (loading) {
                setLoading(false);
            }
        }
    }, [timesheets])

    // Build mapped data entries.
    const createData = (data) => {
        return data.map((item) => {
            return {
                ...item,
                date: moment(item.TimesheetStart).format("MMMM Do YYYY") + " - " + moment(item.TimesheetEnd).format("MMMM Do YYYY")
            };
        });
    };
    // Generate table headers, utilised in renderData()
    const createHeaders = () => {
        return [
            {
                id: "date",
                align: "left",
                clickable: true,
                action: { path: "/timesheets/existing?StartTime={startDate}&EndTime={endDate}", startDate: "TimesheetStart", endDate: "TimesheetEnd" },
                label: "Date Range",
                options: {
                    sort: false,
                }
            },
            {
                id: "ManagerName",
                clickable: false,
                align: "left",
                label: "Approval Manager",
                options: {
                    sort: false,
                }
            },
        ];
    };

    // Generate table cell data, used by renderData()
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};

            cell.id = header.id;

            if (header.hasOwnProperty("action")) {
                cell.handleClick = (event, row) => {
                    let path = header.action.path.replace(
                        "{startDate}",
                        row[header.action.startDate]
                    );
                    path = path.replace(
                        "{endDate}",
                        row[header.action.endDate]
                    );
                    props.history.push(path);
                };
            }

            cell.align = header.align;
            cell.clickable = header.clickable;
            cell.mask = "Download";

            cells.push(cell);
        });

        return cells;
    };

    // Render Table Contnet
    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("licencse.svg")}
                    text="No Timesheets found."
                />
            ) : (
                <SimpleTable
                    handleSelection={null}
                    isSelectable={false}
                    onClick={() => { }}
                    dataId={"rosterId"}
                    cells={cells}
                    headers={headers}
                    rows={searchedData}
                />
            );

        return data;
    };

    return (
        <div>
            <HeaderBlock
                title={"Timesheets"}
                right={
                    <Button
                                onClick={() => {
                                    props.history.push("/timesheets/new");
                                }}
                                variant="outlined"
                                color="secondary"
                            >
                                New
                            </Button>
                }
            />
            <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
        </div>
    );
}

const hoc = withRouter(TimesheetListPage);

// EXPORT COMPONENT
export { hoc as TimesheetListPage };