import { getToken } from "../../config/azureConfig";
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import copy from 'copy-to-clipboard';


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function AuthPage(props) {

  function copyToClipboard(e) {
    copy(getToken())
  };

  return (
    <Container maxWidth="sm">

      <Card className={useStyles.root}>
        <CardContent>
          <Typography className={useStyles.title} color="textSecondary" gutterBottom>
            Authorised!
        </Typography>
          <Typography variant="h5" component="h2">
            You are now authorised, hooray! <span role="img" aria-label="celebrate">🎉</span>
          </Typography>
          <Typography variant="body" component="p">
            {getToken()}
          </Typography>
        </CardContent>
        <CardActions>
            <Button size="large" onClick={copyToClipboard}> Copy Token</Button>
        </CardActions>
      </Card>
    </Container >
  );
}

export default AuthPage;
