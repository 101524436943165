// React
import React, { Fragment, Switch } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import {PageContainer} from "../../components/page-container/PageContainer";
import { Box } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CustomCard from "../../components/custom-card/CustomCard";
import styles from "./MigrationPage.module.scss";
import MUIDataTable from "mui-datatables";

const MigrationCompletionPage = (props) => {
    const failures = useSelector(
        (state) => state.MigrationReducer.UploadFailures
    );
    const successes = useSelector(
        (state) => state.MigrationReducer.UploadSuccesses
    );

    const failuremsg = useSelector(
        (state) => state.MigrationReducer.FailureMessage
    );

    const [failureFile, setFailureFile] = React.useState(failures);
    const [successFile, setSuccessFile] = React.useState(successes);
    const [failureMessage, setFailureMessage] = React.useState(failuremsg);


    const getFailureGrid = () => {
        const ids =
            failureFile != null
                ? failureFile
                : [];

        var columns = [];
        if (failureFile.length > 0) {
            for (const [key, val] of Object.entries(ids[0])) {
                if (!Array.isArray(val) && typeof val !== 'object') {
                    columns.push(key);
                }
            }
        }

        const userTableOptions = {
            filter: false,
            responsive: "stacked",
            rowsPerPage: 10,
            rowsPerPageOptions: [5, 10, 50, 100, 200],

            viewColumns: false,
            search: false,
            filterType: "checkbox",
            elevation: 0,
            selectableRows: "none",
            print: false,
            download: false
        };
        return (
            <MUIDataTable
                title="Failures"
                data={ids}
                columns={columns}
                options={userTableOptions}
            />
        );
    };

    const generateTable = (ids, name) => {
        var columns = [];
        if (ids.length > 0) {
            for (const [key, val] of Object.entries(ids[0])) {
                if (!Array.isArray(val) && typeof val !== 'object') {
                    if (typeof val === "boolean") {
                        columns.push({
                            name: key,
                            options: {
                                filter: true,
                                sort: false,
                                customBodyRender: (value, tableMeta, updateValue) => {
                                    return value == true ? "true" : "false";
                                }
                            },
                        })
                    }
                    else { columns.push(key); };

                }
            }
        }


        const userTableOptions = {
            filter: false,
            responsive: "stacked",
            rowsPerPage: 10,
            rowsPerPageOptions: [5, 10, 50, 100, 200],

            viewColumns: false,
            filterType: "checkbox",
            elevation: 0,
            selectableRows: "none",
            print: false,
            download: true,
            downloadOptions: {
                filename: name + ".csv",
                separator: ",",
                filterOptions: {
                    useDisplayedColumnsOnly: true,
                    useDisplayedRowsOnly: true
                }
            }
        };
        return (
            <Grid item xs={12}>
                <MUIDataTable
                    title={name}
                    data={ids}
                    columns={columns}
                    options={userTableOptions}
                />
            </Grid>
        );
    };

    const getSuccessGrid = () => {
        const ids =
            successFile != null
                ? successFile
                : [];


        if (Array.isArray(ids)) {
            return generateTable(ids, "SuccessfulUploads");
        }
        if (Object.keys(ids)) {
            var b = [];
            // Is a object of objects
            var a = Object.keys(ids);
            a.forEach(ob => {
                b.push(generateTable(ids[ob], ob));
            });
            return b;

        } else {
            return generateTable(ids, "SuccessfulUploads");
        };
    };

    const renderSegment = (pageName) => {
        switch (pageName) {
            case "failures":
                return (
                    <CustomCard title={"Failed Uploads"} display={false}>
                        <Grid item xs={12}>
                            {getFailureGrid()}
                        </Grid>
                    </CustomCard>
                );
            case "successes":
                return (
                    <CustomCard title={"Succeeded Uploads"} display={false}>
                        {getSuccessGrid()}
                    </CustomCard>
                );
            default:
                return null;
        }
    };

    const renderPageContent = () => {
        return Object.keys({ failures: "failures", successes: "successes" }).map((key) => {
            return (
                <Grid key={key} item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {renderSegment(key)}
                </Grid>
            );
        });
    };

    return (
        <Box>
            <Fragment>
                <HeaderBlock
                    title={"Post Upload"}
                    right={null}
                />

                <PageContainer>
                    {failureFile.length > 0 ?
                        <div className={styles.contentGrid}>
                            <Grid container spacing={12}>
                                <CustomCard title={"Failed Upload Debug Message"} display={true}>
                                    <Grid item xs={12}>
                                        <pre><code>{failureMessage}</code></pre>
                                    </Grid>
                                </CustomCard>
                            </Grid>
                        </div> :
                        null
                    }
                    <div className={styles.contentGrid}>
                        <Grid container spacing={3}>
                            {renderPageContent()}
                        </Grid>
                    </div>
                </PageContainer>
            </Fragment>
        </Box>
    );
};
const hoc = withRouter(MigrationCompletionPage);

// EXPORT COMPONENT
export { hoc as MigrationCompletionPage };