import {
  GET_LOGGED_IN_USER_BY_EMAIL, GET_MERCHANDISER_DATA
} from "../constants/action-types";

// INIT STATE
const initialState = {
  loggedInUser: {},
  merchData: {},
};

// REDUCER EXPORT
export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGGED_IN_USER_BY_EMAIL: {
      let loggedInUser = createExistingUser(action.payload);
      return {
        ...state,
        loggedInUser
      };
    }
    case GET_MERCHANDISER_DATA: {
      return {
        ...state,
        merchData: action.payload
      }
    }
    default:
      return state;
  }
}

const createExistingUser = (serverExisitingUser) => {
  let existingUser = {
    userId: serverExisitingUser.UserId,
    existsInAuth: serverExisitingUser.existsInAuth,
    existsInSitecore: serverExisitingUser.existsInSitecore,
    existsInActiveDirectory: serverExisitingUser.existsInActiveDirectory,
    userName: serverExisitingUser.userName,
    territory: serverExisitingUser.Territory === 2 ? "nz" : "au",
    firstName:
      serverExisitingUser.adUserInfo !== null
        ? serverExisitingUser.adUserInfo.givenName
        : "",
    surname:
      serverExisitingUser.adUserInfo !== null
        ? serverExisitingUser.adUserInfo.surname
        : "",
    displayName:
      serverExisitingUser.adUserInfo !== null
        ? serverExisitingUser.adUserInfo.displayName
        : ""
  };

  return existingUser;
};