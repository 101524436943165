import Axios from "axios";
import { getApiConfig } from "../../config/apiConfig";
import { adalApiFetch } from "../../config/azureConfig";
import { v4 as uuidv4 } from 'uuid';

import {
    GET_ALL_CALL_TYPES, UPSERT_CALL_TYPE, GET_CALL_TYPE
} from "../constants/action-types";

const API_CONFIG = getApiConfig();

export const getAllCallTypes = (callback) => {
    return async (dispatch) => {
        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9003/api/GetAllCallTypes`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Calls/GetAllCallTypes`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_ALL_CALL_TYPES,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};

export const getCallType = (id, callback) => {
    return async (dispatch) => {

        if (id == null) {
            dispatch({
                type: GET_CALL_TYPE,
                payload: {
                    data: { Id: uuidv4(), CallTypeName: "" }
                }
            });
            callback(true);
            return;
        }


        try {
            const response = await adalApiFetch(
                Axios,
                process.env.NODE_ENV !== "production"
                    ? `http://localhost:9003/api/GetCallType?Id=${id}`
                    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Calls/GetCallType?Id=${id}`,
                {
                    method: "get"
                }
            );

            dispatch({
                type: GET_CALL_TYPE,
                payload: {
                    data: response.data
                }
            });
            callback(true);
        } catch (error) {
            console.log(`Exception Occurred: ${error}`);
            callback(false);
        }
    };
};


export const upsertCallType = (data, callback) => {
    return async (dispatch) => {
        const headers = { "Content-Type": "application/json" };

        try {
            await adalApiFetch(Axios,
                process.env.NODE_ENV !== 'production' ?
                    `http://localhost:9003/api/UpsertCallType` :
                    `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Calls/UpsertCallType`,
                {
                    method: "post",
                    data: data,
                    headers: headers
                });

            dispatch({
                type: UPSERT_CALL_TYPE,
                payload: data
            });

            callback(true);
        } catch (error) {
            console.log(`Exception occured: ${error.message}`);
            callback(false);
        }
    };
};
