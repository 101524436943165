// React
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Grid,
} from "@material-ui/core";
import styles from "./RegionListPage.module.scss";

// Actions
import { getAllRegions } from "../../state/actions/RegionActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import {PageContainer} from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";
import ScrollableTabs from "../../components/tabs/Tabs";


// Other
import { getIcon } from "../../icon/icon";

// Start of main component body
const RegionListPage = (props) => {
    const allRegions = useSelector((state) => state.RegionReducer.Regions);
    const allSubRegions = useSelector((state) => state.RegionReducer.SubRegions);

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [currentSubRegion, setCurrentSubRegion] = useState("");
    const [tabs, setTabs] = useState([]);

    useEffect(() => {
        setLoading(true);

        dispatch(getAllRegions(() => { }));
    }, []);

    useEffect(() => {
        setupData();
    }, [currentSubRegion]);

    useEffect(() => {
        if (allSubRegions !== undefined) {
            const data = createData(allSubRegions);

            setOriginalData(data);
            setSearchedData(data);
            generateCurrentRegion(allRegions);
            setupData();
            if (loading) {
                setLoading(false);
            }
        }
    }, [allSubRegions]);

    const setupData = () => {
        const filteredSubRegions = allSubRegions.filter((sr) =>
            sr.RegionId === currentSubRegion
        );
        const data = createData(filteredSubRegions);

        setOriginalData(data);
        setSearchedData(data);
    };

    const generateCurrentRegion = (allRegions) => {
        var c = 0;
        var data = [];
        allRegions.forEach(region => {
            data.push({ index: c, id: region.RegionId });
            c += 1;
        });
        setTabs(data);

        // This is because generateThingy is called in the wrong useEffect,
        // But I have no idea how to fix it. So let's just be very strict.
        if (data != null && data.length > 0) { setCurrentSubRegion(data[0].id) };
    }

    const createData = (allSubRegions) => {
        const subRegionKeys = Object.keys(allSubRegions);

        return subRegionKeys.map((subRegionKey) => {
            const subRegion = allSubRegions[subRegionKey];
            return {
                name: subRegion.SubRegionName,
                id: subRegion.SubRegionId,
                territory: subRegion.Site,
                isActive: subRegion.IsActive ? "True" : "False"
            };
        });
    };

    // The headers of the table
    const createHeaders = () => {
        return [
            {
                id: "name",
                clickable: true,
                action: { path: "/sub-region/{id}", identifier: "id" },
                align: "left",
                label: "Name"
            },
            {
                id: "id",
                align: "left",
                label: "Sub Region Id"
            },
            {
                id: "territory",
                align: "left",
                label: "Territory"
            },
            {
                id: "isActive",
                align: "left",
                label: "Is Active"
            }
        ];
    };

    // Create the cells for the table and configure them.
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};

            cell.id = header.id;

            if (header.hasOwnProperty("action")) {
                cell.handleClick = (event, row) => {
                    let path = header.action.path.replace(
                        "{id}",
                        row[header.action.identifier]
                    );
                    props.history.push(path);
                };
            }

            cell.align = header.align;
            cell.clickable = header.clickable;

            cells.push(cell);
        });

        return cells;
    };

    const indexMe = (index) => {
        return tabs.filter(function (item) {
            return item.id === index;
        }).index;
    }

    const onTabChange = (value) => {
        setCurrentSubRegion(tabs[value].id);
    };

    const generateTabs = (data) => {
        if (!loading) {
            return allRegions.map(function (item) {
                return {
                    name: item.RegionName,
                    key: item.RegionId,
                    content: loading ? <Loading /> : data
                }
            })
        }
        else {
            return [
                {
                    name: "Loading",
                    key: "files",
                    content: loading ? <Loading /> : data
                }
            ]
        }
    }

    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("licencse.svg")}
                    text="No SubRegions found."
                />
            ) : (
                    <SimpleTable
                        handleSelection={null}
                        isSelectable={false}
                        onClick={() => { }}
                        dataId={"activityGroupId"}
                        cells={cells}
                        headers={headers}
                        rows={searchedData}
                    />
                );

        return (
            <ScrollableTabs
                handleTabChange={(newValue) => onTabChange(newValue)}
                value={indexMe(currentSubRegion)}
                tabs={generateTabs(data)}
            />
        );;
    };

    return (
        <div>
            <HeaderBlock
                title={"SubRegion List"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <Search
                                searchField={["name", "id"]}
                                returnSearchMatches={(data) => {
                                    setSearchedData(data);
                                }}
                                data={originalData}
                                searchTitle="Search SubRegions"
                            />
                        </Grid>
                        <ExportExcel csvData={searchedData} fileName="activity-groups" />
                        <Grid item>
                            <ButtonGroup classes={{ root: styles.buttonGroup }}>
                                <Button
                                    onClick={() => {
                                        props.history.push("/sub-regions/new");
                                    }}
                                    variant="outlined"
                                    color="secondary"
                                >
                                    New SubRegion
                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                }
            />
            <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
        </div>
    );
};

const hoc = withRouter(RegionListPage);

// EXPORT COMPONENT
export { hoc as RegionListPage };