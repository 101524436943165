export const FileType = Object.freeze({
  jpeg: {
    type: "jpeg",
    extensions: [".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp"],
    mime: "image/jpeg",
    displayable: true
  },
  png: {
    type: "png",
    extensions: [".png"],
    mime: "image/png",
    displayable: true
  },
  pdf: {
    type: "pdf",
    extensions: [".pdf"],
    mime: "application/pdf",
    displayable: false
  },
  csv: {
    type: "csv",
    extensions: [".csv"],
    mime: "text/csv",
    displayable: false
  },
  xls: {
    type: "csv",
    extensions: [".xls", ".xlsx", ".csv"],
    mime: "application/vnd.ms-excel",
    displayable: false
  },
  plain: {
    type: "csv",
    extensions: [".xls", ".xlsx", ".csv"],
    mime: "text/plain",
    displayable: false
  },
  xcsv: {
    type: "csv",
    extensions: [".xls", ".xlsx", ".csv"],
    mime: "text/x-csv",
    displayable: false
  },
  appcsv: {
    type: "csv",
    extensions: [".xls", ".xlsx", ".csv"],
    mime: "application/csv",
    displayable: false
  },
  appxcsv: {
    type: "csv",
    extensions: [".xls", ".xlsx", ".csv"],
    mime: "application/x-csv",
    displayable: false
  },
  comma: {
    type: "csv",
    extensions: [".xls", ".xlsx", ".csv"],
    mime: "text/comma-separated-values",
    displayable: false
  },
  xcomma: {
    type: "csv",
    extensions: [".xls", ".xlsx", ".csv"],
    mime: "text/x-comma-separated-values",
    displayable: false
  },
  tab: {
    type: "csv",
    extensions: [".xls", ".xlsx", ".csv"],
    mime: "text/tab-separated-values",
    displayable: false
  }
});

export function checkFileType(fileName, acceptedFileTypes) {
  // Find index of last "." if available and take slice
  if (acceptedFileTypes) {
    const givenFileType =
      "." + fileName.slice(fileName.lastIndexOf(".") - 1 + 2);
    const concatExtensions = acceptedFileTypes.extensions; //[].concat.apply([], Object.values(acceptedFileTypes).map(type => type.extensions));

    return concatExtensions.includes(givenFileType);
  }
}
