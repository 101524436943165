// React
import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

//UI and Styling
import { Grid, Paper } from "@material-ui/core";
import styles from "./HomePage.module.scss";

// Components
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { CustomCardContent } from "../../components/card-content/CardContent";
import {PageContainer} from "../../components/page-container/PageContainer";

class HomePage extends React.Component {
  getContentGrid() {
    return (
      <div className={styles.contentGrid}>
        <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={styles.cardContainer}>
              <CustomCardContent>
                <h3>Welcome to SAM M Admin.</h3>
              </CustomCardContent>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <div>
        <HeaderBlock title={"Home"} />
        <PageContainer>{this.getContentGrid()}</PageContainer>
      </div>
    );
  }
}

export default HomePage;
