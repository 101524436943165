// React
import React, { useState } from "react";

// UI and Styling
import { Button, Grid, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import styles from "./ContentUploadSegments.module.scss";

// Components
import CustomCard from "../../components/custom-card/CustomCard";
import FileDrop from "../../components/file-drop/FileDrop";

// Other
import { FileType } from "../../state/constants/FileType";
import { UUID } from "../../helpers/uuid";
import {handleDownloadOnClickActions} from "../../state/actions/DownloadActions.js";

const CSVUploadSegment = ({
  csvData,
  setCsvData,
  setDataChanged,
  title,
  targetsEdited,
  setTargetsEdited,
  isEditing,
  setFormEdited,
  description,
  columns,
  templateFileName,
  allDataUrl
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [csvFile, setCsvFile] = useState(null);
  const [manuallyInputtedTargets, setManuallyInputtedTargets] = useState("");

  const ParseFileUpload = (files) => {
    const file = files[0];

    if (file !== null) {
      setCsvFile([file]);

      const fileReader = new FileReader();

      const eventListener = (event) => {
        let fileData = event.target.result;
        fileData = fileData.replace(/\uFEFF|0xFEFF|ï»¿/g, "");
        var data = [];
        // Skip the row headers, since they're pretty useless
        var count = 0;
        var rowHeaders = [];
        fileData.split(/\r?\n/).forEach((row) => {
          if (count !== 0 && row != "") {
            var rowData = {};
            // Get rid of random quote marks from test data
            row.replace(/"/g, "");
            // Replace \'s in path with /'s 
            row = row.replace(/\\/, "/");
            // Split the row into individual strings
            // Regex pretty much means:
            // Split into seperate values using , as the delimiter
            // Except when the comma is between two sets of " in which case skip over
            var rowSplit = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            // Row count as indexOf was giving errors with duplicate values i.e Content and campaign title being the same
            var rowCount = 0;
            rowSplit.forEach((rowItem) => {
              // For each item, grab it's associated header so that we can create an object
              rowData[rowHeaders[rowCount]] = rowItem.trim();
              rowCount += 1;
            });
            // Smack it into our data array
            data.push(rowData);
          } else {
            count += 1;
            rowHeaders = row.split(",");
          }
        });

        var localData = data;

        if (!targetsEdited) {
          setTargetsEdited(true);
        }
        setCsvData(localData);
        setDataChanged(true);
      };

      fileReader.addEventListener("load", eventListener);

      fileReader.readAsBinaryString(file);
    }
  };

  const handleDownloadOnClick = (event) => {
    handleDownloadOnClickActions(allDataUrl, templateFileName);
    // window.open(`${baseUrl}/templates/${templateFileName}`, "_self");
  };

  const handleRemoveTargetsClick = (rowsToDelete) => {
    const localData = csvData;

    rowsToDelete.data.sort((a, b) => {
      return b.index - a.index;
    });

    rowsToDelete.data.forEach((row) => {
      localData.splice(row.index, 1);
    });

    setCsvData(localData);
    setDataChanged(true);
    setFormEdited(true);
  };

  // Get the markup for the main content
  const getIdGrid = () => {
    const ids = Object.keys(csvData).length > 0 ? csvData : [];

    // Holy Guac Batman
    const columns = !(!Array.isArray(csvData) || !csvData.length)
      ? Object.keys(csvData[0])
      : [];

    const userTableOptions = {
      filter: true,
      responsive: "stacked",
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 50, 100, 200],

      viewColumns: false,
      filterType: "checkbox",
      elevation: 0,
      selectableRows: isEditing ? "multiple" : "none",
      print: false,
      download: true,
      downloadOptions: {
        filename: "content-migration.csv",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      onRowsDelete: (rowsDeleted, dataRows) =>
        handleRemoveTargetsClick(rowsDeleted)
    };

    return (
      <MUIDataTable
        title="Content"
        data={ids}
        columns={columns}
        options={userTableOptions}
      />
    );
  };
  return (
    <CustomCard
      title={title}
      actionButton={[
        <Button
          key={UUID()}
          className={styles.buttonStyle}
          variant="outlined"
          color="secondary"
          onClick={(event) => handleDownloadOnClick(event)}
        >
          Download Current
        </Button>
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            {description}
            <br></br>
            Row Columns needs to be as below:
          </Typography>
          <pre><code>{columns.join(", ")}</code></pre>
          <div className={styles.fileDrop}>
            <FileDrop
              message={"Upload CSV"}
              onFilesAdded={(file) => ParseFileUpload(file)}
              file={csvFile}
              errorMessage={""}
              enforceTypes={[
                FileType.csv,
                FileType.xls,
                FileType.plain,
                FileType.xcsv,
                FileType.appcsv,
                FileType.appxcsv,
                FileType.comma,
                FileType.xcomma,
                FileType.tab
              ]}
              enforceTypesMessage={"File must be a csv"}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          {getIdGrid()}
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default CSVUploadSegment;
