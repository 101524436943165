import {
  MIGRATE_REGION,
  MIGRATE_MERCHANDISER
} from "../constants/action-types";


// INITIALIZE STATE
const initialState = {
  UploadFailures: [],
  UploadSuccesses: [],
  FailureMessage: "No failures"
};

// REDUCER
export const MigrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case MIGRATE_REGION: {
      return {
        ...state,
        UploadSuccesses: action.successes,
        UploadFailures: action.failures,
        FailureMessage: action.message
      }
    }

    case MIGRATE_MERCHANDISER: {
      return {
        ...state,
        UploadSuccesses: action.successes,
        UploadFailures: action.failures,
        FailureMessage: action.message
      }
    }

    default:
      return state;
  }
};
